import * as _ from "lodash";
import React, { FC } from "react";
import { Bar, Line } from "@reactchartjs/react-chart.js";

const options = {
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
      },
    ],
  },
  maintainAspectRatio: false,
  aspectRatio: 1, // this would be a 1:1 aspect ratio
};

interface IData {
  partitions: string[];
  data: { counts: number; label: string; dId: number }[];
}
interface IProps {
  results: IData;
  title: string;
  colorMapping: any;
  type?: string;
}

export const BillChart: FC<IProps> = (props) => {
  const data = {
    labels: props.results.partitions,
    datasets: _.map(props.results.data, (result) => {
      const color = _.get(
        props,
        `colorMapping[${result.dId}][0].color`,
        undefined
      );
      return {
        label: result.label,
        data: result.counts,
        backgroundColor: color,
        hoverBackgroundColor: color,
        fill: false,
      };
    }),
  };
  const { type = "bar" } = props;
  return (
    <div className="bill-chart">
      <div>
        {type === "bar" && (
          <Bar data={data} options={options} type height={250} />
        )}
        {type === "line" && (
          <Line data={data} options={options} type height={250} />
        )}
      </div>
      <div className="header">
        <span className="title">{props.title}</span>
      </div>
    </div>
  );
};
