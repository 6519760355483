import DateTimeField from "../components/DateTimeField";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";
import React, { FC, useState } from "react";
import {
  Datagrid,
  List,
  ReferenceField,
  TextField,
  useRefresh,
  useTranslate,
  useUpdate,
} from "react-admin";
import Pagination from "../components/Pagination";
import EditButton from "./EditButtonList";
import * as _ from "lodash";
import {Checkbox, Chip} from "@material-ui/core";
import {slugify} from "../helpers";
import {StatusTypeColor} from "../worktickets/List";
import {phan_chung_object, to_may_2_object, to_may_object} from "./Types";
import moment from "moment";

const ThietBiField = (props: any) => {
  const to_may: any = _.get(props, "record.to_may");
  if (!to_may) return <></>;
  return (
      <div>{to_may_object[to_may]} </div>
  );
};

const HeThongField = (props: any) => {
  let he_thong = _.get(props, "record.he_thong");
  const to_may = _.get(props, "record.to_may");
  if(to_may === "to_may_1"){
    he_thong = to_may_2_object[he_thong]
  } else if( to_may === "to_may_2"){
    he_thong = to_may_2_object[he_thong]
  } else if(to_may === "phan_chung") {
    he_thong = phan_chung_object[he_thong]
  }
  if (!he_thong) return <></>;
  return (
      <div>{he_thong} </div>
  );
};
const TuanFields = (props: any) => {
  const refresh = useRefresh();
  const {source, record} = props
  const tuanValueXacNhanThucHien = !!_.get(props, `record.xac_nhan_${source}`);
  const tuanValueXacNhanHoanThanh = !!_.get(props, `record.${source}`);
  const tuanValueKeHoach = !!_.get(props, `record.ke_hoach_${source}`);
  const [update] = useUpdate("maintenance", record ? record.id : "");
  const handleCheckBox = (e:any, source: any, id: any)=>{
    console.log('update?', source, id);
    update(
      {
          type: "update",
          resource: "maintenance",
          payload: {
              id: id,
              data: {
                [source]: e.target.checked
              }
          },
      },
      {
          onSuccess: () => {
              refresh()
          },
      }
    );
  }
  return <>
    <p>Kế hoạch</p>
    <Checkbox checked={tuanValueKeHoach} onClick={(e)=>handleCheckBox(e, `ke_hoach_${source}`, record.id)}/>
    <p>Thực hiện</p>
    <Checkbox checked={tuanValueXacNhanThucHien} onClick={(e)=>handleCheckBox(e, `xac_nhan_${source}`, record.id)}/>
    <p>Hoàn thành</p>
    <Checkbox checked={tuanValueXacNhanHoanThanh} onClick={(e)=>handleCheckBox(e, source, record.id)}/>
  </>

};
const XacNhanDonViVanHanhField = (props: any) => {
  const content: any = _.get(props, "record.xac_nhan_dv_van_hanh");
  const fullName: any = _.get(props, "record.xac_nhan_dv_van_hanh_updatedUser.fullname");
  const record = props.record;
  if(content){
    return (
        <div>
          <div>{content}</div>
          <div>Được cập nhật bởi: {fullName}</div>
          <DateTimeField record={record} source="xac_nhan_dv_van_hanh_updatedAt" isChip={true}/>
        </div>
    )
  }else{
    return <div />
  }
}
const KqThucHienField = (props: any) => {
  const content: any = _.get(props, "record.kq_thuc_hien");
  const fullName: any = _.get(props, "record.kq_thuc_hien_updatedUser.fullname");
  const record = props.record;
  if(content){
    return (
        <div>
          <div>{content}</div>
          <div>Được cập nhật bởi: {fullName}</div>
          <DateTimeField record={record} source="kq_thuc_hien_updatedAt" isChip={true}/>
        </div>
    )
  }else{
    return <div />
  }
}
const P4DanhGiaField = (props: any) => {
  const content: any = _.get(props, "record.p4_danh_gia");
  const fullName: any = _.get(props, "record.p4_danh_gia_updatedUser.fullname");
  const record = props.record;
  if(content){
    return (
        <div>
          <div>{content}</div>
          <div>Được cập nhật bởi: {fullName}</div>
          <DateTimeField record={record} source="p4_danh_gia_updatedAt" isChip={true}/>
        </div>
    )
  }else{
    return <div />
  }
}
const MaintenanceList: FC<any> = (props) => {
  return (
    <List
      {...props}
      title="Bảo dưỡng thường xuyên"
      actions={<ListActions />}
      filters={<ListFilter />}
      sort={{ field: "id", order: "DESC" }}
      filter={{loai_cong_viec:'bdtx' }}
      filterDefaultValues={{ "createdAt||$gte" : moment().startOf('month').format('YYYY-MM-DD'), "createdAt||$lte" : moment().endOf('month').format('YYYY-MM-DD') }}
      perPage={25}
      pagination={<Pagination/>}
      bulkActionButtons={false}
      exporter={true}
    >
      <Datagrid optimized >
        <TextField source="id" />
        <ThietBiField source="to_may"/>
        <HeThongField source="he_thong" />
        <TextField source="ma_kks" />
        <TextField source="noi_dung" />
        <TuanFields source="tuan1" />
        <TuanFields source="tuan2" />
        <TuanFields source="tuan3" />
        <TuanFields source="tuan4" />
        <ReferenceField
            source="departmentId"
            reference="departments"
            label="Đơn vị thực hiện"
        >
          <TextField source="alias"/>
        </ReferenceField>
        <TextField source="thiet_bi" />
        <KqThucHienField source="kq_thuc_hien" />
        <XacNhanDonViVanHanhField source="xac_nhan_dv_van_hanh" />
        <P4DanhGiaField source="p4_danh_gia" />
        <EditButton label="Thao tác" />
      </Datagrid>
    </List>
  );
};

export default MaintenanceList;
