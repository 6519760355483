import { Chip, CircularProgress } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditAttributesOutlined";
import _ from "lodash";
import React, { FC } from "react";
import { Button, Datagrid, List, TextField, useTranslate } from "react-admin";
import { useHistory } from "react-router";
import Pagination from "../components/Pagination";
import { BillStatusType } from "../dataProvider";
import * as helpers from "../helpers";
import { useCanAccess, useMe } from "../helpers";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";

const BillTypeField = (props: any) => {
  const translate = useTranslate();
  const { record, source } = props;
  if (record && source)
    return <div>{translate(`resources.bills.fields.${record[source]}`)}</div>;
  return null;
};

export const BillStatusField = (props: any) => {
  const translate = useTranslate();
  const { record, source } = props;
  if (!record) return <></>;
  const lastest = _.maxBy<any>(record[source], "createdAt");

  if (!lastest) return <></>;

  const color = [
    BillStatusType.APPROVED_BY_STAFF,
    BillStatusType.APPROVED_BY_KT,
  ].includes(lastest.status)
    ? "default"
    : "primary";

  return (
    <Chip
      label={translate(`resources.bills.fields.${lastest.status}`)}
      color={color}
    />
  );
};

export const BillNumber = (props: any) => {
  const { record } = props;
  if (!record) return <></>;
  return <div>{record.billNumber}</div>;
};

const queryStatus = (me: any) => {
  const isStaffPX = helpers.isStaffPX(me);
  if (isStaffPX) return BillStatusType.APPROVED_BY_STAFF;
  const isLeaderPX = helpers.isLeaderPX(me);
  if (isLeaderPX) return BillStatusType.APPROVED_BY_LEADER_STAFF;

  const isStaffKT = helpers.isStaffKT(me);
  if (isStaffKT) return BillStatusType.APPROVED_BY_KT;

  const isLeaderKT = helpers.isLeaderKT(me);
  if (isLeaderKT) return BillStatusType.APPROVED_BY_LEADER_KT;

  return null;
};

const EditButton: FC<any> = (props) => {
  const history = useHistory();
  const { loading, me } = useMe();
  const billId = _.get(props, "record.id", "");
  const canAccessEdit = useCanAccess({ action: "update", resource: "bill" });
  const departmentIdNeedApproval = _.get(
    props,
    "record.statusBills[0].approvalDepartmentId",
    undefined
  );

  const statusBillCreatedBy = _.get(
    props,
    "record.statusBills[0].createdBy",
    undefined
  );
  const createdBy = _.get(props, `record.createdBy`);
  const updatedBy = _.get(props, `record.updatedBy`);
  const myDepartmentId = _.get(me, "position.departmentId", "");
  const notPxAndKt = isNotKTAndPX(me);
  let isShowEdit = true;
  // if (helpers.isPX(me)) {
  //   isShowEdit =
  //     createdBy === me.id ||
  //     updatedBy === me.id ||
  //     statusBillCreatedBy === me.id ||
  //     departmentIdNeedApproval === myDepartmentId;
  // } else if (helpers.isKT(me)) {
  //   isShowEdit =
  //     createdBy === me.id ||
  //     updatedBy === me.id ||
  //     statusBillCreatedBy === me.id ||
  //     departmentIdNeedApproval === myDepartmentId;
  // } else {
  //   isShowEdit = true;
  // }

  return (
    <>
      {isShowEdit && (
        <Button
          component="button"
          variant="contained"
          label="pos.action.showEdit"
          onClick={() => {
            history.push(`/bills/${billId}`);
          }}
        >
          <EditIcon style={{ fontSize: "20" }} />
        </Button>
      )}
    </>
  );
};

const isNotKTAndPX = (me: any) => {
  const type = _.get(me, "position.department.type", undefined);
  return (
    type !== helpers.DepartmentType.KY_THUAT &&
    type !== helpers.DepartmentType.PHAN_XUONG
  );
};

const SList: FC<any> = (props) => {
  const translate = useTranslate();
  const { loading, me } = useMe();
  const { limit = 25 } = props;
  const canAccessDelete = useCanAccess({ action: "delete", resource: "bill" });
  if (loading) return <CircularProgress />;

  return (
    <List
      {...props}
      id="bill"
      empty={false}
      title={
        props.doNotShowAction ? null : translate("resources.bills.page.list")
      }
      actions={props.doNotShowAction ? null : <ListActions />}
      filters={props.doNotShowAction ? null : <ListFilter />}
      bulkActionButtons={
        props.doNotShowAction
          ? false
          : canAccessDelete
          ? props.bulkActionButtons
          : false
      }
      // filter={{
      //   "statusBills.isCurrent": 1,
      //   ...(isNotKTAndPX(me)
      //     ? { "statusBills.approvalDepartmentId": me.position.departmentId }
      //     : {
      //         "statusBills.status||$in": `${BillStatusType.APPROVED_BY_KT},${BillStatusType.APPROVED_BY_LEADER_KT},${BillStatusType.APPROVED_BY_LEADER_STAFF},${BillStatusType.APPROVED_BY_STAFF}`,
      //       }),
      // }}
      sort={{ field: "createdAt", order: "DESC" }}
      perPage={limit}
      pagination={<Pagination />}
    >
      <Datagrid
        optimized
        rowStyle={(rowData: any) => {
          const isModified = _.some(rowData.supplies, (sup) => sup.isModified);
          if (isModified) return { backgroundColor: "#ffe54c" };
        }}
      >
        <BillNumber source="billNumber" />
        <TextField source="billName" />
        <BillTypeField source="type" />
        <BillStatusField source="statusBills" size="medium" />
        <TextField source="createdTime" />
        <EditButton label={translate("pos.action.header")} />
      </Datagrid>
    </List>
  );
};

export default SList;
