import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import SList from "../bills/List";

export const BillList = (props: any) => {
  const { limit } = props;
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h3 className="acc_title">Danh sách phiếu đề xuất</h3>
      </AccordionSummary>
      <AccordionDetails>
        <SList
          basePath="/bills"
          resource="bills"
          doNotShowAction
          limit={10}
          onlyShowNotApprove
        />
      </AccordionDetails>
    </Accordion>
  );
};
