import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import SList from "../bills/List";
import SearchSupplies from "../supplies/SearchSupplies";

export const SupplyList = () => {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <h3 className="acc_title">Danh sách vật tư cần gấp</h3>
      </AccordionSummary>
      <AccordionDetails>
        <SearchSupplies doNotShowAction limit={10} />
      </AccordionDetails>
    </Accordion>
  );
};
