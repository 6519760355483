import { Box, Card, CardContent } from "@material-ui/core";
import React from "react";
import {
  Create,
  FormWithRedirect,
  AutocompleteInput,
  TextInput,
  Toolbar,
  ReferenceInput,
  required,
  SaveButton,
} from "react-admin";
import ListActions from "./ListActions";
import _ from "lodash";

const MajorCreate = (props: any) => {
  return (
    <Create {...props} undoable={false} actions={<ListActions />}>
      <Form />
    </Create>
  );
};

export const Form = (props: any) => {
  const { isEdit } = props;
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box
                display={{ md: "block", lg: "flex" }}
                style={{ alignItems: "center" }}>
                <Box ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="code" resource="items" />
                </Box>

                <Box ml={{ xs: 0, sm: "0.5em" }}>
                  <ReferenceInput
                    source="supplyId"
                    reference="supplies"
                    resource="items"
                    filterToQuery={(searchText: any) => {
                      return _.isEmpty(_.trim(searchText))
                        ? null
                        : { materialCode: searchText };
                    }}>
                    <AutocompleteInput
                      optionText="materialCode"
                      validate={requiredValidate}
                    />
                  </ReferenceInput>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="supplies"
              undoable={false}>
              <SaveButton
                saving={formProps.saving}
                redirect={isEdit ? "edit" : "list"}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};
const requiredValidate = [required()];
export default MajorCreate;
