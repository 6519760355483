export enum MissingDeviceStatus {
  OPENING = "opening",
  FINISH = "finish",
}

export enum DepartmentAlias {
  px1 = "px1",
  px2 = "px2",
  px3 = "px3",
  px4 = "px4",
  px5 = "px5",
  p3 = "p3",
  p2 = "p2",
  p4 = "p4"
}

export const to_may = [
  {id: "to_may_1", name: "Tổ máy 1"},
  {id: "to_may_2", name: "Tổ máy 2"},
  {id: "phan_chung", name: "Phần Chung"}
]

export const to_chuc_thuc_hien = [
  {id: "ci", name: "Tổ CI"},
  {id: "nhi_tu", name: "Tổ nhị thứ"},
  {id: "nhat_tu", name: "Tổ nhất thứ"},
  {id: "lo_hoi", name: "Tổ lò hơi"},
  {id: "may", name: "Tổ máy"},
  {id: "nhien_lieu", name: "Tổ nhiên liệu"},
]

export const to_chuc_thuc_hien_object_function: any = ()=>{
  return  to_chuc_thuc_hien.reduce((obj, item)=>{
    return {
      ...obj,
      [`${item.id}`]: item.name
    }
  }, {})
}

export const to_chuc_thuc_hien_object = {...to_chuc_thuc_hien_object_function()}

export const to_may_object: any = {
  to_may_1: to_may[0].name,
  to_may_2: to_may[1].name,
  phan_chung: to_may[2].name
}


export const to_may_1 = [
  {id: "lo_hoi", name: "Lò hơi"},
  {id: "turbine", name: "Turbine"},
  {id: "thai_xi", name: "Thải xỉ"},
  {id: "FGD", name: "FGD"},
  {id: "ESP", name: "ESP"},
  {id: "CEMS", name: "CEMS"},
  {id: "he_thong_dien", name: "Hệ thống điện"},
]

export const to_may_1_object: any = ()=>{
  return  to_may_1.reduce((obj, item)=>{
    return {
      ...obj,
      [`${item.id}`]: item.name
    }
  }, {})
}


export const to_may_2 = [
  {id: "lo_hoi", name: "Lò hơi"},
  {id: "turbine", name: "Turbine"},
  {id: "thai_xi", name: "Thải xỉ"},
  {id: "FGD", name: "FGD"},
  {id: "ESP", name: "ESP"},
  {id: "CEMS", name: "CEMS"},
  {id: "he_thong_dien", name: "Hệ thống điện"},
]

export const to_may_2_object: any = ()=>{
  return  to_may_2.reduce((obj, item)=>{
    return {
      ...obj,
      [`${item.id}`]: item.name
    }
  }, {})
}

export const phan_chung = [
  {id: "nhien_lieu", name: "Nhiên liệu"},
  {id: "xu_ly_nuoc", name: "Xử lý nước"},
  {id: "thai_xi", name: "Thải xỉ"},
  {id: "FGD", name: "FGD"},
  {id: "he_thong_dien", name: "Hệ thống điện"},
  {id: "at_mt", name: "An toàn & môi trường"},
  {id: "pccc", name: "Hệ thống PCCC"},
  {id: "ttas", name: "Hệ thống TT&AS"},
  {id: "he_thong_dieu_khien", name: "Hệ thống điều khiển"},
  {id: "he_thong_dien", name: "Hệ thống điện"},
]

export const phan_chung_object: any = ()=>{
  return  phan_chung.reduce((obj, item)=>{
    return {
      ...obj,
      [`${item.id}`]: item.name
    }
  }, {})
}

export const allHeThong = [
  {id: "lo_hoi", name: "Lò hơi"},
  {id: "turbine", name: "Turbine"},
  {id: "ESP", name: "ESP"},
  {id: "CEMS", name: "CEMS"},
  {id: "nhien_lieu", name: "Nhiên liệu"},
  {id: "xu_ly_nuoc", name: "Xử lý nước"},
  {id: "thai_xi", name: "Thải xỉ"},
  {id: "FGD", name: "FGD"},
  {id: "he_thong_dien", name: "Hệ thống điện"},
  {id: "at_mt", name: "An toàn & môi trường"},
  {id: "pccc", name: "Hệ thống PCCC"},
  {id: "ttas", name: "Hệ thống TT&AS"},
  {id: "he_thong_dieu_khien", name: "Hệ thống điều khiển"},
]

export const don_vi_tao = [
  {id: "px1", name: "PX1"},
  {id: "px3", name: "PX3"},
  {id: "px5", name: "PX5"},
]

export const don_vi_sua_chua = [
  {id: "px2", name: "PX2"},
  {id: "px4", name: "PX4"},
]

export const allHeThongObject = {...to_may_1_object(), ...to_may_2_object(), ...phan_chung_object()}
