import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import dayjs from "dayjs";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  AutocompleteArrayInput,
  Create,
  DateInput,
  FileInput,
  FormWithRedirect,
  ReferenceArrayInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  useGetMany,
  useTranslate,
} from "react-admin";
import PreviewInputFile from "../components/PreviewInputFile";
import { BillStatusType } from "../dataProvider";
import {
  isAdmin,
  isLeaderKT,
  isLeaderPX,
  useCanAccess,
  useMe,
} from "../helpers";
import ListActions from "./ListActions";
import TableItem from "./TableItem";
import TableSupply from "./TableSupply";
import TableTarget from "./TableTarget";
import DeleteWithConfirm from "../components/DeleteWithConfirm";

export enum BillType {
  SUA_CHUA_DOT_XUAT = "SUA_CHUA_DOT_XUAT",
  SUA_CHUA_SU_CO = "SUA_CHUA_SU_CO",
  VAT_TU_TIEU_HAO = "VAT_TU_TIEU_HAO",
}

export const isStatusBillType1 = (statusType: BillStatusType) => {
  return [
    BillStatusType.APPROVED_BY_STAFF,
    BillStatusType.APPROVED_BY_KT,
  ].includes(statusType);
};

export const getStatusType = (bill: any, me?: any) => {
  if (!bill || !bill.statusBills) {
    if (me) {
      const isLeader = isLeaderKT(me) || isLeaderPX(me);
      return isLeader
        ? BillStatusType.APPROVED_BY_KT
        : BillStatusType.APPROVED_BY_STAFF;
    }
    return undefined;
  }
  const current = _.find(
    bill.statusBills,
    (statusBill) => statusBill.isCurrent
  );
  if (!current) return undefined;

  return current.status;
};

const createGroupSupplies = (bills: any[]) => {
  const items = bills.map((bill) =>
    (bill.items || [])
      .filter((sup: any) => sup.isActive === true)
      .map((i: any) => ({ ...i, bill: bill, prevBillId: bill.id }))
  );
  const supplies = bills.map((bill) =>
    (bill.supplies || [])
      .filter((sup: any) => sup.isActive === true)
      .map((i: any) => ({
        ...i,
        bill: bill,
        prevBillId: bill.id,
        prevSupplyId: i.id,
      }))
  );
  const targets = bills.map((bill) =>
    (bill.targets || [])
      .filter((sup: any) => sup.isActive === true)
      .map((i: any) => ({
        ...i,
        bill: bill,
        prevBillId: bill.id,
        prevTargetId: i.id,
      }))
  );

  return {
    items: omitIdFromSupplies(_.flatten(items)),
    supplies: omitIdFromSupplies(_.flatten(supplies)),
    targets: omitIdFromSupplies(_.flatten(targets)),
  };
};

const ModifyAutoCompleteArrayInput = (props: any) => {
  useEffect(() => {
    props.setChildIds(props.input.value);
  }, [props.input.value]);

  return (
    <AutocompleteArrayInput
      {...props}
      optionText={(record: any) => {
        if (!record) return <></>;
        return `Phiếu số ${record.billNumber}, ngày ${record.createdTime}`;
      }}
      fullWidth
      validate={requiredValidate}
    />
  );
};

const requiredCustom =
  (message = "Chưa điền") =>
  (value: any) =>
    value ? undefined : message;
const number =
  (message = "Số phiểu phải là số") =>
  (value: any) =>
    value && isNaN(Number(value)) ? message : undefined;
const minValue =
  (min: number, message = "Phải lớn hơn") =>
  (value: number) =>
    value && value < min ? `${message} ${min}` : undefined;
const maxValue =
  (max: number, message = "Phải nhỏ hơn") =>
  (value: number) =>
    value && value > max ? `${message} ${max}` : undefined;

const InnerForm = (formProps: any) => {
  const translate = useTranslate();
  const { statusBill, isGroup, isEdit, record } = formProps;
  const canAccessCreate = useCanAccess({ action: "create", resource: "bill" });
  const canAccessEdit = useCanAccess({ action: "update", resource: "bill" });

  const [type, setType] = useState(!!record ? record.type : "");
  const [childIds, setChildIds] = useState(
    !!record && !!record.childIds ? record.childIds : []
  );
  const { loading, me } = useMe();
  const cantEdit = (isEdit && record) ? !(!!me && me.id === record.createdBy) : !canAccessCreate;

  const {
    loading: suppliesLoading,
    error,
    data,
  } = useGetMany("bills", childIds || []);

  useEffect(() => {
    const inter = setInterval(() => {
      if (isEdit) return;
      formProps.handleSubmit();
    }, 60 * 3 * 1000);
    return () => {
      clearInterval(inter);
    };
  }, []);

  useEffect(() => {
    if (!isEdit && data && isGroup) {
      const { items, supplies, targets } = createGroupSupplies(data);
      formProps.form.change("items", items);
      formProps.form.change("supplies", supplies);
      formProps.form.change("targets", targets);
    }
  }, [isEdit, data, isGroup]);
  const isMe = !!me && me.id === record.createdBy;
  const canAccessDelete =
    (useCanAccess({ action: "delete", resource: "bill" }) &&
      statusBill === BillStatusType.APPROVED_BY_STAFF) ||
    statusBill === BillStatusType.APPROVED_BY_KT ||
    isAdmin(me);

  if (loading || suppliesLoading) return <CircularProgress />;

  const isShowCreate =
    (canAccessCreate && !isEdit) ||
    (canAccessEdit &&
      isEdit &&
      statusBill &&
      [
        BillStatusType.APPROVED_BY_STAFF,
        BillStatusType.APPROVED_BY_KT,
      ].includes(statusBill) &&
      isMe);

  return (
    <Card>
      <form id="bill-form">
        <CardContent>
          <Typography variant="h6" component="h2" style={{ fontWeight: 500 }}>
            {translate("resources.bills.zones.common")}
          </Typography>
          <Box display={{ md: "block", lg: "flex" }}>
            <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
              <SelectInput
                source="type"
                variant="outlined"
                resource="bills"
                fullWidth
                validate={requiredValidate}
                choices={Object.keys(BillType).map((type) => ({
                  id: type,
                  name: translate(`resources.bills.fields.${type}`),
                }))}
                onChange={(e: any) => setType(e.target.value)}
                disabled={cantEdit}
              />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <TextInput
                source="billNumber"
                variant="outlined"
                disabled={cantEdit}
                resource="bills"
                fullWidth
                required
                validate={
                  isEdit
                    ? []
                    : [requiredCustom(), number(), minValue(1), maxValue(9999)]
                }
              />
            </Box>

            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <DateInput
                source="createdTime"
                resource="bills"
                fullWidth
                variant="outlined"
                disabled={cantEdit}
              />
            </Box>
          </Box>

          {isGroup && me?.position?.department?.type !== 'PX' && (
            <Box display={{ md: "block", lg: "flex" }}>
              <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
                <ReferenceArrayInput
                  source="childIds"
                  reference="bills"
                  variant="outlined"
                  resource="bills"
                  fullWidth
                  filter={{
                    "statusBills.isCurrent": 1,
                  }}
                  validate={requiredValidate}
                  filterToQuery={(searchText: any) => {
                    return _.isEmpty(_.trim(searchText))
                      ? null
                      : { billNumber: searchText };
                  }}
                >
                  <ModifyAutoCompleteArrayInput
                    variant="outlined"
                    setChildIds={setChildIds}
                  />
                </ReferenceArrayInput>
              </Box>
            </Box>
          )}

          <Box display={{ md: "block", lg: "flex" }}>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <TextInput
                source="billName"
                resource="bills"
                fullWidth
                validate={requiredValidate}
                variant="outlined"
                disabled={cantEdit}
              />
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}></Box>
          </Box>

          <Box display={{ md: "block", lg: "flex" }}>
            <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
              <TextInput
                source="content"
                resource="bills"
                fullWidth
                multiline
                variant="outlined"
                rows={3}
                disabled={cantEdit}
              />
            </Box>
            <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
              <TextInput
                source="solution"
                multiline
                rows={3}
                resource="bills"
                fullWidth
                variant="outlined"
                disabled={cantEdit}
              />
            </Box>
          </Box>

          <Box display={{ md: "block", lg: "flex" }}>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}></Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <FileInput
                source="files"
                resource="hworktickets"
                multiple
                accept="application/pdf"
                disabled={cantEdit}
              >
                <PreviewInputFile source="src" title="title" />
              </FileInput>
            </Box>
          </Box>

          {!!type && (
            <>
              {![BillType.SUA_CHUA_DOT_XUAT, BillType.SUA_CHUA_SU_CO].includes(
                type
              ) && (
                <TableItem
                  source="items"
                  statusType={statusBill}
                  type={type}
                  isClone={isGroup}
                />
              )}

              <TableSupply
                source="supplies"
                statusType={statusBill}
                type={type}
                isClone={isGroup}
              />
              {(type === BillType.SUA_CHUA_DOT_XUAT ||
                type === BillType.SUA_CHUA_SU_CO) && (
                <TableTarget
                  source="targets"
                  statusType={statusBill}
                  type={type}
                  isClone={isGroup}
                />
              )}
            </>
          )}
        </CardContent>

        <Toolbar
          record={formProps.record}
          basePath={formProps.basePath}
          invalid={formProps.invalid}
          // handleSubmit={formProps.handleSubmit}
          saving={formProps.saving}
          resource="bills"
          // @ts-ignore
          undoable="false"
          submitOnEnter={false}
        >
          {(isShowCreate || (isEdit && !cantEdit)) && (
            <SaveButton
              saving={formProps.saving}
              redirect={isEdit ? "edit" : "list"}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            />
          )}
          {(!cantEdit && canAccessDelete) && <DeleteWithConfirm hasReason={false} />}
        </Toolbar>
      </form>
    </Card>
  );
};
export const Form = (props: any) => {
  const { isEdit, isGroup = false } = props;
  const { loading, me } = useMe();
  const statusBill = getStatusType(props.record, me);

  return (
    <FormWithRedirect
      {...props}
      initialValues={{ createdTime: dayjs().format("YYYY-MM-DD") }}
      render={(formProps: any) => {
        return (
          <InnerForm
            {...formProps}
            statusBill={statusBill}
            isGroup={isGroup}
            isEdit={isEdit}
            record={props.record}
          />
        );
      }}
    />
  );
};

const omitIdFromSupplies = (supplies: any) => {
  return _.map(supplies, (sup) => _.omit(sup, ["id", "billId"]));
};

const MajorCreate = (props: any) => {
  return (
    <>
      <Create {...props} actions={<ListActions />}>
        <Form isGroup={props.isGroup} />
      </Create>
    </>
  );
};

const requiredValidate = [required()];

export default MajorCreate;
