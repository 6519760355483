import DateTimeField from "../components/DateTimeField";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";
import React, { FC } from "react";
import {
    Datagrid,
    List,
    ReferenceField,
    TextField, useListContext,
    useTranslate,
} from "react-admin";
import Pagination from "../components/Pagination";
import EditButton from "./EditButtonList";
import * as _ from "lodash";
import {allHeThongObject, to_chuc_thuc_hien_object, to_may_object} from "./Types";
import {orange, red} from "@material-ui/core/colors";
import moment from "moment";

const KhiemKhuyetThietBiField = (props: any) => {
  const content: any = _.get(props, "record.khiem_khuyet_thiet_bi");
  const to_may: any = _.get(props, "record.to_may");
  const he_thong: any = _.get(props, "record.he_thong");
  const fullName: any = _.get(props, "record.khiem_khuyet_thiet_bi_updatedUser.fullname");
  const record = props.record;
  if(content){
    return (
        <div>
          <div>{to_may_object[to_may]} - {allHeThongObject[he_thong]}</div>
          <div>{content}</div>
          <div>Được cập nhật bởi: {fullName}</div>
          <DateTimeField record={record} source="khiem_khuyet_thiet_bi_updatedAt" isChip={true}/>
        </div>
    )
  }else{
    return <div />
  }
}

const ToChucThucHienField = (props: any) => {
    const content: any = _.get(props, "record.to_thuc_hien");
    if(content){
        return (
            <div>
                <div>{to_chuc_thuc_hien_object[content]}</div>
            </div>
        )
    }else{
        return <div />
    }
}

const YKienDonViSuaChuaField = (props: any) => {
  const content: any = _.get(props, "record.y_kien_don_vi_sua_chua");
  const fullName: any = _.get(props, "record.y_kien_sua_chua_updatedUser.fullname");
  const record = props.record;
  if(content){
    return (
        <div>
          <div>{content}</div>
          <div>Được cập nhật bởi: {fullName}</div>
          <DateTimeField record={record} source="y_kien_don_vi_sua_chua_updatedAt" isChip={true}/>
        </div>
    )
  }else{
    return <div />
  }
}

const YKienDonViVanHanhField = (props: any) => {
  const content: any = _.get(props, "record.y_kien_don_vi_van_hanh");
  const fullName: any = _.get(props, "record.y_kien_don_vi_van_hanh_updatedUser.fullname");
  const record = props.record;
  if(content){
    return (
        <div>
          <div>{content}</div>
          <div>Được cập nhật bởi: {fullName}</div>
          <DateTimeField record={record} source="y_kien_don_vi_van_hanh_updatedAt" isChip={true}/>
        </div>
    )
  }else{
    return <div />
  }
}

const YKienDonViKyThuatField = (props: any) => {
  const content: any = _.get(props, "record.y_kien_don_vi_ky_thuat");
  const fullName: any = _.get(props, "record.y_kien_don_vi_ky_thuat_updatedUser.fullname");
  const record = props.record;
  if(content){
    return (
        <div>
          <div>{content}</div>
          <div>Được cập nhật bởi: {fullName}</div>
          <DateTimeField record={record} source="y_kien_don_vi_ky_thuat_updatedAt" isChip={true}/>
        </div>
    )
  }else{
    return <div />
  }
}

const YKienDonViATMTField = (props: any) => {
  const content: any = _.get(props, "record.y_kien_don_vi_ATMT");
  const fullName: any = _.get(props, "record.y_kien_don_vi_ATMT_updatedUser.fullname");
  const record = props.record;
  if(content){
    return (
        <div>
          <div>{content}</div>
          <div>Được cập nhật bởi: {fullName}</div>
          <DateTimeField record={record} source="y_kien_don_vi_ATMT_updatedAt" isChip={true}/>
        </div>
    )
  }else{
    return <div />
  }
}

const IndexField = (props: any) => {
  const index: any = _.get(props, "record.index");
  console.log(props.record)
  return <div>{index}</div>

}

const MissingdevicesList: FC<any> = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate("resources.departments.page.list")}
      actions={<ListActions />}
      filters={<ListFilter />}
      sort={{ field: "id", order: "DESC" }}
      filterDefaultValues={{ "createdAt||$gte" : moment().startOf('month').format('YYYY-MM-DD'), "createdAt||$lte" : moment().endOf('month').format('YYYY-MM-DD') }}
      perPage={25}
      pagination={<Pagination/>}
      bulkActionButtons={false}
    >
      <Datagrid
          optimized
          rowStyle={(rowData: any) => {
              if(rowData.rat_gap){
                  return {
                      backgroundColor:  red[600],
                  }
              }
              if(rowData.gap){
                  return {
                      backgroundColor:  orange[600],
                  }
              }
          }}
      >
        <IndexField source="index" label="Số thứ tự" />
        <DateTimeField showOnlyDate source="createdAt" label="Ngày tạo"/>
        <KhiemKhuyetThietBiField source="khiem_khuyet_thiet_bi" label="Quản lý khiếm khuyết thiết bị" />
        <ReferenceField
          source="departmentId"
          reference="departments"
          label="Đơn vị thực hiện"
        >
          <TextField source="alias"/>
        </ReferenceField>
        <ToChucThucHienField source="to_thuc_hien" label="Tổ chức thực hiện" />
        <YKienDonViSuaChuaField source="y_kien_don_vi_sua_chua" label="Ý kiến đơn vị sửa chữa" />
        <YKienDonViVanHanhField source="y_kien_don_vi_van_hanh" label="Ý kiến đơn vị vận hành" />
        <YKienDonViKyThuatField source="y_kien_don_vi_ky_thuat" label="Ý kiến phòng kỹ thuật" />
        <YKienDonViATMTField source="y_kien_don_vi_ATMT" label="Ý kiến phòng AT&MT" />
        <DateTimeField showOnlyDate source="ngay_ket_thuc" label="Ưu tiên hoàn thành sớm"/>
        <ReferenceField
          source="billId"
          reference="bills"
          label="Tiến độ vật tư"
        >
          <TextField source="billName"/>
        </ReferenceField>
        <EditButton label="Thao tác" />
      </Datagrid>
    </List>
  );
};

export default MissingdevicesList;
