import PermissionIcon from "@material-ui/icons/Accessibility";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import FileSaver from "file-saver";
import { ImportButton } from "ra-import-csv";
import React, { cloneElement, FC } from "react";
import {
  Button,
  CreateButton,
  ListButton,
  sanitizeListRestProps,
  TopToolbar,
  useTranslate,
} from "react-admin";
import { useHistory } from "react-router";

const configImportQuestion = {
  parseConfig: {
    header: true,
  },
};

const configImportContractor = {
  parseConfig: {
    header: true,
  },
  addFields: (rows: any[]) => {
    return rows.map((row) => ({ ...row, isContractor: true }));
  },
};

const ListActions: FC<any> = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  showPermission = false,
  ...rest
}) => {
  const history = useHistory();
  const { data } = rest;
  const translate = useTranslate();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {!filters && <ListButton basePath={basePath} />}
      {filters &&
        cloneElement(filters, {
          undoable: "false",
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} />
      {showPermission && (
        <Button
          variant="text"
          label="pos.action.grant"
          onClick={() => {
            history.push(`/permissions/${data.id}`);
          }}
        >
          <PermissionIcon style={{ fontSize: "20" }} />
        </Button>
      )}

      <ImportButton
        resource="users"
        basePath={`/users`}
        {...configImportQuestion}
        label={translate("pos.action.importUser")}
        showOverWrite={false}
      />

      <ImportButton
        resource="users"
        basePath={`/users`}
        {...configImportContractor}
        label={"Nhập nhà thầu"}
        showOverWrite={false}
      />

      <Button
        color="primary"
        component="button"
        variant="text"
        translate="no"
        label="Tải vị trí"
        onClick={() => {
          FileSaver.saveAs(
            `${process.env.REACT_APP_API_BASE_URL}/api/positions/export`,
            "vitri.csv"
          );
        }}
      >
        <CloudDownloadIcon style={{ fontSize: "20" }} />
      </Button>
    </TopToolbar>
  );
};

export default ListActions;
