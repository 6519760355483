import { CircularProgress, TextField } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Close from "@material-ui/icons/Close";
import CloudDownloadOutlined from "@material-ui/icons/CloudDownloadOutlined";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Done from "@material-ui/icons/Done";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import IconButton from "@material-ui/icons/MoreOutlined";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import * as _ from "lodash";
import MaterialTable, { Column, Components, Options } from "material-table";
import { ImportButton, ImportConfig } from "ra-import-csv";
import React, { FC, forwardRef, memo, useEffect, useState } from "react";
import { Button, useDataProvider, useNotify, useTranslate } from "react-admin";
import { useField } from "react-final-form";
import { useRouteMatch } from "react-router";
import { MTableToolbar } from "../components/MToolbar";
import TextFieldMore from "../components/TextFieldMore";
import UploadPDF from "../components/UploadPDF";
import { BillStatusType, customHttpClient } from "../dataProvider";
import { slugify, useThrottle } from "../helpers";
import { BillType, isStatusBillType1 } from "./Create";
import DialogSupplyMoreDetail from "./DialogSupplyMoreDetail";

export const tableIcons: any = {
  Add: forwardRef((props, ref: any) => (
    <Button ref={ref} label="ra.action.create">
      <Add {...props} />
    </Button>
  )),
  // @ts-ignore
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  // @ts-ignore
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),

  Delete: forwardRef((props, ref: any) => (
    <Button label="ra.action.delete" ref={ref} style={{ color: "red" }}>
      <DeleteOutline {...props} style={{ color: "red" }} />
    </Button>
  )),

  DetailPanel: forwardRef((props, ref) => (
    // @ts-ignore
    <ChevronRight {...props} ref={ref} />
  )),

  Edit: forwardRef((props, ref: any) => (
    <Button label="ra.action.edit" ref={ref}>
      <Edit {...props} />
    </Button>
  )),
  // @ts-ignore
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  // @ts-ignore
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  // @ts-ignore
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  // @ts-ignore
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  // @ts-ignore
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),

  PreviousPage: forwardRef((props, ref) => (
    // @ts-ignore
    <ChevronLeft {...props} ref={ref} />
  )),
  // @ts-ignore
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  // @ts-ignore
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  // @ts-ignore
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  // @ts-ignore
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  // @ts-ignore
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const components = (
  billId: string | undefined,
  field: string,
  isShowAdd: boolean,
  inputProps?: any,
  billType?: string,
  selectedId?: any,
  setSelectedId?: any,
  show?: any,
  setShow?: any,
  item?: boolean
) =>
  ({
    Action: (props: any, args: any) => {
      const action =
        typeof props.action === "function"
          ? props.action().tooltip
          : props.action.tooltip;
      switch (action) {
        case "Add":
          return (
            <>
              {isShowAdd && selectedId?.length > 0 && (
                <span>
                  Đã chọn: {selectedId?.length} &nbsp;
                  <Button
                    label="ra.action.delete"
                    style={{ color: "red" }}
                    onClick={(event: any) => {
                      setShow(true);
                    }}
                  >
                    <DeleteOutline style={{ color: "red" }} />
                  </Button>
                  <Dialog
                    open={show}
                    onClose={setShow}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Xoá bản ghi
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Xác nhận xoá {selectedId?.length} bản ghi?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button label="Huỷ" onClick={() => setShow(false)} />
                      <Button
                        label="Đồng ý"
                        onClick={() => {
                          const dataDelete = [...inputProps.input.value];
                          const dataResult = _.difference(
                            dataDelete,
                            selectedId
                          );
                          inputProps.input.onChange(dataResult);
                          setSelectedId(null);
                          setShow(false);
                        }}
                        autoFocus
                      />
                    </DialogActions>
                  </Dialog>
                </span>
              )}
              {isShowAdd && (
                <>
                  <Button
                    label={`ra.action.create`}
                    onClick={(event: any) =>
                      props.action.onClick(event, props.data)
                    }
                  >
                    <Add />
                  </Button>
                  {inputProps && (
                    <Button
                      label="Tải mẫu nhập"
                      translate={"no"}
                      onClick={(event: any) => {
                        const link = document.createElement("a");
                        let href = `${process.env.REACT_APP_API_BASE_URL}/public/templates/import_sua_chua_dot_xuat_va_su_co.xlsx`;
                        if (item) {
                          href = `${process.env.REACT_APP_API_BASE_URL}/public/templates/import_vat_tu_tieu_hao.xlsx`;
                        }

                        link.href = href;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }}
                    >
                      <CloudDownloadOutlined />
                    </Button>
                  )}
                </>
              )}

              {billId && (
                <Button
                  label={`ra.action.export`}
                  onClick={(event: any) => {
                    customHttpClient(
                      `/api/bills/export/${field}/${billId}_pdx.xlsx`
                    ).then((res) => {
                      const link = document.createElement("a");
                      const href = `${process.env.REACT_APP_API_BASE_URL}${res.body}`;

                      link.href = href;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    });
                  }}
                >
                  <CloudDownloadOutlined />
                </Button>
              )}
            </>
          );
        case "Edit":
          return (
            <>
              <Button
                label={`ra.action.edit`}
                onClick={(event: any) =>
                  props.action().onClick(event, props.data)
                }
              >
                <Edit />
              </Button>

              {/* <Button
                label="Copy"
                onClick={(event: any) => console.log(event, props.data)}
              >
                <FileCopy />
              </Button> */}
            </>
          );
        case "Delete":
          return (
            <Button
              label="ra.action.delete"
              style={{ color: "red" }}
              onClick={(event: any) =>
                props.action().onClick(event, props.data)
              }
            >
              <DeleteOutline style={{ color: "red" }} />
            </Button>
          );
        case "Save":
          const configImportSupply: ImportConfig = item
            ? {
                parseConfig: {
                  header: true,
                  transformHeader: (h: string) => {
                    const slug = slugify(h);
                    const HEADER_MAPPER: any = {
                      ma_vat_tu: "materialCode",
                      "mo_ta_ten_vat_tu_-_thiet_bi": "name",
                      dac_tinh_ky_thuat: "info",
                      "ma_hieu_-_nha_cung_cap": "madein",
                      don_vi: "unit",
                      hang_san_xuat: "madein",
                      "so_luong_can_bo_sung_(trong_ke_hoach)": "countOfYear",
                      "so_luong_can_bo_sung_(ngoai_ke_hoach)": "countOfMonth",
                      so_luong: "count",
                      ghi_chu: "note",
                    };
                    return HEADER_MAPPER[slug] || slug;
                  },
                },
              }
            : {
                parseConfig: {
                  header: true,
                  transformHeader: (h: string) => {
                    const slug = slugify(h);
                    const HEADER_MAPPER: any = {
                      ma_vat_tu: "materialCode",
                      thiet_bi: "deviceName",
                      he_thong: "system",
                      ten_vat_tu: "name",
                      thong_so_ky_thuat: "info",
                      ma_hieu: "code",
                      don_vi: "unit",
                      ma_hieu_nha_cung_cap: "madein",
                      hang_san_xuat: "madein",
                      so_luong: "count",
                      ghi_chu: "note",
                      "can_gap_(10)": "isUrgen",
                    };
                    return HEADER_MAPPER[slug] || slug;
                  },
                },
              };
          return (
            <>
              <Button
                onClick={(event: any) => {
                  props.action.onClick(event, props.data);
                }}
                label="Lưu"
              >
                <Done />
              </Button>
              {!!inputProps && (
                <ImportButton
                  resource="any"
                  {...configImportSupply}
                  label="Nhập"
                  showOverWrite={false}
                  handleValues={(data: any[], onComplete: any) => {
                    if (_.isEmpty(data)) {
                      return;
                    }

                    const oldDataNotMC = _.filter(
                      inputProps.input.value,
                      (item) => !item.materialCode
                    );
                    const oldDataMC = _.filter(
                      inputProps.input.value,
                      (item) => !!item.materialCode
                    );

                    // not have materialCode
                    const notHaveMaterialCode = _.filter(
                      data,
                      (item) => !item.materialCode
                    );

                    const newdatahasMC = _.filter(
                      data,
                      (item) => !!item.materialCode
                    );

                    // insert only
                    const newData = _.differenceBy(
                      newdatahasMC,
                      oldDataMC,
                      "materialCode"
                    );

                    // old with no update
                    const notUpdate = _.differenceBy(
                      oldDataMC,
                      newdatahasMC,
                      "materialCode"
                    );

                    // old with update
                    const mustUpdate: any[] = _.differenceBy(
                      oldDataMC,
                      notUpdate,
                      "materialCode"
                    );

                    // data update
                    const dataUpdate = _.differenceBy(
                      newdatahasMC,
                      newData,
                      "materialCode"
                    );

                    const dataUpdated = dataUpdate.map((row) => {
                      const oRow = mustUpdate.find(
                        (oRow: any) => oRow.materialCode === row.materialCode
                      );
                      if (!oRow) return row;
                      return {
                        ...row,
                        count:
                          _.toInteger(oRow.count || 0) +
                          _.toInteger(row.count || 0),
                      };
                    });

                    inputProps.input.onChange(
                      [
                        ...notUpdate,
                        ...dataUpdated,
                        ...newData,
                        ...notHaveMaterialCode,
                        ...oldDataNotMC,
                      ].map((u) => ({ ...u, count: _.toInteger(u.count || 0) }))
                    );

                    setTimeout(() => {
                      onComplete();
                    }, 500);
                  }}
                />
              )}
            </>
          );

        case "Cancel":
          return (
            <Button
              label="Hủy"
              onClick={(event: any) => props.action.onClick(event, props.data)}
            >
              <Close style={{ color: "red" }} />
            </Button>
          );

        default:
          console.log("heere", props);
          return <></>;
      }
    },
    Toolbar: MTableToolbar,
  } as Components);

const EditComponent = (props: any) => {
  const { disableAutoFill = false, multiline = false } = props;
  const [inputData, setInputData] = useState(props.value || "");
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const inputDataThrottle = useThrottle(inputData, 500);

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList("supplies", {
        pagination: { page: 1, perPage: 50 },
        filter: {
          ...(inputDataThrottle
            ? { [props.columnDef.field]: inputDataThrottle }
            : {}),
        },
        sort: { field: "createdAt", order: "DESC" },
      })
      .then((data: any) => {
        setLoading(false);

        return (data || {}).data;
      })
      .then((data: any) => setState(data || []));
  }, [inputDataThrottle, dataProvider, props.columnDef.field]);

  return (
    <Autocomplete
      options={state}
      getOptionLabel={(option: any) => {
        return option[props.columnDef.field] || "";
      }}
      freeSolo={true}
      value={{ [props.columnDef.field]: props.value || "" }}
      defaultValue={{ [props.columnDef.field]: props.value || "" }}
      onInputChange={(e, value) => {
        const recordMatch = state.find(
          (record) => record[props.columnDef.field] === value
        );

        if (recordMatch && !disableAutoFill) {
          const {
            materialCode = "",
            madein = "",
            unit = "",
            code = "",
            name = "",
            system = "",
            info = "",
            deviceName = "",
            count = 0,
          } = recordMatch;
          props.onRowDataChange({
            materialCode,
            madein,
            unit,
            code,
            name,
            system,
            info,
            deviceName,
            count,
          });
        } else {
          props.onChange(value);
          setInputData(value);
        }
      }}
      loading={loading}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            {...(multiline ? { multiline: true, rows: 10 } : {})}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
    />
  );
};

const ShowMoreButton: FC<any> = (props) => {
  const [showMore, setShowMore] = useState(false);
  if (!props.id) return <></>;
  return (
    <>
      <DialogSupplyMoreDetail
        showMore={showMore}
        setShowMore={setShowMore}
        type="supplies"
        {...props}
      />
      <Button
        color="primary"
        component="span"
        variant="contained"
        label="pos.action.more"
        onClick={() => {
          setShowMore(() => true);
        }}
      >
        <IconButton style={{ fontSize: "20" }} />
      </Button>
    </>
  );
};

const colVTTH1 = (translate: any) => [
  {
    title: translate("resources.supplies.fields.materialCode"),
    field: "materialCode",
    editComponent: (row: any) => {
      return <EditComponent {...row} />;
    },

    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: translate("resources.supplies.fields.name"),
    field: "name",
    editComponent: (row: any) => {
      return <EditComponent {...row} />;
    },
    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: translate("resources.supplies.fields.info"),
    field: "info",
    width: 300,
    cellStyle: { maxWidth: 300, overflowWrap: "break-word" },
    render: (row: any) => {
      return <TextFieldMore record={row} source="info" />;
    },
    editComponent: (row: any) => {
      return <EditComponent {...row} />;
    },
  },
  {
    title: "Mã hiệu/ Nhà cung cấp",
    field: "madein",
    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: translate("resources.supplies.fields.unit"),
    field: "unit",
    width: 50,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: "Cần gấp",
    field: "isUrgen",
    type: "boolean",
    width: 50,
  },
  {
    title: translate("resources.supplies.fields.count"),
    field: "count",
    type: "numeric",
    width: 50,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },

  {
    title: translate("resources.supplies.fields.note"),
    field: "note",
    render: (row: any) => {
      return <TextFieldMore record={row} source="note" />;
    },
  },

  {
    title: "Ảnh",
    field: "imageUrl",
    editComponent: (row: any) => {
      return <UploadPDF {...row} />;
    },
    render: (row: any) => {
      return <UploadPDF {...row} value={row.imageUrl} disable />;
    },
  },

  {
    title: translate("pos.action.more"),
    render: (row: any) => {
      return <ShowMoreButton {...row} />;
    },
    width: 50,
  },
];

export const colVTTH2 = (translate: any) => [
  {
    title: translate("resources.supplies.fields.materialCode"),
    field: "materialCode",
    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
    editComponent: (row: any) => {
      return <EditComponent {...row} />;
    },
  },
  {
    title: translate("resources.supplies.fields.name"),
    field: "name",
    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
    editComponent: (row: any) => {
      return <EditComponent {...row} />;
    },
  },
  {
    title: translate("resources.supplies.fields.info"),
    field: "info",
    width: 300,
    cellStyle: { maxWidth: 300, overflowWrap: "break-word" },
    render: (row: any) => {
      return <TextFieldMore record={row} source="info" />;
    },
    editComponent: (row: any) => {
      return <EditComponent {...row} />;
    },
  },
  {
    title: "Mã hiệu/ Nhà cung cấp",
    field: "madein",
    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: translate("resources.supplies.fields.unit"),
    field: "unit",
    width: 50,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },

  {
    title: translate("resources.supplies.fields.count"),
    field: "count",
    type: "numeric",
    width: 50,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: "Số lượng đề xuất tháng",
    field: "countOfYear",
    type: "numeric",
    width: 50,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },

  {
    title: "Số lượng cần bổ sung (trong kế hoạch)",
    field: "countOfUsed",
    type: "numeric",
    width: 50,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: "Số lượng cần bổ sung (ngoài kế hoạch)",
    field: "countOfMonth",
    type: "numeric",
    width: 50,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: "Cần gấp",
    field: "isUrgen",
    type: "boolean",
    width: 50,
  },

  {
    title: translate("resources.supplies.fields.note"),
    field: "note",
    width: 50,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
    render: (row: any) => {
      return <TextFieldMore record={row} source="note" />;
    },
  },

  {
    title: "Ảnh",
    field: "imageUrl",
    editComponent: (row: any) => {
      return <UploadPDF {...row} />;
    },
    render: (row: any) => {
      return <UploadPDF {...row} value={row.imageUrl} disable />;
    },
  },

  {
    title: translate("pos.action.more"),
    render: (row: any) => {
      return <ShowMoreButton {...row} />;
    },
    width: 50,
  },
];

export const colSCDX1 = (translate: any) => [
  {
    title: translate("resources.supplies.fields.materialCode"),
    field: "materialCode",
    editComponent: (row: any) => {
      return <EditComponent {...row} />;
    },
    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },

  {
    title: translate("resources.supplies.fields.system"),
    field: "system",
    editComponent: (row: any) => {
      return <EditComponent {...row} />;
    },
    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: translate("resources.supplies.fields.deviceName"),
    field: "deviceName",
    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: translate("resources.supplies.fields.name"),
    field: "name",
    editComponent: (row: any) => {
      return <EditComponent {...row} />;
    },
    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: translate("resources.supplies.fields.info"),
    field: "info",
    editComponent: (row: any) => {
      return <EditComponent {...row} multiline />;
    },
    width: 300,
    cellStyle: { maxWidth: 300, overflowWrap: "break-word" },
    render: (row: any) => {
      return <TextFieldMore record={row} source="info" />;
    },
  },

  {
    title: translate("resources.supplies.fields.code"),
    field: "code",
    editComponent: (row: any) => {
      return <EditComponent {...row} />;
    },
    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },

  {
    title: translate("resources.supplies.fields.madein"),
    field: "madein",
    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: translate("resources.supplies.fields.unit"),
    field: "unit",
    width: 50,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },

  {
    title: translate("resources.supplies.fields.count"),
    field: "count",
    type: "numeric",

    width: 50,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: "Cần gấp",
    field: "isUrgen",
    type: "boolean",
    width: 50,
  },

  {
    title: translate("resources.supplies.fields.note"),
    field: "note",

    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
    render: (row: any) => {
      return <TextFieldMore record={row} source="note" />;
    },
  },

  {
    title: "Ảnh",
    field: "imageUrl",
    editComponent: (row: any) => {
      return <UploadPDF {...row} />;
    },
    render: (row: any) => {
      return <UploadPDF {...row} value={row.imageUrl} disable />;
    },
  },

  {
    title: translate("pos.action.more"),

    render: (row: any) => {
      return <ShowMoreButton {...row} />;
    },
    width: 50,
  },
];

export const getCols = (
  translate: any,
  statusType: BillStatusType,
  billType: BillType
) => {
  let cols: any[] = [];

  if (billType === BillType.VAT_TU_TIEU_HAO) {
    if (isStatusBillType1(statusType)) {
      cols = colVTTH1(translate);
    } else {
      cols = colVTTH2(translate);
    }
  } else {
    cols = colSCDX1(translate);
  }
  return cols.map((i: any) => ({
    ...i,
    headerStyle: {
      ...(i.headerStyle || {}),
      padding: "4px",
    },
    cellStyle: {
      ...(i.cellStyle || {}),
      padding: "4px",
      borderWidth: 1,
      borderColor: "black",
      borderStyle: "solid",
    },
  })) as Column<any>[];
};

export const options = (isClone: boolean) =>
  ({
    search: false,
    actionsColumnIndex: -1,
    showSelectAllCheckbox: false,
    showTextRowsSelected: false,
    pageSizeOptions: [10, 25, 50, 100],
    pageSize: 10,
    padding: "dense",
    tableLayout: "auto",
    selection: isClone,
    selectionProps: (row: any) => {
      if (row.checked)
        return {
          checked: true,
        };
      return { checked: false };
    },
    headerStyle: {
      borderWidth: 1,
      borderColor: "black",
      borderStyle: "solid",
    },
    rowStyle: (rowData) => {
      if (rowData.isModified) {
        return {
          padding: 0,
          fontSize: "14px",
          paddingBlock: 0,
          backgroundColor: "#ffe54c",
        };
      }
      return {
        padding: 0,
        fontSize: "14px",
        paddingBlock: 0,
      };
    },
    exportFileName: "vat_tu",
    actionsCellStyle: {
      padding: "4px",
      borderWidth: 1,
      borderColor: "black",
      borderStyle: "solid",
    },
  } as Options<any>);

export const showAddAction = (isClone: boolean, statusType: any) => {
  const isShowAdd =
    [BillStatusType.APPROVED_BY_KT, BillStatusType.APPROVED_BY_STAFF].includes(
      statusType
    ) || !statusType;

  return isShowAdd;
};

const resolveId = (billType: string) => {
  if (billType === BillType.VAT_TU_TIEU_HAO) {
    // if (isStatusBillType1(statusType)) {
    //   cols = colVTTH1(translate);
    // } else {
    //   cols = colVTTH2(translate);
    // }
    return "vtth";
  } else {
    return "scdx";
  }
};
const TableSupply = memo((props: any) => {
  const inputProps = useField(props.source);
  const { isClone, statusType, type: billType } = props;
  const match = useRouteMatch<{ id: string }>();
  const billId = match.params.id;

  const translate = useTranslate();
  const isShowAdd = showAddAction(isClone, statusType);
  const data = _.reject(
    inputProps.input.value,
    (val) => val.isActive === false
  );
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState<any>(
    data?.filter((i) => i?.checked)
  );

  return (
    <div id={`table-supply-${resolveId(billType)}${isClone ? "-group" : ""}`}>
      <MaterialTable
        icons={tableIcons}
        title={translate("resources.bills.zones.pl2")}
        options={options(isShowAdd)}
        columns={getCols(translate, statusType, billType) as any}
        data={data}
        components={components(
          billId,
          "supplies",
          isShowAdd,
          inputProps,
          billType,
          selectedId,
          setSelectedId,
          show,
          setShow
        )}
        localization={{
          pagination: { labelRowsSelect: "bản ghi" },
          body: {
            emptyDataSourceMessage: "Không có bản ghi nào",
            editRow: { deleteText: "Xóa bản ghi?" },
          },
          header: { actions: "Chỉnh sửa" },
        }}
        onSelectionChange={(rows, rowData) => {
          const newData = inputProps.input.value.map((input: any) => {
            return {
              ...input,
              checked:
                input.materialCode === rowData.materialCode
                  ? !input.checked
                  : input.checked,
            };
          });
          inputProps.input.onChange(newData);
          setSelectedId(newData?.filter((i: any) => i?.checked));
        }}
        editable={
          isShowAdd
            ? {
                onRowAdd: (newData: any) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      if (!newData.materialCode) {
                        inputProps.input.onChange([
                          ...(inputProps.input.value || []),
                          newData,
                        ]);
                        return resolve(null);
                      }
                      const oldRow = (inputProps.input.value || []).find(
                        (row: any) => {
                          return row.materialCode === newData.materialCode;
                        }
                      );

                      if (!oldRow) {
                        inputProps.input.onChange([
                          ...(inputProps.input.value || []),
                          newData,
                        ]);

                        return resolve(null);
                      }

                      inputProps.input.onChange(
                        (inputProps.input.value || []).map((row: any) => {
                          if (row.materialCode === newData.materialCode) {
                            return {
                              ...row,
                              deviceName: "",
                              system: "",
                              count:
                                _.toInteger(row.count || 0) +
                                _.toInteger(newData.count || 0),
                            };
                          }
                          return row;
                        })
                      );

                      resolve(null);
                    }, 0);
                  }),
                onRowUpdate: (newData: any, oldData: any) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      // if (billType === BillType.VAT_TU_TIEU_HAO) {
                      //   if (!newData.materialCode || !newData.system) {
                      //     notify("pos.action.fillMissing");
                      //     return reject();
                      //   }
                      // }

                      const dataUpdate = [...inputProps.input.value];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      inputProps.input.onChange([...dataUpdate]);

                      resolve(null);
                    }, 0);
                  }),
                onRowDelete: (oldData: any) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataDelete = [...inputProps.input.value];
                      const index = oldData.tableData.id;
                      dataDelete.splice(index, 1);
                      inputProps.input.onChange(dataDelete);
                      resolve(null);
                    }, 100);
                  }),
              }
            : { onRowAdd: () => new Promise((resolve) => {}) }
        }
      />
    </div>
  );
});

export default TableSupply;
