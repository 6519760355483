import * as _ from "lodash";
import {useHistory} from "react-router";
import {Button, useRefresh} from "react-admin";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import {useMutateDeleteCRUD} from "../hooks/useMutate";
import {useNotify} from "ra-core";
import {MaintenanceStatus} from "./Types";

const EditButton = (props: any) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const id = _.get(props, "record.id", "");
  const status = _.get(props, "record.status", MaintenanceStatus.OPENING);
  const history = useHistory();
  const [{}, deleteCmd] = useMutateDeleteCRUD("maintenance", [], (err) => {
    notify('Xóa thành công');
    refresh()
  });
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      <Button
        component="button"
        variant="contained"
        label="Sửa"
        onClick={() => {
          history.push(`/maintenance/${id}`);
        }}
      >
        <EditIcon style={{fontSize: "20"}}/>
      </Button>
      {status !== MaintenanceStatus.FINISH && (
        <Button
          label="Xóa"
          style={{color: "red"}}
          onClick={() =>
            deleteCmd({id})
          }
        >
          <DeleteOutline style={{color: "red"}}/>
        </Button>
      )}

    </div>
  );
};

export default EditButton
