import { Box, Card, CardContent } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import trim from "lodash/trim";
import React from "react";
import {
  Create,
  FormWithRedirect,
  ReferenceInput,
  SelectInput,
  TextInput,
  Toolbar,
  useTranslate,
  SaveButton,
} from "react-admin";
import ListActions from "./ListActions";
import { DepartmentType } from "../helpers";

const MajorCreate = (props: any) => {
  return (
    <Create {...props} undoable={false} actions={<ListActions />}>
      <Form />
    </Create>
  );
};

export const Form = (props: any) => {
  const translate = useTranslate();
  const { isEdit } = props;
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box
                display={{ md: "block", lg: "flex" }}
                style={{ alignItems: "center" }}
              >
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="name" resource="departments" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <SelectInput
                    source="type"
                    resource="departments"
                    fullWidth
                    choices={Object.keys(DepartmentType).map((key) => ({
                      id: (DepartmentType as any)[key],
                      name: translate(`resources.departments.fields.${key}`),
                    }))}
                  />
                </Box>
              </Box>
              <Box
                display={{ md: "block", lg: "flex" }}
                style={{ alignItems: "center" }}
              >
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="alias" resource="departments" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <ReferenceInput
                    source="parentId"
                    reference="departments"
                    filter={{ "parentId||$isnull": true }}
                    resource="departments"
                    filterToQuery={(searchText: any) => {
                      return isEmpty(trim(searchText))
                        ? null
                        : { name: searchText };
                    }}
                  >
                    <SelectInput optionText="name" fullWidth />
                  </ReferenceInput>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="departments"
              undoable={false}
            >
              <SaveButton
                saving={formProps.saving}
                redirect={isEdit ? "edit" : "list"}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

export default MajorCreate;
