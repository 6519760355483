// @ts-ignore
import vietnameseMessages from "ra-language-vietnamese";

const customVietnameseMessages: any = {
  debug: false,
  ...vietnameseMessages,
  pos: {
    search: "Search",
    configuration: "Configuration",
    language: "Language",
    userinfo: "Thông tin tài khoản",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark",
    },
    dashboard: {
      student_total: "Học viên",
      course_total: "Khóa học",
      unit_total: "Bài học",
    },
    menu: {
      setting: "Cài đặt",
      workticket: "Quản lý phiếu công tác",
      bill: "Quản lý phiếu đề xuất",
    },
    action: {
      rejected: "Chưa duyệt",
      importUser: "Import tài khoản",
      save: "Lưu",
      reset: "Hủy",
      clone: "Tạo từ phiếu đã lưu",
      more: "Xem thêm",
      approval: "Phê duyệt",
      reject: "Trả lại",
      fillMissing: "Điền thiếu dữ liệu",
      exportPDX: "Xuất file docx",
      exportSupply: "Xuất vật tư",
      grant: "Phân quyền",
      history: "Xem lịch sử",
      downloadTemplate: "File mẫu vật tư",
      groupBill: "Tạo phiếu gộp",
      importExistsSupply: "Nhập tồn kho",
      importSupply: "Nhập vật tư",
      downloadTemplateExistSupply: "File mẫu tồn kho",
      downloadTemplatePackageSupply: "File mẫu nhập tiến độ phụ lục 1",
      downloadTemplatePackageSupply2: "File mẫu nhập tiến độ phụ lục 2",
      header: "Thao tác",
      showEdit: "Chi tiết",
      showCreateKt: "Tạo đề xuất",
      showCreateGt: "Tạo gói thầu",
      exportGT: "Xuất gói thầu",
      exportTicket: "Xuất phiếu công tác",
      exportTicketWithPL: "Xuất phiếu công tác kèm phụ lục",
      exportRegisterTicket: "Xuất đăng ký công tác",
      exportCMDTicket: "Xuất lệnh công tác",
    },
  },

  common: {
    fields: {
      f: "Nữ",
      m: "Nam",
      fm: "Không yêu cầu",
      createdAt: "Ngày tạo",
      createdBy: "Người tạo",
      updatedBy: "Người chỉnh sửa",
      showCourse: "Xem các khóa học",
    },
  },
  resources: {
    missingdevices: {
      name: "Quản lý khiếm khuyết thiết bị",
      fields: {
        to_may: "Tổ máy",
        he_thong: "Hệ thống",
        uu_tien_hoan_thanh: "Ưu tiên hoàn thành sớm",
        ngay_phat_hien: "Ngày phát hiện",
        ngay_ket_thuc: "Ngày ưu tiên hoàn thành sớm",
        billId: "Tiến độ vật tư"
      },
      status: {
        opening: "Đang mở",
        finish: "Khoá kết thúc",
      },
    },
    maintenance: {
      name: "Sửa chữa thường xuyên",
      fields: {
        to_may: "Tổ máy",
        he_thong: "Hệ thống",
        ma_kks: "Mã KKS",
        tuan: "Tuần",
        tuan1: "Tuần 1",
        tuan2: "Tuần 2",
        tuan3: "Tuần 3",
        tuan4: "Tuần 4",
        noi_dung: "Nội dung công việc",
        kq_thuc_hien: "Kết quả thực hiện",
        p4_danh_gia: "P4 đánh giá",
      },
      status: {
        opening: "Đang mở",
        finish: "Khoá kết thúc",
      },
    },
    departments: {
      name: "Quản lý phòng ban",
      fields: {
        name: "Tên phòng ban",
        createdAt: "Ngày tạo",
        parentId: "Phòng ban cấp trên",
        PHAN_XUONG: "Phân xưởng",
        KY_THUAT: "Kỹ thuật",
        GIAM_DOC: "Giám đốc",
        KE_HOACH: "Kế hoạch",
        type: "Phân loại phòng ban",
        alias: "Viết tắt",
      },
      page: {
        list: "Danh sách phòng ban",
      },
    },
    positions: {
      name: "Vị trí",
      fields: {
        departmentId: "Phòng ban",
        name: "Tên vị trí",
        isLeader: "Lãnh đạo",
        createdAt: "Ngày tạo",
      },
      page: {
        list: "Danh sách",
      },
    },

    items: {
      name: "Mặt hàng",
      fields: {
        supplyId: "Vật tư",
        code: "Mã",
        createdAt: "Ngày tạo",
      },
      page: {
        list: "Danh sách",
      },
    },

    loggers: {
      name: "Lịch sử",

      page: {
        list: "Danh sách",
      },
    },

    supplyrelativebills: {
      fields: {
        code: "Mã hiệu",
        name: "Tên vật tư",
        materialCode: "Mã vật tư",

        ktBill: "Phiếu P4",
        pxBill: "Phiếu PX",
        packageSupplies: "Tiến độ mua sắm",
      },
    },

    supplies: {
      name: "Quản lý vật tư",
      importName: "vật tư",
      search_name: "Tìm kiếm vật tư",
      fields: {
        deviceName: "Thiết bị",
        countOfYear: "Số lượng đề xuất của năm",
        countOfMonth: "Số lượng đề xuất tháng",
        countOfUsed: "Số lượng đã sử dụng",
        serviceName: "Tên dịch vụ",
        content: "Nội dung công việc",
        createdBy: "Người tạo",
        updatedBy: "Người cập nhật",
        code: "Mã hiệu",
        materialCode: "Mã vật tư",
        materialCode2: "Thiết bị/hệ thống",
        image: "Hình ảnh",
        name: "Tên vật tư",
        status: "Tình trạng",
        createdAt: "Ngày tạo",
        outOfStock: "Hết hàng",
        inStock: "Còn hàng",
        madein: "Hãng xuất xứ",
        count: "Số lượng",
        info: "Thông số kỹ thuật",
        unit: "Đơn vị",
        note: "Ghi chú",
        category: "Danh mục hàng",
        system: "Hệ thống",
        idkt: "ID P4",
        idpx: "ID PX",
        buyingStatus: "Tiến độ mua sắm",
      },
      page: {
        list: "Danh sách",
        list_search: "Tìm kiếm vật tư",
      },
    },

    targets: {
      name: "Quản lý dịch vụ",
      importName: "Dịch vụ",
      search_name: "Tìm kiếm dịch vụ",
      fields: {
        serviceName: "Tên dịch vụ",
        content: "Nội dung công việc",
        count: "Số lượng",
        unit: "Đơn vị",
        note: "Ghi chú",
        buyingStatus: "Tiến độ mua sắm",
        idkt: "ID P4",
        idpx: "ID PX",
      },
      page: {
        list: "Danh sách",
        list_search: "Tìm kiếm dịch vụ",
      },
    },

    bills: {
      name: "Danh sách phiếu đề xuất",
      zones: {
        approval: "PHÊ DUYỆT",
        historyList: "LỊCH SỬ PHÊ DUYỆT",
        draftsupplies: "DANH SÁCH VẬT TƯ",
        common: "Phiếu đề xuất",
        pl1: "Phụ lục 1",
        pl2: "Phụ lục 2",
        pl3: "Phụ lục 3",
      },
      fields: {
        childIds: "Các phiếu gộp",
        billNumber: "Số phiếu",
        billName: "Tên phiếu",
        type: "Loại phiếu",
        createdBy: "Người tạo",
        updatedBy: "Người cập nhật",
        code: "Mã vật tư",
        name: "Tên vật tư",
        status: "Tình trạng",
        createdAt: "Ngày tạo",
        count: "Số lượng",
        content: "Căn cứ",
        createdTime: "Ngày tạo phiếu",
        solution: "Phương án giải quyết",
        statusBills: "Trạng thái phê duyệt",
        MUA_SAM_KE_HOACH_NAM: "Mua sắm kế hoạch năm",
        MUA_SAM_KE_HOACH_THANG: "Mua sắm kế hoạch tháng",
        SUA_CHUA_DOT_XUAT: "Sửa chữa đột xuất",
        SUA_CHUA_SU_CO: "Sửa chữa sự cố",
        VAT_TU_TIEU_HAO: "Vật tư tiêu hao",

        APPROVED_BY_STAFF: "Chưa duyệt",
        APPROVED_BY_LEADER_STAFF: "Đã duyệt",
        APPROVED_BY_KT: "Chưa duyệt",
        APPROVED_BY_LEADER_KT: "Đã duyệt",
        APPROVED_BY_DIRECTOR: "Duyệt bởi giám đốc",
        BUY_SUCCESS: "Mua hoàn tất",

        attachment: "Tệp đính kèm",
        approvalDepartmentId: "Chọn phòng ban cấp trên phê duyệt",
      },
      page: {
        list: "Danh sách",
      },
    },

    packages: {
      name: "Quản lý gói thầu",
      zones: {
        common: "Thông tin gói thầu",
        phuluc: "Thông tin vật tư",
      },
      fields: {
        billIds: "Các phiếu",
        packageName: "Tên gói thầu",
        buyingType: "Loại hình mua sắm",
        createdBy: "Người tạo",
        updatedBy: "Người cập nhật",
        code: "Mã vật tư",
        name: "Tên vật tư",
        status: "Tình trạng",
        createdAt: "Ngày tạo",
        count: "Số lượng",
        content: "Căn cứ",
        createdTime: "Ngày tạo",
      },
      page: {
        list: "Danh sách",
      },
    },

    worktickets: {
      name: "Giấy đăng ký công tác",
      zones: {
        common: "Thông tin đăng ký công tác",
        phuluc: "10. Danh sách nhân viên",
        "relative-department": "11. Danh sách phòng ban liên quan",
      },
      status: {
        created: "Tạo mới",
        sended: "Đã trình",
        rejected: "Chưa duyệt",
        approved: "Đã duyệt",
      },
      fields: {
        mainEmail: "Trình đơn vị QLVH",
        ccEmail: "Email CC",
        ticketNumber: "Số phiếu",
        fullTicketNumber: "Số phiếu",
        showApproved: "Lọc phiếu duyệt",
        otherCondition: "12. Yêu cầu cấp LCT/ PCT Điện/ PCT CHN",
        targetId: "Kính gửi",
        workAddress: "2. Địa điểm",
        conditionScope: "3. Điều kiện về an toàn điện để thực hiện công việc",
        startDate: "4.1 Ngày tiến hành",
        endDate: "4.2 Ngày kết thúc",
        createdBy: "Người tạo",
        numberOfDepartment: "5. Số lượng đơn vị công tác",
        staffId: "Nhân viên đơn vị công tác",
        staffScope: "Thẻ An toàn",
        staffDes: "6. Số lượng nhân viên đơn vị công tác",
        leaderId: "7. Lãnh đạo công việc",
        leaderScope: "Thẻ An toàn",
        scopeWatcherId: "8. Người giám sát ATĐ",
        watcherScope: "Thẻ An toàn",
        directWatcherId: "9. Người chỉ huy trực tiếp",
        directWatcherScope: "Thẻ An toàn",
        status: "Tình trạng",
        createdAt: "Ngày tạo",
        content: "1. Nội dung công việc",
        aliasContent: "Nội dung",
        sendedUserId: "Trình đến trưởng ca",
      },
      page: {
        list: "Phiếu công tác",
      },
    },
    eworktickets: {
      name: "Phiếu công tác điện",
      zones: {
        common: "Thông tin phiếu công tác điện",
      },
      value: {
        TYPE1: "Thiết bị, đường dây, đoạn đường dây đã cắt điện",
        TYPE2: "Nối đất tại",
        TYPE3: "Làm rào chắn",
        TYPE4: "Treo biển báo tại",
      },
      status: {
        creating: "Tạo mới",
        checking: "Cấp lần 1",
        sending: "Đang cấp",
        opening: "Đang mở",
        finish: "Khoá kết thúc",
        close: "Hoàn thành phiếu",
        chtt_close: "Chỉ huy trực tiếp khóa phiếu",
        chtt_open: "Chỉ huy trực tiếp mở phiếu",
        ncp_open: "Người cho phép mở phiếu",
        ncp_close: "Người cho phép khóa phiếu",
      },
      fields: {
        fullTicketNumber: "Số phiếu",
        mainEmail: "Cấp phiếu cho",
        ccEmail: "Email CC",
        ticketNumber: "Số phiếu",
        workArea: "2.2 Phạm vi được phép làm việc",
        groundingUnitAt3_1: "3.1. Đơn vị công tác làm nối đất tại:",
        workUnitTakesOther3_2: "3.2. Đơn vị công tác làm các biện pháp an toàn khác:",
        sendedUserId: "Cấp phiếu cho",
        warningMessage: "2.3 Cảnh báo, chỉ dẫn các yếu tố nguy hiểm",
        workAddress: "1.4 Địa điểm",
        startDate: "1.6.1 Ngày tiến hành",
        endDate: "1.6.2 Ngày kết thúc",
        handOverDate: "2.4 Ngày bàn giao",
        createdBy: "Người tạo",
        numberOfStaff: "1.3 Số lượng nhân viên",
        staffDepartmentId: "Phòng ban nhân viên",
        departmentStaff: "Phòng ban liên quan",
        staffId: "Nhân viên đơn vị công tác",
        staffScope: "Thẻ An toàn",
        leaderId: "1.1 Lãnh đạo công việc",
        leaderScope: "Thẻ An toàn",
        scopeWatcherId: "1.9 Người giám sát ATĐ",
        watcherScope: "Thẻ An toàn",
        departmentContent: "1.8 Các đơn vị liên quan",
        directWatcherId: "1.2 Người chỉ huy trực tiếp",
        directWatcherScope: "Thẻ An toàn",
        status: "Tình trạng",
        createdAt: "Ngày tạo",
        content: "1.5 Nội dung công việc",
        aliasContent: "Nội dung",
        reasons: "1.7 Điều kiện tiến hành",
        images: "Hình ảnh",
        image2s: "Hình ảnh vi phạm",
        files: "Tệp đính kèm",
      },
      page: {
        list: "Phiếu công tác điện",
      },
    },
    hworktickets: {
      name: "Phiếu công tác cơ hóa nhiệt",
      zones: {
        common: "Thông tin phiếu công tác cơ hóa nhiệt",
      },
      value: {
        // TYPE1: "Cắt nguồn lực, nguồn điều khiển đo lường liên quan",
        // TYPE2: "Các thiết bị đã tách khỏi hệ thống",
        TYPE1: "Các biện pháp an toàn về Điện",
        TYPE2: "Các biện pháp an toàn Cơ - Nhiệt - Hóa",
        TYPE3: 'Treo biển báo "Cấm thao tác" tại',
      },
      status: {
        creating: "Tạo mới",
        checking: "Cấp lần 1",
        sending: "Đang cấp",
        opening: "Đang mở",
        finish: "Khoá kết thúc",
        close: "Hoàn thành phiếu",
        chtt_close: "Chỉ huy trực tiếp khóa phiếu",
        chtt_open: "Chỉ huy trực tiếp mở phiếu",
        ncp_open: "Người cho phép mở phiếu",
        ncp_close: "Người cho phép khóa phiếu",
      },
      fields: {
        fullTicketNumber: "Số phiếu",
        eEmail: "Cấp điện",
        hEmail: "Cấp cơ hóa nhiệt",
        mainEmail: "Cấp phiếu cho",
        ccEmail: "Email CC",
        ticketNumber: "Số phiếu",
        leaderId: "1.1 Lãnh đạo công việc",
        directWatcherId: "1.2 Người chỉ huy trực tiếp",
        numberOfStaff: "1.3 Số lượng nhân viên",
        departmentStaff: "Phòng ban liên quan",
        workArea: "2.2 Phạm vi được phép làm việc",
        warningMessage:
          "2.3 Cảnh báo, chỉ dẫn cần thiết về các yếu tố nguy hiểm",
        handOverDate: "2.4.Người chỉ huy trực tiếp đã kiểm tra vào lúc",
        groundingUnitAt3_1: "3.1. Đơn vị công tác đã làm biện pháp an toàn",
        sendedUserId: "Cấp phiếu cho",
        workAddress: "1.4 Địa điểm",
        content: "1.5 Nội dung công việc",
        startDate: "1.6.1 Ngày tiến hành",
        endDate: "1.6.2 Ngày kết thúc",
        reasons: "1.7 Điều kiện tiến hành",
        createdBy: "Người tạo",
        staffDepartmentId: "Phòng ban nhân viên",
        staffId: "Nhân viên đơn vị công tác",
        scopeWatcherId: "1.8 Người giám sát ATĐ",
        status: "Tình trạng",
        createdAt: "Ngày tạo",
        aliasContent: "Nội dung",
        images: "Hình ảnh",
        files: "Tệp đính kèm",
        image2s: "Hình ảnh vi phạm",
      },
      page: {
        list: "Phiếu công tác cơ hóa nhiệt",
      },
    },
    cmdworktickets: {
      name: "Lệnh công tác",
      zones: {
        common: "Thông tin lệnh công tác",
        phuluc: "Danh sách nhân viên",
      },
      status: {
        creating: "Đang tạo",
        sending: "Đã cấp",
        opening: "Đang tiến hành",
        finish: "Kết thúc",
        close: "Hoàn thành",
      },
      fields: {
        fullTicketNumber: "Số lệnh",
        ticketNumber: "Số lệnh",
        directWatcher2Id: "2.1 Người chỉ huy trực tiếp",
        workCondition: "Biện pháp an toàn",
        workName: "Trình tự công việc",
        mainEmail: "Cấp lệnh cho",
        ccEmail: "Email CC",
        works: "2.3 Trình tự công việc",
        workAddress: "1.3 Địa điểm (hoặc thiết bị) tiến hành công tác",
        startDate: "1.6 Ngày tiến hành",
        endDate: "3.1 Ngày kết thúc",
        createdBy: "Người tạo",
        numberOfStaff: "1.2 Số lượng nhân viên",
        staffDepartmentId: "Phòng ban nhân viên",
        departmentStaff: "Phòng ban liên quan",
        recallUserId: "3.2 Người chỉ huy trực tiếp đã khai báo cho ông (bà)",
        recallUserTitle: "Chức danh",
        scopeWatcherId: "2.2 Người giám sát ATĐ",
        watcherScope: "Thẻ An toàn",
        departmentContent: "1.8 Các đơn vị liên quan",
        directWatcherId: "1.1 Người chỉ huy trực tiếp",
        directWatcherScope: "Thẻ An toàn",
        status: "Tình trạng",
        createdAt: "Ngày tạo",
        content: "1.4 Nội dung công tác",
        conditionScope: "1.5 Điều kiện về an toàn điện để tiến hành công việc",
        aliasContent: "Nội dung",
        reasons: "Điều kiện tiến hành",
        images: "Hình ảnh thi công",

        files: "Tệp đính kèm",
      },
      page: {
        list: "Phiếu công tác điện",
      },
    },

    permissions: {
      page: {
        list: "Thêm quyền hạn",
      },
      zones: {
        user: "Tài khoản",
        department: "Khoa, phòng ban",
        supply: "Vật tư",
        bill: "Phiếu đề xuất",
        "export-bill": "Xuất phiếu",
        "approval-bi": "Phê duyệt",
        permission: "Quyền hạn",
        "import-supp": "Nhập vât tư và tồn kho",
        workticket: "Phiếu công tác",
        eworkticket: "Phiếu công tác điện",
        cmdworktick: "Lệnh công tác",
        hworkticket: "Lệnh công cơ hóa nhiệt",
        package: "Gói thầu",
        ticket: "Quản lý công tác",
      },
      fields: {
        all: "Tất cả",

        "manage-ticket": "Quản lý công tác",

        "create-user": "Tạo tài khoản",
        "update-user": "Cập nhật tài khoản",
        "read-user": "Đọc tài khoản",
        "delete-user": "Xóa tài khoản",

        "create-department": "Tạo phòng ban",
        "update-department": "Cập nhật phòng ban",
        "read-department": "Xem phòng ban",
        "delete-department": "Xóa phòng ban",

        "create-supply": "Tạo vật tư",
        "update-supply": "Cập nhật vật tư",
        "read-supply": "Xem vật tư",
        "delete-supply": "Xóa vật tư",

        "create-bill": "Tạo phiếu đề xuất",
        "update-bill": "Cập nhật phiếu đề xuất",
        "read-bill": "Xem phiếu đề xuất",
        "delete-bill": "Xóa phiếu đề xuất",

        "create-package": "Tạo gói thầu",
        "update-package": "Cập nhật gói thầu",
        "read-package": "Xem gói thầu",
        "delete-package": "Xóa gói thầu",

        "create-workticket": "Tạo đăng ký công tác",
        "update-workticket": "Cập nhật đăng ký công tác",
        "read-workticket": "Xem đăng ký công tác",
        "delete-workticket": "Xóa đăng ký công tác",
        "approve-workticket": "Phê duyệt đăng ký công tác",

        "create-eworkticket": "Tạo Phiếu công tác điện",
        "update-eworkticket": "Cập nhật Phiếu công tác điện",
        "read-eworkticket": "Xem Phiếu công tác điện",
        "delete-eworkticket": "Xóa Phiếu công tác điện",

        "create-hworkticket": "Tạo Phiếu công tác cơ hóa nhiệt",
        "update-hworkticket": "Cập nhật Phiếu công tác cơ hóa nhiệt",
        "read-hworkticket": "Xem Phiếu công tác cơ hóa nhiệt",
        "delete-hworkticket": "Xóa Phiếu công tác cơ hóa nhiệt",

        "create-cmdworkticket": "Tạo Lệnh công tác",
        "update-cmdworkticket": "Cập nhật Lệnh công tác",
        "read-cmdworkticket": "Xem Lệnh công tác",
        "delete-cmdworkticket": "Xóa Lệnh công tác",

        "create-export-bill-type1": "Xuất phiếu đề xuất",
        "create-export-bill-type2": "Xuất phê duyệt phiếu đề xuất",

        "create-import-supplies": "Nhập vật tư",
        "create-import-supplies-exists": "Nhập tồn kho",

        "create-approval-bill": "Phê duyệt phiếu đề xuất",
        "update-permission": "Phân quyền người dùng",
      },
    },
    users: {
      name: "Quản lý tài khoản",
      fields: {
        emailGroup: "Email nhóm",
        fullname: "Tên đầy đủ",
        email: "Email",
        username: "Tên tài khoản",
        telephone: "Số điện thoại",
        positionId: "Vị trí",
        dob: "Ngày sinh",
        address: "Địa chỉ",
        scope: "Thẻ An toàn",
        createdAt: "Ngày tạo",
      },
      page: {
        list: "Danh sách",
      },
    },
  },
};

export default customVietnameseMessages;
