import React from "react";
import { FileField } from "react-admin";
import "./PreviewImage.scss";

const FileFromInput = (props: any) => {
  return (
    <>
      <FileField {...props} target="_blank" />
    </>
  );
};
const FileFromUrl = (props: any) => {
  return (
    <>
      <FileField
        {...props}
        record={{
          [props.source]: `${process.env.REACT_APP_API_BASE_URL}${props.record.data}`,
        }}
        download
        target="_blank"
        title={props.record.name}
      />
    </>
  );
};

const PreviewInputFile = (props: any) => {
  return typeof props.record.data === "string" ? (
    <FileFromUrl {...props} />
  ) : (
    <FileFromInput {...props} />
  );
};
export default PreviewInputFile;
