import React, { FC } from "react";
import { Filter, SelectInput, TextInput } from "react-admin";

const ListFilterSearch: FC<any> = (props) => (
  <fieldset className="filter-fieldset">
    <legend>Tìm kiếm</legend>
    <Filter {...props}>
      <TextInput source="materialCode" alwaysOn variant="outlined" />
      <TextInput variant="outlined" source="name" alwaysOn />
      <TextInput alwaysOn source="code" label="Mã hiệu" variant="outlined" />
      <TextInput
        alwaysOn
        source="info"
        label="Thông số kỹ thuật"
        variant="outlined"
      />
      <SelectInput
        alwaysOn
        source="isUrgen"
        variant="outlined"
        fullWidth
        label="Vật tư cần gấp"
        choices={[
          { id: 0, name: "Chưa cần gấp" },
          { id: 1, name: "Cần gấp" },
        ]}
      />
    </Filter>
  </fieldset>
);

export default ListFilterSearch;
