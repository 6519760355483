import {
    Button, Select, TextField, MenuItem, CircularProgress
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, {useEffect, useState} from "react";
import {useApiFetch} from "../helpers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FileSaver from "file-saver";


export default function PopupDownloadPDFByMonth(props: any) {
    const {showPopup, setShowPopup} = props;
    const [month, setMonth] = useState<number>(1);
    const [file, setFile] = useState<any>(undefined);
    const {data, loading} = useApiFetch(
        `/api/maintenance/${month}/files`,
        [month]
    );
    const handleChange = (e: any) => {
        setMonth(e.target.value)
    };
    const handleClose = () => {
        setMonth(1);
        setShowPopup(false)
    }
    const handleDownload = () => {
        if(file){
            let href = `${process.env.REACT_APP_API_BASE_URL}/${file.fileUrl}`;
            FileSaver.saveAs(
                href,
                file.fileName
            )
        }
    }
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<any[]>([]);
    useEffect(() => {
        if (data && data.length) {
            const results = data.map((d: any)=>{
                return {
                    ...d,
                    value: d.fileName
                }
            });
            setOptions(results);
        }
    }, [data]);
    return (
        <Dialog
            open={showPopup}
            onClose={() => handleClose()}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Nhập vào tháng để tìm file</DialogTitle>
            <DialogContent>
                <p>Chọn tháng để tìm file</p>
                <Select
                    style={{width: "100%", marginBottom: "10px"}}
                    value={month}
                    label="Tháng"
                    onChange={handleChange}
                    variant="outlined"
                >
                    <MenuItem value={1}>Tháng 1</MenuItem>
                    <MenuItem value={2}>Tháng 2</MenuItem>
                    <MenuItem value={3}>Tháng 3</MenuItem>
                    <MenuItem value={4}>Tháng 4</MenuItem>
                    <MenuItem value={5}>Tháng 5</MenuItem>
                    <MenuItem value={6}>Tháng 6</MenuItem>
                    <MenuItem value={7}>Tháng 7</MenuItem>
                    <MenuItem value={8}>Tháng 8</MenuItem>
                    <MenuItem value={9}>Tháng 9</MenuItem>
                    <MenuItem value={10}>Tháng 10</MenuItem>
                    <MenuItem value={11}>Tháng 11</MenuItem>
                    <MenuItem value={12}>Tháng 12</MenuItem>
                </Select>

                <p>Chọn tháng để tìm file</p>
                <Autocomplete
                    open={open}
                    onChange={(e, value) => {
                        setFile(value)
                    }}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option: any) => option.value}
                    options={options}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="File"
                            variant="standard"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? (
                                            <CircularProgress color="inherit" size={20}/>
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} color="primary">
                    Hủy bỏ
                </Button>
                <Button onClick={() => handleDownload()} color="primary">
                    Tải xuống
                </Button>
            </DialogActions>
        </Dialog>
    );
}
