import { Chip } from "@material-ui/core";
import * as _ from "lodash";
import React from "react";
import { useTranslate } from "react-admin";

export const BillStatusChangeField = (props: any) => {
  const translate = useTranslate();
  const { record, source } = props;

  if (!record) return <></>;
  return (
    <div>
      <Chip
        label={translate(`resources.bills.fields.${record[source]}`)}
        size="small"
      />
    </div>
  );
};

export const CustomAttachment = (props: any) => {
  const { source } = props;
  const attachmentString = _.get(props, `record[${source}]`);
  try {
    const attachment = JSON.parse(attachmentString);

    return (
      <a
        href={`${process.env.REACT_APP_API_BASE_URL + attachment.link}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {attachment.filename}
      </a>
    );
  } catch (error) {
    return <></>;
  }
};

export const CustomUser = (props: any) => {
  return (
    <div>
      <Chip label={_.get(props, "record.name")} size="small" />
    </div>
  );
};
