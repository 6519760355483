import React, { FC } from "react";
import { Filter, TextInput } from "react-admin";
import SupplyStatusInput from "../components/SupplyStatusInput";

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <TextInput source="id" />
    <TextInput source="code" />
    <TextInput source="name" />
    <SupplyStatusInput source="status" />
  </Filter>
);

export default ListFilter;
