import {
    Button, Input, Select, TextField,MenuItem
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, {useState} from "react";
import {useNotify, useRefresh} from "react-admin";

export default function PopupUploadPDFByMonth(props: any) {
    const {showPopup, setShowPopup} = props;
    const [month, setMonth] = useState('');
    const [file, setFile] = useState<File | undefined>(undefined);
    const handleChange = (e: any) => {
        setMonth(e.target.value)
    };
    const handleChangeFile = (event: any) => {
        setFile(event.target.files[0]);
    };
    const fileToBase64 = (file: File) =>{
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result?.toString().substr(reader.result?.toString().indexOf(',') + 1));
            reader.onerror = error => reject(error);
        });
    }
    const notify = useNotify();
    const handleUpload =async (event: any) => {
        if(file){
            try{
                const token = localStorage.getItem("user_token");
                const base64 = await fileToBase64(file);
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/maintenance/uploadFile/${month}`,{
                    method:'POST',
                    body:JSON.stringify({
                        pdfFile: base64,
                        fileName: file.name
                    }),
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                } );
                if(response.ok){
                    notify('Tải lên thành công', 'info');
                    handleClose();
                }
                if(response.status === 400){
                    const reason = await response.text();
                    notify(reason, 'error');
                    handleClose();
                }
            }catch(e){
                console.log(e);
            }
        }
    }
    const handleClose = ()=>{
        setFile(undefined);
        setMonth('');
        setShowPopup(false)
    }

    return (
        <Dialog
            open={showPopup}
            onClose={() => handleClose()}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Nhập vào tháng và file</DialogTitle>
            <DialogContent>
                <p>Chọn tháng để xuất file</p>
                    <Select
                        style={{width: "100%", marginBottom:"10px"}}
                        value={month}
                        label="Tháng"
                        onChange={handleChange}
                        variant="outlined"
                    >
                        <MenuItem value={1}>Tháng 1</MenuItem>
                        <MenuItem value={2}>Tháng 2</MenuItem>
                        <MenuItem value={3}>Tháng 3</MenuItem>
                        <MenuItem value={4}>Tháng 4</MenuItem>
                        <MenuItem value={5}>Tháng 5</MenuItem>
                        <MenuItem value={6}>Tháng 6</MenuItem>
                        <MenuItem value={7}>Tháng 7</MenuItem>
                        <MenuItem value={8}>Tháng 8</MenuItem>
                        <MenuItem value={9}>Tháng 9</MenuItem>
                        <MenuItem value={10}>Tháng 10</MenuItem>
                        <MenuItem value={11}>Tháng 11</MenuItem>
                        <MenuItem value={12}>Tháng 12</MenuItem>
                    </Select>
                    <p>Chọn file lưu</p>
                    <Input inputProps={{accept:".pdf,.PDF"}} type="file" onChange={handleChangeFile} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} color="primary">
                    Hủy bỏ
                </Button>
                <Button
                    color="primary"
                    onClick={handleUpload}
                >
                    Tải lên
                </Button>
            </DialogActions>
        </Dialog>
    );
}
