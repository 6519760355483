import * as _ from "lodash";
import { Avatar, CircularProgress, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { forwardRef } from "react";
import { MenuItemLink } from "react-admin";
import { useTranslate } from "react-admin";
import { AppBar, UserMenu } from "react-admin";
import { useMe } from "../helpers";
import UserIcon from "@material-ui/icons/SupervisedUserCircleOutlined";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  appName: {
    textAlign: "center",
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  avatar: {
    height: 30,
    width: 30,
  },
  userName: {
    fontSize: 16,
    marginLeft: 8,
    fontWeight: 600,
    margin: 0,
  },
  department: {
    fontSize: 14,
    marginLeft: 8,
    fontWeight: 600,
    margin: 0,
  },
  wrapperUser: {
    textAlign: "start",
    marginLeft: 8,
  },
});

const MyCustomIcon = () => {
  const { me, loading } = useMe();

  const classes = useStyles();
  if (loading || !me) return <CircularProgress />;

  return (
    <>
      <Avatar className={classes.avatar} src="/images/avatar.png" />
      <div className={classes.wrapperUser}>
        <p className={classes.userName}>{me.fullname}</p>
        <p className={classes.department}>
          {_.get(me, "position.department.name", "")}
        </p>
      </div>
    </>
  );
};

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
  const translate = useTranslate();
  const { me, loading } = useMe();
  if (loading) return <></>;
  return (
    <MenuItemLink
      ref={ref}
      to={`/users/${me.id}`}
      primaryText={translate("pos.userinfo")}
      leftIcon={<UserIcon />}
      onClick={props.onClick}
      sidebarIsOpen
    />
  );
});

const CustomUserMenu = (props: any) => (
  <>
    <UserMenu {...props} icon={<MyCustomIcon />}>
      <ConfigurationMenu />
    </UserMenu>
  </>
);

const CustomAppBar = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const msMatches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <AppBar {...props} userMenu={<CustomUserMenu />}>
      {!msMatches && (
        <Typography variant="h6" color="inherit" className={classes.title}>
          TPC Nghi Sơn
        </Typography>
      )}
      <Typography
        variant="h6"
        color="inherit"
        className={classes.appName}
      ></Typography>
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default CustomAppBar;
