import ListActions from "./ListActions";
import React from "react";
import { Edit } from "react-admin";
import { Form } from "./Create";

const MajorEdit = (props: any) => {
  return (
    <Edit undoable={false} component="div" {...props} actions={<ListActions />}>
      <Form isEdit />
    </Edit>
  );
};

export default MajorEdit;
