import React, {cloneElement, FC} from "react";
import {
    Button,
    CreateButton,
    ListButton,
    sanitizeListRestProps,
    TopToolbar,
} from "react-admin";
import {customHttpClient} from "../dataProvider";
import * as FileSaver from "file-saver";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const ListActions: FC<any> = ({
                                  currentSort,
                                  className,
                                  resource,
                                  filters,
                                  displayedFilters,
                                  exporter, // you can hide ExportButton if exporter = (null || false)
                                  filterValues,
                                  permanentFilter,
                                  hasCreate, // you can hide CreateButton if hasCreate = false
                                  basePath,
                                  selectedIds,
                                  onUnselectItems,
                                  showFilter,
                                  maxResults,
                                  total,
                                  hasList,
                                  hasShow,
                                  ...rest
                              }) => {
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {!filters && <ListButton basePath={basePath}/>}
            {filters &&
            cloneElement(filters, {
                undoable: "false",
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: "button",
            })}
            <CreateButton basePath={basePath}/>
            <Button
                color="primary"
                component="button"
                variant="text"
                label="Xuất Quản lý KKTB"
                onClick={() => {
                    customHttpClient(`/api/missingdevices/export-file`, {
                        method: 'POST',
                        body: JSON.stringify(filterValues)
                    }).then(
                        (res) =>
                            FileSaver.saveAs(
                                `${process.env.REACT_APP_API_BASE_URL}${res.body}`,
                                "khiem-khuyet-thiet-bi.xlsx"
                            )
                    );
                }}
            >
                <CloudDownloadIcon style={{fontSize: "20"}}/>
            </Button>
        </TopToolbar>
    )
};

export default ListActions;
