import * as _ from "lodash";
import React, { FC, useState } from "react";
import { TextField } from "react-admin";

const TextFieldMore: FC<any> = (props) => {
  const [showMore, setShowMore] = useState(false);
  const { length = 30 } = props;
  const text = (_.get(props, `record[${props.source}]`, "") || "") + "";
  if (text.length <= length) return <TextField {...props} />;

  return (
    <>
      {!showMore ? (
        <>
          <TextField
            {...props}
            record={{
              ...props.record,
              [props.source]: text.substr(0, length) + "...",
            }}
          />
          <br />
          <span
            className="btn-more"
            style={{
              cursor: "pointer",
              backgroundColor: "#eee",
              fontSize: 13,
              padding: 4,
              borderRadius: 2,
            }}
            onClick={(event) => {
              event.stopPropagation();
              setShowMore((e) => !e);
            }}
          >
            Xem thêm
          </span>
        </>
      ) : (
        <>
          <TextField {...props} />
          <br />
          <span
            style={{
              cursor: "pointer",
              backgroundColor: "#eee",
              fontSize: 13,
              padding: 4,
              borderRadius: 2,
            }}
            className="btn-more"
            onClick={(event) => {
              event.stopPropagation();
              setShowMore((e) => !e);
            }}
          >
            Thu nhỏ
          </span>
        </>
      )}
    </>
  );
};

export default TextFieldMore;
