import React, {cloneElement, FC, useState} from "react";
import {
    Button,
    CreateButton,
    ListButton,
    sanitizeListRestProps,
    TopToolbar,
} from "react-admin";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CLoudUpload from "@material-ui/icons/CloudUpload";
import PopupExportByMonth from "./PopupExportByMonth";
import PopupImportByMonth from "./PopupImportByMonth";
import PopupUploadPDFByMonth from "./PopupUploadPDFByMonth";
import PopupDownloadPDFByMonth from "./PopupDownloadPDFByMonth";
import FileSaver from "file-saver";

const ListActions: FC<any> = ({
                                  currentSort,
                                  className,
                                  resource,
                                  filters,
                                  displayedFilters,
                                  exporter, // you can hide ExportButton if exporter = (null || false)
                                  filterValues,
                                  permanentFilter,
                                  hasCreate, // you can hide CreateButton if hasCreate = false
                                  basePath,
                                  selectedIds,
                                  onUnselectItems,
                                  showFilter,
                                  maxResults,
                                  total,
                                  hasList,
                                  hasShow,
                                  ...rest
                              }) => {
    const [showPopupExport, setShowPopupExport] = useState(false);
    const [showPopupImport, setShowPopupImport] = useState(false);
    const [showPopupUpload, setShowPopupUpload] = useState(false);
    const [showPopupDownloadFile, setShowPopupDownloadFile] = useState(false);
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {!filters && <ListButton basePath={basePath}/>}
            {filters &&
            cloneElement(filters, {
                undoable: "false",
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: "button",
            })}
            <CreateButton basePath={basePath}/>
            <Button
                color="primary"
                component="button"
                variant="text"
                label="Xuất BDTX"
                onClick={() => {
                    setShowPopupExport(true);
                }}
            >
                <CloudDownloadIcon style={{fontSize: "20"}}/>
            </Button>
            <Button
                color="primary"
                component="button"
                variant="text"
                label="Tải mẫu nhập BDTX"
                onClick={() => {
                    let href = `${process.env.REACT_APP_API_BASE_URL}/public/templates/maintainance_template.xlsx`;
                    FileSaver.saveAs(
                        href,
                        "Mau-Bao-Duong-Thuong-Xuyen.xlsx"
                      )
                }}
            >
                <CloudDownloadIcon style={{fontSize: "20"}}/>
            </Button>
            <Button
                color="primary"
                component="button"
                variant="text"
                label="Nhập BDTX"
                onClick={() => {
                    setShowPopupImport(true);
                }}
            >
                <CLoudUpload style={{fontSize: "20"}}/>
            </Button>
            <Button
                color="primary"
                component="button"
                variant="text"
                label="Tải lên file pdf"
                onClick={() => {
                    setShowPopupUpload(true);
                }}
            >
                <CLoudUpload style={{fontSize: "20"}}/>
            </Button>
            <Button
                color="primary"
                component="button"
                variant="text"
                label="Tải về file pdf"
                onClick={() => {
                    setShowPopupDownloadFile(true);
                }}
            >
                <CloudDownloadIcon style={{fontSize: "20"}}/>
            </Button>
            <PopupExportByMonth
                showPopup={showPopupExport}
                setShowPopup={setShowPopupExport}
                filterValues={filterValues}
            />
            <PopupImportByMonth
                showPopup={showPopupImport}
                setShowPopup={setShowPopupImport}
            />
            <PopupUploadPDFByMonth
                showPopup={showPopupUpload}
                setShowPopup={setShowPopupUpload}
            />
            <PopupDownloadPDFByMonth
                showPopup={showPopupDownloadFile}
                setShowPopup={setShowPopupDownloadFile}
            />
        </TopToolbar>
    )
};

export default ListActions;
