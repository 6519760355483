import { Box, Card, CardContent } from "@material-ui/core";
import React from "react";
import {
  Create,
  FormWithRedirect,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";
import ListActions from "./ListActions";
import { useCanAccess } from "../helpers";

const MajorCreate = (props: any) => {
  return (
    <Create {...props} undoable={false} actions={<ListActions />}>
      <Form />
    </Create>
  );
};

export const Form = (props: any) => {
  const { isEdit } = props;
  const canAccessCreate = useCanAccess({
    action: "create",
    resource: "supply",
  });
  const canAccessEdit = useCanAccess({ action: "update", resource: "supply" });
  const canAccessDelete = useCanAccess({
    action: "delete",
    resource: "supply",
  });
  const isShowToolBar =
    (canAccessCreate && !isEdit) || (canAccessEdit && isEdit);
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box
                display={{ md: "block", lg: "flex" }}
                style={{ alignItems: "center" }}
              >
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="system"
                    resource="supplies"
                    fullWidth
                    variant="outlined"
                  />
                </Box>

                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="deviceName"
                    resource="supplies"
                    fullWidth
                    variant="outlined"
                  />
                </Box>

                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="materialCode"
                    resource="supplies"
                    variant="outlined"
                    fullWidth
                  />
                </Box>
              </Box>
              <Box
                display={{ md: "block", lg: "flex" }}
                style={{ alignItems: "center" }}
              >
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="name"
                    resource="supplies"
                    fullWidth
                    variant="outlined"
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="info"
                    resource="supplies"
                    fullWidth
                    variant="outlined"
                  />
                </Box>

                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="code"
                    resource="supplies"
                    fullWidth
                    variant="outlined"
                  />
                </Box>
              </Box>
              <Box
                display={{ md: "block", lg: "flex" }}
                style={{ alignItems: "center" }}
              >
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="madein"
                    resource="supplies"
                    fullWidth
                    variant="outlined"
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput
                    source="count"
                    resource="supplies"
                    fullWidth
                    disabled={!isEdit}
                    variant="outlined"
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="unit"
                    resource="supplies"
                    fullWidth
                    variant="outlined"
                  />
                </Box>
              </Box>
              <Box
                display={{ md: "block", lg: "flex" }}
                style={{ alignItems: "center" }}
              >
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="note"
                    resource="supplies"
                    fullWidth
                    variant="outlined"
                  />
                </Box>
              </Box>
              <Box
                display={{ md: "block", lg: "flex" }}
                style={{ alignItems: "center" }}
              >
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <ReferenceInput
                    source="createdBy"
                    reference="users"
                    resource="supplies"
                    variant="outlined"
                  >
                    <SelectInput
                      optionText="username"
                      disabled
                      fullWidth
                      variant="outlined"
                    />
                  </ReferenceInput>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <ReferenceInput
                    source="updatedBy"
                    reference="users"
                    resource="supplies"
                    variant="outlined"
                  >
                    <SelectInput
                      optionText="username"
                      disabled
                      fullWidth
                      variant="outlined"
                    />
                  </ReferenceInput>
                </Box>
                {/* <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}></Box> */}
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}></Box>
              </Box>
            </CardContent>

            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              // handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="supplies"
              undoable={false}
            >
              {isShowToolBar && (
                <SaveButton
                  saving={formProps.saving}
                  redirect={isEdit ? "edit" : "list"}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
              )}
              {canAccessDelete && <DeleteButton />}
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

export default MajorCreate;
