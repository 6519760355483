import { Theme, useMediaQuery } from "@material-ui/core";
import SubMenu from "./SubMenu";
import SettingsIcon from "@material-ui/icons/Settings";
import WorkTicketIcon from "@material-ui/icons/CardMembershipOutlined";
import React, { FC, useState } from "react";
import { DashboardMenuItem, MenuItemLink, useTranslate } from "react-admin";
import { useSelector } from "react-redux";
import departments from "../departments";
import bills from "../bills";
import supplies from "../supplies";
import { AppState } from "../types";
import users from "../users";
import packages from "../packages";
import worktickets from "../worktickets";
import eworktickets from "../eworktickets";
import hworktickets from "../hworktickets";
import cmdworktickets from "../cmdworktickets";
import { makeStyles } from "@material-ui/core/styles";
import { isKHVT, useCanAccess, useMe, isAdmin } from "../helpers";
import { Dashboard } from "@material-ui/icons";
import loggers from "../loggers";

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const menuItemStyle = makeStyles({
  root: { padding: "0.75em 1em" },
  active: { fontWeight: "bold", backgroundColor: "#0d47a1" }, // Style applied when the menu item is the active one
});

type MenuName = "menuParam" | "workticket" | "bill" | "missingdevices" | "maintenance";

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const translate = useTranslate();
  const [state, setState] = useState<any>({
    menuParam: false,
    workticket: false,
    bill: false,
    missingdevices: false,
    maintenance: false,
  });
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const { loading, me } = useMe();
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  const handleToggle = (menu: MenuName) => {
    setState((state: any) => ({ ...state, [menu]: !state[menu] }));
  };
  const itemStyle = menuItemStyle();

  const canAccessPackage = useCanAccess({
    action: "read",
    resource: "package",
  });
  return (
    <div>
      {/* <MenuItemLink
        classes={itemStyle}
        to={`/`}
        primaryText="Tổng hợp"
        leftIcon={<Dashboard />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      /> */}
      <MenuItemLink
        classes={itemStyle}
        to={`/supplies`}
        primaryText={translate(`resources.supplies.name`, {
          smart_count: 2,
        })}
        leftIcon={<supplies.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />

      <SubMenu
        handleToggle={() => handleToggle("bill")}
        isOpen={state.bill}
        sidebarIsOpen={open}
        name="pos.menu.bill"
        icon={<WorkTicketIcon />}
        dense={dense}
      >
        <MenuItemLink
          classes={itemStyle}
          to={`/bills-board`}
          primaryText="Tổng quan"
          leftIcon={<bills.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          classes={itemStyle}
          to={`/bills`}
          primaryText={translate(`resources.bills.name`, {
            smart_count: 2,
          })}
          leftIcon={<bills.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />

        <MenuItemLink
          classes={itemStyle}
          to={`/billsearchs`}
          primaryText={translate(`resources.supplies.search_name`, {
            smart_count: 2,
          })}
          leftIcon={<bills.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>

        <SubMenu
            handleToggle={() => handleToggle("missingdevices")}
            isOpen={state.missingdevices}
            sidebarIsOpen={open}
            name="Quản lý công tác bảo dưỡng sửa chữa"
            icon={<WorkTicketIcon />}
            dense={dense}
        >
            <MenuItemLink
                classes={itemStyle}
                to={`/missingdevices`}
                primaryText={translate(`resources.missingdevices.name`, {
                  smart_count: 2,
                })}
                leftIcon={<bills.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />

            <MenuItemLink
                classes={itemStyle}
                to={`/maintenance`}
                primaryText={"Bảo dưỡng thường xuyên"}
                leftIcon={<bills.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                classes={itemStyle}
                to={`/incurred`}
                primaryText={"Công việc phát sinh"}
                leftIcon={<bills.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
        </SubMenu>

      {canAccessPackage && (
        <MenuItemLink
          classes={itemStyle}
          to={`/packages`}
          primaryText={translate(`resources.packages.name`, {
            smart_count: 2,
          })}
          leftIcon={<packages.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}

      <SubMenu
        handleToggle={() => handleToggle("workticket")}
        isOpen={state.workticket}
        sidebarIsOpen={open}
        name="pos.menu.workticket"
        icon={<WorkTicketIcon />}
        dense={dense}
      >
        <MenuItemLink
          classes={itemStyle}
          to={`/worktickets-board`}
          primaryText={"Tổng quan"}
          leftIcon={<worktickets.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          classes={itemStyle}
          to={`/worktickets`}
          primaryText={translate(`resources.worktickets.name`, {
            smart_count: 2,
          })}
          leftIcon={<worktickets.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          classes={itemStyle}
          to={`/eworktickets`}
          primaryText={translate(`resources.eworktickets.name`, {
            smart_count: 2,
          })}
          leftIcon={<eworktickets.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          classes={itemStyle}
          to={`/hworktickets`}
          primaryText={translate(`resources.hworktickets.name`, {
            smart_count: 2,
          })}
          leftIcon={<hworktickets.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          classes={itemStyle}
          to={`/cmdworktickets`}
          primaryText={translate(`resources.cmdworktickets.name`, {
            smart_count: 2,
          })}
          leftIcon={<cmdworktickets.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>

      <MenuItemLink
        classes={itemStyle}
        to={`/loggers`}
        primaryText={translate(`resources.loggers.name`, {
          smart_count: 2,
        })}
        leftIcon={<loggers.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />

      {isAdmin(me) && (
        <SubMenu
          handleToggle={() => handleToggle("menuParam")}
          isOpen={state.menuParam}
          sidebarIsOpen={open}
          name="pos.menu.setting"
          icon={<SettingsIcon />}
          dense={dense}
        >
          <MenuItemLink
            classes={itemStyle}
            to={`/users`}
            primaryText={translate(`resources.users.name`, {
              smart_count: 2,
            })}
            leftIcon={<users.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            classes={itemStyle}
            to={`/departments`}
            primaryText={translate(`resources.departments.name`, {
              smart_count: 2,
            })}
            leftIcon={<departments.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        </SubMenu>
      )}

      {isXSmall && logout}
    </div>
  );
};

export default Menu;
