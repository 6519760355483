import slugi from "slugify";
import moment from "moment-timezone";

export enum DepartmentType {
  PHAN_XUONG = "PX",
  KY_THUAT = "KT",
  GIAM_DOC = "GD",
  KE_HOACH = "KH",
}

export enum PCTType {
  DX = "dx",
  KH = "kh",
  NKH = "nkh",
}

export * from "./hooks";
export * from "./useApiFetch";
export * from "./sign";

export const formatTime = (time: string) => {
  return moment.tz(time, "Asia/Ho_Chi_Minh").format("HH:mm DD/MM/YYYY");
};
export const stringToSlug = (str: string = "") => {
  // remove accents
  var from =
      "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
    to =
      "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(RegExp(from[i], "gi"), to[i]);
  }

  return str;
};

export const slugify = (str: string) =>
  slugi(stringToSlug(str), { lower: true, replacement: "_" });

export const isLeaderPX = (me: any) => {
  if (!me || !me.position || !me.position.department) return false;

  if (
    me.position.isLeader &&
    me.position.department.type === DepartmentType.PHAN_XUONG
  ) {
    return true;
  }
  return false;
};

export const isAdmin = (me: any) => {
  return !me || (!!me && me.role === "Admin");
};
export const isStaffPX = (me: any) => {
  if (!me || !me.position || !me.position.department) return false;

  if (
    !me.position.isLeader &&
    me.position.department.type === DepartmentType.PHAN_XUONG
  ) {
    return true;
  }
  return false;
};

export const isLeaderKT = (me: any) => {
  if (!me || !me.position || !me.position.department) return false;

  if (
    me.position.isLeader &&
    me.position.department.type === DepartmentType.KY_THUAT
  ) {
    return true;
  }
  return false;
};

export const isStaffKT = (me: any) => {
  if (!me || !me.position || !me.position.department) return false;

  if (
    !me.position.isLeader &&
    me.position.department.type === DepartmentType.KY_THUAT
  ) {
    return true;
  }
  return false;
};

export const isKHVT = (me: any) => {
  if (!me || !me.position || !me.position.department) return false;

  if (me.position.department.type === DepartmentType.KE_HOACH) {
    return true;
  }
  return false;
};

export const isPX = (me: any) => {
  return isStaffPX(me) || isLeaderPX(me);
};

export const isKT = (me: any) => {
  return isStaffKT(me) || isLeaderKT(me);
};
