import { CircularProgress } from "@material-ui/core";
import polyglotI18nProvider from "ra-i18n-polyglot";
import React, { Suspense } from "react";
import { Admin, Resource, CustomRoutes } from "react-admin";
import "./App.scss";
import authProvider from "./authProvider";
import bills from "./bills";
import cmdworktickets from "./cmdworktickets";
import { ErrorBoundary } from "./components/ErrorBoundary";
import dataProvider from "./dataProvider";
import departments from "./departments";
import missingdevices from "./missingdevices";
import maintenance from "./maintenance";
import incurred from "./incurred";
import eworktickets from "./eworktickets";
import hworktickets from "./hworktickets";
import viMessages from "./i18n/vi";
import items from "./items";
import { Layout, Login } from "./layout";
import loggers from "./loggers";
import packages from "./packages";
import positions from "./positions";
import customRoutes from "./routes";
import statusbills from "./statusbills";
import supplies from "./supplies";
import themeReducer from "./themeReducer";
import users from "./users";
import worktickets from "./worktickets";

const i18nProvider = polyglotI18nProvider((locale) => {
  return viMessages;
}, "vi");

const App = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={() => <CircularProgress />}>
        <Admin
          dataProvider={dataProvider}
          customReducers={{ theme: themeReducer }}
          customRoutes={customRoutes}
          authProvider={authProvider}
          // dashboard={() => <Dashboard />}
          loginPage={Login}
          layout={Layout}
          i18nProvider={i18nProvider}
        >
          <Resource {...supplies} />
          <Resource {...users} />
          <Resource {...positions} />
          <Resource {...departments} />
          <Resource {...missingdevices} />
          <Resource {...maintenance} />
          <Resource {...incurred} />
          <Resource {...items} />
          <Resource {...bills} />
          <Resource {...packages} />
          <Resource {...statusbills} />
          <Resource {...worktickets} />
          <Resource {...eworktickets} />
          <Resource {...cmdworktickets} />
          <Resource {...hworktickets} />
          <Resource {...loggers} />
          <Resource name="billlogs" />
          <Resource name="draftsupplies" />
          <Resource name="comments" />
          <Resource name="supplyrelativebills/supplies" />
          <Resource name="supplyrelativebills/targets" />
          <Resource name="supplyrelativebills" />
          <Resource name="uceworktickets" />
          <Resource name="aeworktickets" />
          <Resource name="uchworktickets" />
          <Resource name="ahworktickets" />
        </Admin>
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
