import React, {FC} from "react";
import {FieldProps} from "../types";
import {useTranslate} from "react-admin";
import {Checkbox, FormControlLabel, withStyles} from "@material-ui/core";
import {useInput} from "react-admin";
import {orange, red} from "@material-ui/core/colors";

interface Props extends FieldProps<any> {
    className?: string;
    fullWidth?: any;
    resource?: string;
    source: string;
    type: string;
    disabled?: boolean;
    alwaysOn?: boolean;
    onChange?: any
}
const RedCheckbox = withStyles({
    root: {
        color: red[900],
        '&$checked': {
            color: red[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const OrangeCheckbox = withStyles({
    root: {
        color: orange[900],
        '&$checked': {
            color: orange[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CheckboxInput: FC<any> = (props: any) => {
    const { color, record, className, source, label, disabled, onChange, ...rest} = props;

    const translate = useTranslate();
    const {input, isRequired} = useInput(props);
    let component = <Checkbox color="primary" {...input} />;
    if(color && color === 'red'){
        component = <RedCheckbox {...input} />
    }else if(color && color === 'orange'){
        component = <OrangeCheckbox {...input} />
    }

    if (onChange) {
        return (
            <FormControlLabel
                control={component}
                label={translate(label!)}
                style={{marginTop: "6px"}}
                disabled={disabled}
                onChange={onChange}
            />
        );
    } else {
        return (
            <FormControlLabel
                control={component}
                label={translate(label!)}
                style={{marginTop: "6px"}}
                disabled={disabled}
            />
        );
    }

};

export default CheckboxInput;
