import {
    Button, Input, Select, TextField,MenuItem
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, {ChangeEvent, useEffect, useState} from "react";
import {customHttpClient} from "../dataProvider";
import FileSaver from "file-saver";

export default function PopupExportByMonth(props: any) {
    const {showPopup, setShowPopup, filterValues} = props;
    const [month, setMonth] = useState('');
    const handleChange = (e: any) => {
        setMonth(e.target.value)
    };
    const handleExport = (event: any) => {
        customHttpClient(`/api/maintenance/export-file?month=${month}`, {
                method: 'POST',
                body: JSON.stringify(filterValues)
            }).then(
            (res) => {
                FileSaver.saveAs(
                    `${process.env.REACT_APP_API_BASE_URL}${res.body}`,
                    "baoduongthuongxuyen.xlsx"
                )
                setShowPopup(false)
            }
        );
    }

    return (
        <Dialog
            open={showPopup}
            onClose={() => setShowPopup(false)}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Nhập vào tháng để xuất</DialogTitle>
            <DialogContent>
                <p>Chọn tháng để xuất file</p>
                <Select
                    style={{width: "100%"}}
                    value={month}
                    label="Tháng"
                    onChange={handleChange}
                >
                    <MenuItem value={1}>Tháng 1</MenuItem>
                    <MenuItem value={2}>Tháng 2</MenuItem>
                    <MenuItem value={3}>Tháng 3</MenuItem>
                    <MenuItem value={4}>Tháng 4</MenuItem>
                    <MenuItem value={5}>Tháng 5</MenuItem>
                    <MenuItem value={6}>Tháng 6</MenuItem>
                    <MenuItem value={7}>Tháng 7</MenuItem>
                    <MenuItem value={8}>Tháng 8</MenuItem>
                    <MenuItem value={9}>Tháng 9</MenuItem>
                    <MenuItem value={10}>Tháng 10</MenuItem>
                    <MenuItem value={11}>Tháng 11</MenuItem>
                    <MenuItem value={12}>Tháng 12</MenuItem>
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowPopup(false)} color="primary">
                    Hủy bỏ
                </Button>
                <Button
                    color="primary"
                    onClick={handleExport}
                >
                    Xuất file
                </Button>
            </DialogActions>
        </Dialog>
    );
}