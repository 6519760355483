import { Checkbox, Chip, CircularProgress } from "@material-ui/core";
import * as _ from "lodash";
import React, { FC } from "react";
import { Datagrid, List, TextField, useTranslate } from "react-admin";
import { Link } from "react-router-dom";
import Pagination from "../components/Pagination";
import TextFieldMore from "../components/TextFieldMore";
import { slugify, useMe } from "../helpers";
import {
  BuyingStatusType,
  BuyingStatusTypeColor,
} from "../packages/TableSupplyChoosen";
import "./component.scss";
import ListFilterSearch from "./ListFilterSearch";

const PackageSuppliesField = ({ record, source }: any) => {
  if (_.isEmpty(record) || _.isEmpty(record[source])){
    return (
      <Chip
        label={BuyingStatusType.NOT_BUYING}
        color="primary"
        size="small"
        style={{
          backgroundColor:
            BuyingStatusTypeColor[slugify(BuyingStatusType.NOT_BUYING) as any],
        }}
      />
    );
  }
  return (
    <>
      {record[source].map((item: any) => (
        <Link to={`/packages/${item.id}`}>
          <Chip
            key={item.id}
            label={item.status}
            color="primary"
            size="small"
            style={{
              backgroundColor: BuyingStatusTypeColor[slugify(item.status) as any],
              cursor: "pointer"
            }}
          />
        </Link>
      ))}
    </>
  );
};

const MaterialCodeField = ({ record, source, linksource }: any) => {
  if(record[linksource] && record[linksource].length){
    return <Link to={`/packages/${record[linksource][0].id}`}>
      <TextField source={source} record={record} style={{
        cursor: "pointer"
      }}/>
    </Link>
  }else{
    return <TextField source={source} record={record}/>
  }
};

const BillField: FC<any> = (props) => {
  const { record, source, type } = props;
  const value = _.get(record, `${source}`, undefined);
  if (_.isEmpty(value)) return <></>;
  return (
    <Link to={`/bills/${value.id}`}>
      <Chip
        label={value.billNumber}
        color="primary"
        size="small"
        style={{ cursor: "pointer" }}
      />
    </Link>
  );
};

const CheckBoxField: FC<any> = (props) => {
  const { record, source, type } = props;
  const value = _.get(record, `${source}`, undefined);

  return <Checkbox checked={value} />;
};

const SearchSupplies: FC<any> = (props) => {
  const translate = useTranslate();
  const { loading, me } = useMe();
  const { limit = 10 } = props;
  if (loading) return <CircularProgress />;

  return (
    <List
      {...props}
      id="supplyrelativebills"
      basePath="/supplyrelativebills"
      resource="supplyrelativebills"
      empty={false}
      bulkActionButtons={false}
      title={
        props.doNotShowAction
          ? ""
          : translate("resources.supplies.page.list_search")
      }
      actions={false}
      filters={props.doNotShowAction ? null : <ListFilterSearch />}
      filter={
        props.doNotShowAction
          ? {
              isUrgen: 1,
            }
          : {}
      }
      sort={{ field: "createdAt", order: "DESC" }}
      perPage={limit}
      pagination={<Pagination />}
    >
      <Datagrid optimized>
        <TextField source="name" />
        <MaterialCodeField source="materialCode" linksource="packageSupplies" />
        <TextField source="code" label="Mã hiệu" />
        <TextFieldMore source="info" label="Thông số kỹ thuật" length={100} />
        <CheckBoxField source="isUrgen" label="Cần gấp" />
        <BillField source="pxBill" />
        <BillField source="ktBill" />
        <PackageSuppliesField source="packageSupplies" />
      </Datagrid>
    </List>
  );
};

export default SearchSupplies;
