import * as _ from "lodash";
import {useHistory} from "react-router";
import {Button, useRefresh} from "react-admin";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import {useMutateDeleteCRUD} from "../hooks/useMutate";
import {useNotify} from "ra-core";
import {DepartmentAlias, MissingDeviceStatus} from "./Types";
import {useMe} from "../helpers";

const EditButton = (props: any) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const {me} = useMe();
    const id = _.get(props, "record.id", "");
    const status = _.get(props, "record.status", MissingDeviceStatus.OPENING);
    const history = useHistory();
    const [{}, deleteCmd] = useMutateDeleteCRUD("missingdevices", [], (err) => {
        notify('Xóa thành công');
        refresh()
    });
    let allowDelete = false;
    if (me && me.position && me.position.department) {
        allowDelete = [DepartmentAlias.px1, DepartmentAlias.px3, DepartmentAlias.px5].includes(me.position.department.alias);
    }
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
            }}
        >
            <Button
                component="button"
                variant="contained"
                label="Sửa"
                onClick={() => {
                    history.push(`/missingdevices/${id}`);
                }}
            >
                <EditIcon style={{fontSize: "20"}}/>
            </Button>
            {status === MissingDeviceStatus.OPENING && (
                <Button
                    label="Xóa"
                    style={{color: "red"}}
                    onClick={() =>
                        deleteCmd({id})
                    }
                >
                    <DeleteOutline style={{color: "red"}}/>
                </Button>
            )}
        </div>
    );
};

export default EditButton
