import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { Edit as EditAdmin } from "react-admin";
import { useHistory } from "react-router";
import { Form } from "./Create";
import ListActions from "./ListActions";

const MajorEdit = (props: any) => {
  const history = useHistory();

  useEffect(() => {
    history.push(`/bills`);
  }, [history]);
  return (
    <Box display={{ md: "block", lg: "flex" }}>
      <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
        <EditAdmin
          component="div"
          {...props}
          undoable={false}
          actions={<ListActions />}>
          <Form />
        </EditAdmin>
      </Box>
    </Box>
  );
};

export default MajorEdit;
