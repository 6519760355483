import { HistoryOutlined } from "@material-ui/icons";
import ReviewIcon from "@material-ui/icons/PostAddOutlined";
import React from "react";
import "./component.scss";

export default {
  icon: HistoryOutlined,
  list: React.lazy(() => import("./List")),
  name: "loggers",
};
