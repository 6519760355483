import * as _ from "lodash";
import MaterialTable, { Column } from "material-table";
import React, { memo } from "react";
import { useNotify, useTranslate } from "react-admin";
import { useField } from "react-final-form";
import { BillStatusType } from "../dataProvider";
import { isStatusBillType1, BillType } from "./Create";
import { components, options, tableIcons, showAddAction } from "./TableSupply";
import { useRouteMatch } from "react-router";
import { TextField } from "@material-ui/core";

const colVTTH1 = (translate: any) => [
  {
    title: translate("resources.supplies.fields.materialCode"),
    field: "materialCode",
    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: translate("resources.supplies.fields.name"),
    field: "name",
    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: translate("resources.supplies.fields.info"),
    field: "info",
    width: 300,
    cellStyle: { maxWidth: 300, overflowWrap: "break-word" },
  },
  {
    title: translate("resources.supplies.fields.madein"),
    field: "madein",
    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: translate("resources.supplies.fields.unit"),
    field: "unit",
    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },

  {
    title: translate("resources.supplies.fields.countOfYear"),
    field: "countOfYear",
    type: "numeric",
    width: 50,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },

  {
    title: translate("resources.supplies.fields.countOfUsed"),
    field: "countOfUsed",
    type: "numeric",
    width: 50,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: translate("resources.supplies.fields.countOfMonth"),
    field: "countOfMonth",
    type: "numeric",
    width: 50,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: translate("resources.supplies.fields.count"),
    field: "countOfMonth",
    type: "numeric",
    width: 50,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },

  {
    title: translate("resources.supplies.fields.note"),
    field: "note",
  },
];

export const colVTTH2 = (translate: any) => [
  {
    title: translate("resources.supplies.fields.materialCode"),
    field: "materialCode",
    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: translate("resources.supplies.fields.name"),
    field: "name",
    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: translate("resources.supplies.fields.info"),
    field: "info",
    width: 300,
    cellStyle: { maxWidth: 300, overflowWrap: "break-word" },
  },
  {
    title: translate("resources.supplies.fields.madein"),
    field: "madein",
    width: 100,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
  {
    title: translate("resources.supplies.fields.unit"),
    field: "unit",
    width: 50,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },

  {
    title: translate("resources.supplies.fields.count"),
    field: "countOfMonth",
    type: "numeric",
    width: 50,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },

  {
    title: translate("resources.supplies.fields.note"),
    field: "note",
    width: 50,
    cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
  },
];

export const colSCDX = (translate: any) => [
  {
    title: translate("resources.supplies.fields.materialCode2"),
    field: "materialCode",
    editComponent: (row: any) => {
      return (
        <TextField
          placeholder={row.columnDef.title}
          multiline
          rows={4}
          style={{ display: "flex" }}
          InputProps={{ onChange: (e) => row.onChange(e.target.value) }}
        />
      );
    },
  },
  {
    title: translate("resources.supplies.fields.status"),
    field: "name",
    editComponent: (row: any) => {
      return (
        <TextField
          placeholder={row.columnDef.title}
          multiline
          rows={4}
          style={{ display: "flex" }}
          InputProps={{ onChange: (e) => row.onChange(e.target.value) }}
        />
      );
    },
  },
  {
    title: translate("resources.supplies.fields.image"),
    field: "info",
    editComponent: (row: any) => {
      return (
        <TextField
          placeholder={row.columnDef.title}
          multiline
          rows={4}
          style={{ display: "flex" }}
          InputProps={{ onChange: (e) => row.onChange(e.target.value) }}
        />
      );
    },
  },
  {
    title: translate("resources.supplies.fields.note"),
    field: "note",
    editComponent: (row: any) => {
      return (
        <TextField
          placeholder={row.columnDef.title}
          multiline
          rows={4}
          style={{ display: "flex" }}
          InputProps={{ onChange: (e) => row.onChange(e.target.value) }}
        />
      );
    },
  },
];

export const getCols = (
  translate: any,
  statusType: BillStatusType,
  billType: BillType
) => {
  let cols: any = [];

  if (billType === BillType.VAT_TU_TIEU_HAO) {
    if (isStatusBillType1(statusType)) {
      cols = colVTTH1(translate);
    } else {
      cols = colVTTH2(translate);
    }
  } else {
    cols = colSCDX(translate);
  }

  return cols.map((i: any) => ({
    ...i,
    headerStyle: {
      ...(i.headerStyle || {}),
      padding: "4px",
    },
    cellStyle: {
      ...(i.cellStyle || {}),
      padding: "4px",
      borderWidth: 1,
      borderColor: "black",
      borderStyle: "solid",
    },
  })) as Column<any>[];
};
export const cols = () => [];

const resolveId = (billType: string) => {
  if (billType === BillType.VAT_TU_TIEU_HAO) {
    // if (isStatusBillType1(statusType)) {
    //   cols = colVTTH1(translate);
    // } else {
    //   cols = colVTTH2(translate);
    // }
    return "vtth";
  } else {
    return "scdx";
  }
};

const TableItem = memo((props: any) => {
  const inputProps = useField(props.source);
  const match = useRouteMatch<{ id: string }>();
  const billId = match.params.id;
  const translate = useTranslate();
  const notify = useNotify();
  const { isClone, statusType, type: billType } = props;
  const isShowAdd = showAddAction(isClone, statusType);

  return (
    <div id={`table-item-${resolveId(billType)}`}>
      <MaterialTable
        icons={tableIcons}
        title={translate("resources.bills.zones.pl1")}
        options={options(isClone)}
        columns={getCols(translate, statusType, billType) as any}
        data={[
          ..._.reject(inputProps.input.value, (val) => val.isActive === false),
        ]}
        components={components(billId, "items", isShowAdd, inputProps, undefined,undefined,undefined,undefined,undefined, true)}
        localization={{
          pagination: { labelRowsSelect: "bản ghi" },
          body: {
            emptyDataSourceMessage: "Không có bản ghi nào",
            editRow: { deleteText: "Xóa bản ghi?" },
          },
          header: { actions: "Chỉnh sửa" },
        }}
        onSelectionChange={(rows, rowData) => {
          // if (_.isEmpty(rows)) return;
          const newData = inputProps.input.value.map((input: any) => {
            // const update = _.find(
            //   rows,
            //   (row) =>
            //     row.materialCode === input.materialCode &&
            //     row.billId === input.billId
            // );
            return {
              ...input,
              checked:
                input.materialCode === rowData.materialCode
                  ? !input.checked
                  : input.checked,
            };
          });
          inputProps.input.onChange(newData);
        }}
        editable={
          !isShowAdd
            ? { onRowAdd: () => new Promise((resolve) => {}) }
            : {
                onRowAdd: (newData: any) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      if (!newData.materialCode) {
                        notify("pos.action.fillMissing");
                        return reject();
                      }
                      inputProps.input.onChange([
                        ...inputProps.input.value,
                        newData,
                      ]);

                      resolve(null);
                    }, 0);
                  }),
                onRowUpdate: (newData: any, oldData: any) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      if (!newData.materialCode) {
                        notify("pos.action.fillMissing");
                        return reject();
                      }
                      const dataUpdate = [...inputProps.input.value];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      inputProps.input.onChange([...dataUpdate]);

                      resolve(null);
                    }, 0);
                  }),
                onRowDelete: (oldData: any) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataDelete = [...inputProps.input.value];
                      const index = oldData.tableData.id;
                      dataDelete.splice(index, 1);
                      inputProps.input.onChange(dataDelete);

                      resolve(null);
                    }, 100);
                  }),
              }
        }
      />
    </div>
  );
});

export default TableItem;
