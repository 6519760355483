import { Chip } from "@material-ui/core";
import React, { FC } from "react";
import {
  Filter,
  SelectInput,
  TextInput,
  useInput,
  useTranslate,
} from "react-admin";
import { DepartmentType } from "../helpers";
import { BillType } from "./Create";
import { BillGroupType } from "./Edit";

const TagInput: FC<any> = (props) => {
  const { record, className, source, label, disabled, choices, ...rest } =
    props;

  const translate = useTranslate();
  const { input, isRequired } = useInput(props);

  const items = [{ name: "Tất cả", id: "" }, ...choices].map(
    (choice: { name: string; id: any }) => (
      <Chip
        label={choice.name}
        clickable
        color={input.value === choice.id ? "primary" : "default"}
        onClick={() => input.onChange({ target: { value: choice.id } })}
      />
    )
  );

  return (
    <>
      <div className="filter_label">{label}</div>
      <div>{items}</div>
    </>
  );
};
const ListFilter: FC<any> = (props) => {
  const translate = useTranslate();
  return (
    <fieldset className="filter-fieldset">
      <legend>Tìm kiếm</legend>
      <Filter {...props}>
        <TextInput alwaysOn source="billNumber" variant="outlined" />
        <TextInput alwaysOn source="billName" variant="outlined" />

        <SelectInput
          alwaysOn
          source="type"
          label="Loại phiếu"
          variant="outlined"
          resource="bills"
          choices={Object.keys(BillType).map((type) => ({
            id: type,
            name: translate(`resources.bills.fields.${type}`),
          }))}
        />

        <SelectInput
          alwaysOn
          source="departmentCreatedType"
          variant="outlined"
          resource="bills"
          label="Phòng ban tạo"
          choices={[
            {
              id: DepartmentType.PHAN_XUONG,
              name: translate(`resources.departments.fields.PHAN_XUONG`),
            },
            {
              id: DepartmentType.KY_THUAT,
              name: translate(`resources.departments.fields.KY_THUAT`),
            },{
              id: DepartmentType.GIAM_DOC,
              name: "Giám đốc",
            },{
              id: DepartmentType.KE_HOACH,
              name: "Kế hoạch"
            },
          ]}
        />
      </Filter>
    </fieldset>
  );
};

export default ListFilter;
