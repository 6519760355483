import ListActions from "./ListActions";
import React from "react";
import {
  Edit as EditAdmin,
  TopToolbar,
  Show,
  ReferenceManyField,
  TextField,
  EditButton,
  Datagrid,
  BooleanField,
} from "react-admin";
import { Form } from "./Create";
import { Button, Box } from "@material-ui/core";
import { Link } from "react-router-dom";

const ShowActions = ({ id }: any) => (
  <TopToolbar>
    <div
      className="MuiButton-textPrimary"
      style={{
        position: "absolute",
        left: 10,
        bottom: "24px",
        fontSize: "1rem",
      }}>
      Các vị trí thuộc phòng ban
    </div>
    <Button
      className="MuiButton-textPrimary MuiButton-textSizeSmall"
      component={Link}
      to={{
        pathname: "/positions/create",
        state: { record: { departmentId: parseInt(id) } },
      }}>
      + Create
    </Button>
  </TopToolbar>
);

const PositionShow = (props: any) => {
  const { basePath, resource, id } = props;
  return (
    <Show
      title={" "}
      basePath={basePath}
      resource={resource}
      id={id}
      actions={<ShowActions {...props} />}>
      <ReferenceManyField
        label="positions"
        reference="positions"
        target="departmentId">
        <Datagrid>
          <TextField source="name" />
          <BooleanField source="isLeader" />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </Show>
  );
};

const MajorEdit = (props: any) => {
  return (
    <Box display={{ md: "block", lg: "flex" }}>
      <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
        <EditAdmin
          undoable={false}
          component="div"
          {...props}
          actions={<ListActions />}>
          <Form isEdit />
        </EditAdmin>
      </Box>
      <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
        <PositionShow {...props} />
      </Box>
    </Box>
  );
};

export default MajorEdit;
