import { Box } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import UndoIcon from "@material-ui/icons/Undo";
import * as _ from "lodash";
import React, { useState } from "react";
import {
  AutocompleteInput,
  Button,
  Create,
  FileField,
  FileInput,
  FormWithRedirect,
  ReferenceInput,
  required,
  Toolbar,
} from "react-admin";
import PopupRejectDraftSupply from "./PopupRejectDraftSupply";
import { useCanAccess } from "../helpers";
import { useDataProvider } from "react-admin";
import { useNotify } from "react-admin";
import { useRefresh } from "react-admin";
import PreviewInputFile from "../components/PreviewInputFile";

const Approval = (props: any) => {
  const { isShowApproval, showApproval, ...rest } = props;

  return (
    <Create
      resource="statusbills"
      {...rest}
      title={" "}
      basePath="/statusbills"
      actions={null}
    >
      <Form />
    </Create>
  );
};

export const Form = (props: any) => {
  const [isShowReject, showReject] = useState(false);
  const canAccessCreate = useCanAccess({
    action: "create",
    resource: "approval-bill",
  });

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  return (
    <FormWithRedirect
      {...props}
      resource="statusbills"
      render={(formProps: any) => {
        return (
          <>
            <PopupRejectDraftSupply
              isShowReject={isShowReject}
              showReject={showReject}
              billId={formProps.record.billId}
            />
            <Box
              display={{ md: "block", lg: "flex" }}
              style={{ alignItems: "center" }}
            >
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <ReferenceInput
                  source="approvalDepartmentId"
                  reference="departments"
                  resource="bills"
                  filterToQuery={(searchText: any) => {
                    return _.isEmpty(_.trim(searchText))
                      ? null
                      : { name: searchText };
                  }}
                >
                  <AutocompleteInput
                    resettable
                    optionText="name"
                    fullWidth
                    validate={requiredValidate}
                  />
                </ReferenceInput>
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <FileInput
                  source="attachment"
                  resource="bills"
                  accept="application/pdf"
                  placeholder={<p>Kéo thả đính kèm vào đây</p>}
                >
                  <FileField source="src" title="title" />
                </FileInput>
              </Box>
            </Box>

            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              // handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="statusbills"
              // @ts-ignore
              style={{ justifyContent: "space-between" }}
              undoable={false}
            >
              <Button
                color="primary"
                component="span"
                variant="contained"
                label="pos.action.approval"
                onClick={() => {
                  dataProvider
                    .create("statusbills", {
                      data: { ...formProps.form.getState().values },
                    })
                    .then(() => {
                      notify("Duyệt thành công", "info");
                      refresh();
                    })
                    .catch((err: any) => {
                      notify(err.message, "warning");
                      refresh();
                    });
                }}
              >
                <DoneIcon style={{ fontSize: "20" }} />
              </Button>
              <Button
                style={{ color: "red" }}
                component="button"
                variant="text"
                label="pos.action.reject"
                onClick={() => {
                  showReject(true);
                }}
              >
                <UndoIcon style={{ fontSize: "20", color: "red" }} />
              </Button>
            </Toolbar>
          </>
        );
      }}
    />
  );
};

const requiredValidate = [required()];

export default Approval;
