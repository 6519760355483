import ListActions from "./ListActions";
import React from "react";
import { Edit } from "react-admin";
import { Form } from "./Create";
import { isAdmin, useCanAccess, useMe } from "../helpers";

const MajorEdit = (props: any) => {
  const showPermission = useCanAccess({
    action: "update",
    resource: "permission",
  });

  const { loading, me } = useMe();

  return (
    <Edit
      undoable={false}
      component="div"
      {...props}
      actions={
        isAdmin(me) ? <ListActions showPermission={showPermission} /> : false
      }
    >
      <Form isEdit />
    </Edit>
  );
};

export default MajorEdit;
