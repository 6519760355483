import React from "react";
import {
  Datagrid,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  TopToolbar,
  useTranslate,
} from "react-admin";
import ChipField from "../components/ChipField";
import DateTimeField from "../components/DateTimeField";
import { BillStatusChangeField, CustomAttachment } from "./component";

const ShowHistorys = ({ id }: any) => {
  const translate = useTranslate();
  return (
    <TopToolbar className="top-toolbar">
      <div
        className="MuiButton-textPrimary RaButton-label-37 MuiButton-textSizeSmall MuiButton-root"
        style={{
          position: "absolute",
          left: 0,
          bottom: "20px",
        }}
      >
        {translate("resources.bills.zones.historyList")}
      </div>
    </TopToolbar>
  );
};

const ShowHistoryApproval = (props: any) => {
  const { basePath, id } = props;
  const translate = useTranslate();
  return (
    <Show
      title={" "}
      basePath={basePath}
      id={id}
      resource="bills"
      actions={<ShowHistorys {...props} />}
    >
      <ReferenceManyField
        pagination={<Pagination />}
        label="statusbills"
        reference="statusbills"
        perPage={5}
        sort={{ field: "createdAt", order: "DESC" }}
        target="billId"
      >
        <Datagrid>
          <DateTimeField
            {...props}
            isChip={false}
            // showOnlyDate={true}
            source="createdAt"
            resource="bills"
            label={translate("resources.bills.fields.createdAt")}
          />
          <BillStatusChangeField
            source="status"
            resource="bills"
            label={translate("resources.bills.fields.status")}
          />
          <ReferenceField
            source="createdBy"
            reference="users"
            resource="bills"
            label={translate("resources.bills.fields.createdBy")}
          >
            <ChipField source="username" />
          </ReferenceField>
          <CustomAttachment
            source="attachment"
            label={translate("resources.bills.fields.attachment")}
          />
        </Datagrid>
      </ReferenceManyField>
    </Show>
  );
};

export default ShowHistoryApproval;
