import * as _ from "lodash";
import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Edit as EditAdmin, useTranslate } from "react-admin";
import { customHttpClient, BillStatusType } from "../dataProvider";
import Approval from "./Approval";
import { Form } from "./Create";
import ListActions from "./ListActions";
import PopupCreateDraftSupply from "./PopupCreateDraftSupply";
import ShowHistoryApproval from "./ShowHistoryApproval";
import {useCanAccess, useMe} from "../helpers";
import { ListingInEdit } from "./ListingInEdit";

export enum BillStatusLogType {
  CHANGE_STATUS = 1,
  CHANGE_PROPERTY = 2,
  CREATE = 3,
}

export enum BillGroupType {
  PARENT = 3,
  CHILD = 2,
  DEFAULT = 1,
  DEFAULT_KT = 4,
}

const WrapperForm = (props: any) => {
  const { record } = props;

  const isGroup = !!record && record.groupType === BillGroupType.PARENT;
  return <Form {...props} isEdit isGroup={isGroup} />;
};

const MajorEdit = (props: any) => {
  const billId = props.match.params.id;
  const translate = useTranslate();
  const [billState, setBillState] = useState({} as any);
  const [record, setRecord] = useState<any>(undefined);
  useEffect(() => {
    customHttpClient("/api/now-status-bills?billId=" + billId)
      .then((res) => res.json.data)
      .then((res) => setBillState(_.isEmpty(res) ? {} : res));
  }, [billId]);

  useEffect(() => {
    customHttpClient("/crud/bills/" + billId)
        .then((res) => {
          return res.json
        })
        .then((res) => setRecord(res));
  }, [billId]);
  const { me } = useMe();
  const [isShowCreate, showCreate] = useState(false);
  const [isShowApproval, showApproval] = useState(false);
  const [isEditPopup] = useState(false);
  const canApproval = record && me && me.id ? me.id === record.createdBy  : false
  return (
    <div className="wrapper-edit">
      <div className="wrapper-edit__left">
        <ListingInEdit />
      </div>
      <div className="wrapper-edit__right">
        <Box display={{ md: "block", lg: "flex" }}>
          <Box flex={3} ml={{ xs: 0, sm: "0.5em" }}>
            <EditAdmin
              component="div"
              {...props}
              undoable={false}
              actions={<ListActions {...props} showExport id={billId} />}
            >
              <WrapperForm />
            </EditAdmin>

            {/* <ListDraftSupply
            id={billId}
            showCreate={showCreate}
            setEditPopup={setEditPopup}
          /> */}
          </Box>
        </Box>
        <Box display={{ md: "block", lg: "flex" }}>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <ShowHistoryApproval {...props} />
          </Box>
          {billState && canApproval && (
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }} mt={{ xs: "1em" }}>
              <div className="MuiButton-textPrimary MuiButton-textPrimary RaButton-label-37 MuiButton-textSizeSmall MuiButton-root">
                {translate("resources.bills.zones.approval")}
              </div>
              <Approval
                // {...props}
                record={{ billId, status: billState.status }}
                isShowApproval={isShowApproval}
                showApproval={showApproval}
              />
            </Box>
          )}
        </Box>
      </div>

      <PopupCreateDraftSupply
        isShowCreate={isShowCreate}
        showCreate={showCreate}
        billId={billId}
        isEditPopup={isEditPopup}
        {...props}
      />
    </div>
  );
};

export default MajorEdit;
