import React, { cloneElement, FC } from "react";
import CloudDownload from "@material-ui/icons/CloudDownloadOutlined";
import {
  CreateButton,
  ListButton,
  sanitizeListRestProps,
  TopToolbar,
  Button,
  useTranslate,
} from "react-admin";
import { ImportButton } from "ra-import-csv";
import { slugify, useCanAccess } from "../helpers";
import * as FileSaver from "file-saver";
import {customHttpClient} from "../dataProvider";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const HEADER_MAPPER: any = {
  vat_tu: "name",
  ma_vat_tu: "materialCode",
  ma_hieu: "code",
  don_vi: "unit",
  don_vi_tinh: "unit",
  so_luong: "count",
  so_luong_ton_kho: "count",
  hang_san_xuat_xuat_xu: "madein",
  thong_so_ky_thuat: "info",
  he_thong: "system",
  ghi_chu: "note",
  thiet_bi: "deviceName",
};

const configImportSupply = {
  parseConfig: {
    header: true,
    transformHeader: (h: string) => {
      const slug = slugify(h);

      return HEADER_MAPPER[slug] || slug;
    },
  },
};

const configImportExistsSupply = {
  parseConfig: {
    header: true,
    transformHeader: (h: string) => {
      const slug = slugify(h);

      return HEADER_MAPPER[slug] || slug;
    },
  },
};
const ListActions: FC<any> = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  hasList,
  hasShow,
  ...rest
}) => {
  const canAccessCreate = useCanAccess({
    action: "create",
    resource: "supply",
  });

  const canImportSupply = useCanAccess({
    action: "create",
    resource: "import-supplies",
  });

  const canImportExistSupply = useCanAccess({
    action: "create",
    resource: "import-supplies-exists",
  });
  const translate = useTranslate();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {!filters && <ListButton basePath={basePath} />}

      <Button
        color="primary"
        component="button"
        variant="text"
        label="pos.action.downloadTemplate"
        onClick={() => {
          FileSaver.saveAs(
            `${process.env.REACT_APP_API_BASE_URL}/api/supplies/downloadexport/Vật-tư.xlsx`,
            "vattu.xlsx"
          );
        }}
      >
        <CloudDownload style={{ fontSize: "20" }} />
      </Button>
      <Button
        color="primary"
        component="button"
        variant="text"
        label="pos.action.downloadTemplateExistSupply"
        onClick={() => {
          FileSaver.saveAs(
            `${process.env.REACT_APP_API_BASE_URL}/api/existsupplies/downloadexport/Tồn-kho.xlsx`,
            "tonkho.xlsx"
          );
        }}
      >
        <CloudDownload style={{ fontSize: "20" }} />
      </Button>
      {canAccessCreate && (
        <>
          <CreateButton basePath={basePath} />
          {canImportSupply && (
            <ImportButton
              resource={resource}
              {...rest}
              {...configImportSupply}
              label={translate("pos.action.importSupply")}
              showOverWrite={false}
              handleValues={(data: any[], onComplete: any)=>{
                console.log(data);
                console.log(onComplete)
              }}
            />
          )}
          {canImportExistSupply && (
            <ImportButton
              showOverWrite={false}
              resource="existsupplies"
              {...rest}
              {...configImportExistsSupply}
              label={translate("pos.action.importExistsSupply")}
            />
          )}
          <>
            <Button
                color="primary"
                component="button"
                variant="text"
                label="pos.action.exportGT"
                onClick={() => {
                  customHttpClient(`/api/supplies/export-file`).then(
                      (res) =>
                          FileSaver.saveAs(
                              `${process.env.REACT_APP_API_BASE_URL}${res.body}`,
                              "vat_tu.xlsx"
                          )
                  );
                }}
            >
              <CloudDownloadIcon style={{ fontSize: "20" }} />
            </Button>
          </>
        </>
      )}
    </TopToolbar>
  );
};

export default ListActions;
