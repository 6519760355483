import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";

import DialogContentText from "@material-ui/core/DialogContentText";
import { useDataProvider, useNotify } from "react-admin";
import { useHistory } from "react-router";

export default function PopupRejectDraftSupply(props: any) {
  const { isShowReject, showReject, billId } = props;
  const [note, changeNote] = useState("");
  const dataProvider = useDataProvider();
  const history = useHistory();
  const notify = useNotify();
  return (
    <Dialog
      open={isShowReject}
      maxWidth="lg"
      onClose={() => showReject(false)}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Trả lại phiếu đề xuất</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Bạn có chắc chắn trả lại phiếu đề xuất?
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Ghi chú"
          type="text"
          onChange={(e) => changeNote(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => showReject(false)} color="primary">
          Hủy bỏ
        </Button>
        <Button
          onClick={() => {
            showReject(false);
            dataProvider
              .create("statusbills/reject", {
                data: { note, billId },
              })
              .then(() => {
                notify("Trả lại thành công", "info");
                history.push("/bills");
              })
              .catch((err: any) => {
                notify(err.message, "warning");
              });
          }}
          color="primary">
          Trả lại
        </Button>
      </DialogActions>
    </Dialog>
  );
}
