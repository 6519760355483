import AddIcon from "@material-ui/icons/Add";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import HistoryIcon from "@material-ui/icons/HistoryOutlined";
import * as FileSaver from "file-saver";
import React, { cloneElement, FC, memo } from "react";
import {
  Button,
  CreateButton,
  ListButton,
  sanitizeListRestProps,
  TopToolbar,
} from "react-admin";
import { useHistory } from "react-router";
import { customHttpClient } from "../dataProvider";
import {
  isLeaderKT,
  isLeaderPX,
  isStaffKT,
  isStaffPX,
  useCanAccess,
  useMe,
} from "../helpers";

const ListActions: FC<any> = memo((props) => {
  const {
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    hasEdit,
    filterValues,
    permanentFilter,
    hasCreate, // you can hide CreateButton if hasCreate = false
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    hasList,
    hasShow,
    showExport,
    id,
    ...rest
  } = props;

  const history = useHistory();

  const { loading, me } = useMe();
  const canAccessCreate = useCanAccess({ action: "create", resource: "bill" });
  const isLeader = isLeaderKT(me) || isLeaderPX(me);
  const staffKt = isStaffKT(me);
  const staffPx = isStaffPX(me);
  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      // haslist={`${hasList}`}
      // hasshow={`${hasShow}`}
    >
      {!filters && <ListButton basePath={basePath} />}
      {filters &&
        cloneElement(filters, {
          undoable: "false",
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {canAccessCreate && (
        <>
          <CreateButton basePath={basePath} />

          {staffKt && (
            <Button
              color="primary"
              component="button"
              variant="text"
              label="pos.action.groupBill"
              onClick={() => {
                history.push("/bills/create-by-group");
              }}
            >
              <AddIcon style={{ fontSize: "20" }} />
            </Button>
          )}
        </>
      )}

      {showExport && (
        <>
          <Button
            color="primary"
            component="button"
            variant="text"
            label="pos.action.exportPDX"
            onClick={() => {
              customHttpClient(
                `/api/bills/exportpdx/${id}_pdx.docx`
              ).then((res) =>
                FileSaver.saveAs(
                  `${process.env.REACT_APP_API_BASE_URL}${res.body}`,
                  "phieudexuat.docx"
                )
              );
            }}
          >
            <CloudDownloadIcon style={{ fontSize: "20" }} />
          </Button>
          <Button
            color="primary"
            component="button"
            variant="text"
            label="pos.action.history"
            onClick={() => {
              history.push(`/bills/${id}/history`);
            }}
          >
            <HistoryIcon style={{ fontSize: "20" }} />
          </Button>
        </>
      )}
    </TopToolbar>
  );
});

export default ListActions;
