import { Box } from "@material-ui/core";
import React from "react";
import { Edit as EditAdmin } from "react-admin";
import { Form } from "./Create";
import ListActions from "./ListActions";

const MajorEdit = (props: any) => {
  return (
    <Box display={{ md: "block", lg: "flex" }}>
      <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
        <EditAdmin
          undoable={false}
          component="div"
          {...props}
          actions={<ListActions />}>
          <Form />
        </EditAdmin>
      </Box>
    </Box>
  );
};

export default MajorEdit;
