import {
  ReactEventHandler,
  SyntheticEvent,
  useCallback,
  useState,
} from "react";
import {
  CRUD_DELETE,
  useDelete,
  useNotify,
  useRedirect,
  useRefresh,
  useResourceContext,
} from "react-admin";

const useDeleteWithConfirmController = (
  props: UseDeleteWithConfirmControllerParams
): UseDeleteWithConfirmControllerReturn => {
  const {
    record,
    redirect: redirectTo,
    basePath,
    onClick,
    onSuccess,
    onFailure,
    data,
  } = props;
  const resource = useResourceContext(props);
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const [deleteOne, { loading }] = useDelete(resource, "", data, {
    action: CRUD_DELETE,
    onSuccess: (response: any) => {
      setOpen(false);
      if (onSuccess === undefined) {
        notify("ra.notification.deleted", "info", { smart_count: 1 });
        redirect(redirectTo, basePath);
        refresh();
      } else {
        onSuccess(response);
      }
    },
    onFailure: (error: any) => {
      setOpen(false);
      if (onFailure === undefined) {
        notify(
          typeof error === "string"
            ? error
            : error.message || "ra.notification.http_error",
          "warning"
        );
        refresh();
      } else {
        onFailure(error);
      }
    },
    undoable: false,
    ...data,
  });

  const handleDialogOpen = (e: any) => {
    setOpen(true);
    e.stopPropagation();
  };

  const handleDialogClose = (e: any) => {
    setOpen(false);
    e.stopPropagation();
  };

  const handleDelete = useCallback(
    (event) => {
      deleteOne({
        payload: { id: record.id },
      });
      if (typeof onClick === "function") {
        onClick(event);
      }
    },
    [deleteOne, onClick, record]
  );

  return { open, loading, handleDialogOpen, handleDialogClose, handleDelete };
};

export interface UseDeleteWithConfirmControllerParams {
  basePath?: string;
  record?: any;
  redirect?: any;
  // @deprecated. This hook get the resource from the context
  resource?: string;
  onClick?: ReactEventHandler<any>;
  onSuccess?: any;
  onFailure?: any;
  data?: any;
}

export interface UseDeleteWithConfirmControllerReturn {
  open: boolean;
  loading: boolean;
  handleDialogOpen: (e: SyntheticEvent) => void;
  handleDialogClose: (e: SyntheticEvent) => void;
  handleDelete: ReactEventHandler<any>;
}

export default useDeleteWithConfirmController;
