import React, { FC } from 'react';
import { FieldProps } from '../types';
import { required, SelectInput, useTranslate } from 'react-admin';

interface Props extends FieldProps<any> {
  className?: string;
  fullWidth?: any;
  resource?: string;
}
const requiredValidate = [required()];
const SexInput: FC<Props> = ({ record, className, source, ...rest }) => {
  const translate = useTranslate();

  return (
    <SelectInput
      {...rest}
      source={source}
      validate={requiredValidate}
      choices={[
        { id: "M", name: translate("common.fields.m") },
        { id: "F", name: translate("common.fields.f") },
        { id: "FM", name: translate("common.fields.fm") },
      ]}
    />
  );
};

export default SexInput;
