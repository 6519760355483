import PropTypes from "prop-types";
import React, {
  Fragment,
  ReactEventHandler,
  FC,
  ReactElement,
  SyntheticEvent,
  useState,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import ActionDelete from "@material-ui/icons/Delete";
import classnames from "classnames";
import {
  useTranslate,
  Record,
  RedirectionSideEffect,
  OnSuccess,
  OnFailure,
  useResourceContext,
} from "ra-core";

import { Button, ButtonProps } from "react-admin";
import Confirm from "./DialogConfim";
import useDeleteWithConfirmController from "../hooks/useDeleteWithConfirmReason";

const DeleteWithConfirmButton: FC<
  DeleteWithConfirmButtonProps & { hasReason?: boolean }
> = (props) => {
  const {
    basePath,
    classes: classesOverride,
    className,
    confirmTitle = "ra.message.delete_title",
    confirmContent = "ra.message.delete_content",
    icon = defaultIcon,
    label = "ra.action.delete",
    onClick,
    record,
    redirect = "list",
    onSuccess,
    onFailure,
    hasReason,
    ...rest
  } = props;
  const translate = useTranslate();
  const classes = useStyles(props);
  const [reason, setReason] = useState("");
  const {
    open,
    loading,
    handleDialogOpen,
    handleDialogClose,
    handleDelete,
  } = useDeleteWithConfirmController({
    record,
    redirect,
    basePath,
    onClick,
    onSuccess,
    onFailure,
    data: { reasonDelete: reason },
  });
  const resource = useResourceContext(props);

  return (
    <Fragment>
      <Button
        onClick={handleDialogOpen}
        label={label}
        className={classnames(
          "ra-delete-button",
          classes.deleteButton,
          className
        )}
        key="button"
        {...rest}
      >
        {icon}
      </Button>
      <Confirm
        hasReason={hasReason}
        reason={reason}
        setReason={setReason}
        isOpen={open}
        loading={loading}
        title={confirmTitle}
        content={confirmContent}
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

const defaultIcon = <ActionDelete />;

const useStyles = makeStyles(
  (theme) => ({
    deleteButton: {
      color: theme.palette.error.main,
      "&:hover": {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
  }),
  { name: "RaDeleteWithConfirmButton" }
);

interface Props {
  basePath?: string;
  classes?: object;
  className?: string;
  confirmTitle?: string;
  confirmContent?: string;
  icon?: ReactElement;
  label?: string;
  onClick?: ReactEventHandler<any>;
  record?: Record;
  redirect?: RedirectionSideEffect;
  resource?: string;
  // May be injected by Toolbar - sanitized in Button
  handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>;
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void;
  invalid?: boolean;
  pristine?: boolean;
  saving?: boolean;
  submitOnEnter?: boolean;
  undoable?: boolean;
  onSuccess?: OnSuccess;
  onFailure?: OnFailure;
}
export type DeleteWithConfirmButtonProps = Props & any;

DeleteWithConfirmButton.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  confirmTitle: PropTypes.string,
  confirmContent: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.any,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  icon: PropTypes.element,
};
export default DeleteWithConfirmButton;
