import * as _ from "lodash";
import MaterialTable, { Column } from "material-table";
import React, {FC, memo, useEffect, useState} from "react";
import {Button, useDataProvider, useNotify, useTranslate} from "react-admin";
import { useField } from "react-final-form";
import { components, tableIcons, options, showAddAction } from "./TableSupply";
import { useRouteMatch } from "react-router";
import { BillStatusType } from "../dataProvider";
import { useThrottle } from "../helpers";
import DialogSupplyMoreDetail from "./DialogSupplyMoreDetail";
import IconButton from "@material-ui/icons/MoreOutlined";

export const cols = (translate: any) =>
  [
    {
      title: translate("resources.supplies.fields.serviceName"),
      field: "name",
      width: "200px",
    },
    {
      title: translate("resources.supplies.fields.content"),
      field: "content",
      width: "500px",
    },
    {
      title: translate("resources.supplies.fields.count"),
      field: "count",
      width: "100px",
      type: "numeric",
    },
    {
      title: translate("resources.supplies.fields.unit"),
      field: "unit",
      width: "100px",
    },
    {
      title: translate("resources.supplies.fields.note"),
      field: "note",
    },
      {
          title: translate("pos.action.more"),
          render: (row: any) => {
              return <ShowMoreButton {...row} />;
          },
          width: 100,
      },
  ].map((i: any) => ({
    ...i,
    headerStyle: {
      ...(i.headerStyle || {}),
      padding: "4px",
    },
    cellStyle: {
      ...(i.cellStyle || {}),
      padding: "4px",
      borderWidth: 1,
      borderColor: "black",
      borderStyle: "solid",
    },
  })) as Column<any>;

const ShowMoreButton: FC<any> = (props) => {
    const [showMore, setShowMore] = useState(false);
    if (!props.id) return <></>;
    return (
        <>
            <DialogSupplyMoreDetail
                showMore={showMore}
                setShowMore={setShowMore}
                type='targets'
                {...props}
            />
            <Button
                color="primary"
                component="span"
                variant="contained"
                label="pos.action.more"
                onClick={() => {
                    setShowMore(() => true);
                }}
            >
                <IconButton style={{ fontSize: "20" }} />
            </Button>
        </>
    );
};

const TableTarget = memo((props: any) => {
  const inputProps = useField(props.source);
  const match = useRouteMatch<{ id: string }>();
  const billId = match.params.id;
  const translate = useTranslate();
  const notify = useNotify();

  const { isClone, statusType } = props;
  const isShowAdd = showAddAction(isClone, statusType);
  return (
    <MaterialTable
      icons={tableIcons}
      title={translate("resources.bills.zones.pl3")}
      options={options(isClone)}
      columns={cols(translate) as any}
      data={[
        ..._.reject(inputProps.input.value, (val) => val.isActive === false),
      ]}
      components={components(billId, "targets", isShowAdd, false)}
      localization={{
        pagination: { labelRowsSelect: "bản ghi" },
        body: {
          emptyDataSourceMessage: "Không có bản ghi nào",
          editRow: { deleteText: "Xóa bản ghi?" },
        },
        header: { actions: "Chỉnh sửa" },
      }}
      onSelectionChange={(rows, rowData) => {
        // if (_.isEmpty(rows)) return;
        const newData = inputProps.input.value.map((input: any) => {
          // const update = _.find(
          //   rows,
          //   (row) =>
          //     row.materialCode === input.materialCode &&
          //     row.billId === input.billId
          // );
          return {
            ...input,
            checked:
              input.name === rowData.name ? !input.checked : input.checked,
          };
        });
        inputProps.input.onChange(newData);
      }}
      editable={
        !isShowAdd
          ? { onRowAdd: () => new Promise((resolve) => {}) }
          : {
              onRowAdd: (newData: any) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (!newData.name) {
                      notify("pos.action.fillMissing");
                      return reject();
                    }
                    inputProps.input.onChange([
                      ...inputProps.input.value,
                      newData,
                    ]);

                    resolve(null);
                  }, 0);
                }),
              onRowUpdate: (newData: any, oldData: any) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (!newData.name) {
                      notify("pos.action.fillMissing");
                      return reject();
                    }
                    const dataUpdate = [...inputProps.input.value];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    inputProps.input.onChange([...dataUpdate]);

                    resolve(null);
                  }, 0);
                }),
              onRowDelete: (oldData: any) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataDelete = [...inputProps.input.value];
                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    inputProps.input.onChange(dataDelete);

                    resolve(null);
                  }, 100);
                }),
            }
      }
    />
  );
});

export default TableTarget;
