import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { CreateDraftSupply, EditDraftSupply } from "./CreateDraftSupply";

export default function PopupCreateDraftSupply(props: any) {
  const { isShowCreate, showCreate, billId, isEditPopup = false } = props;

  return (
    <Dialog
      open={isShowCreate}
      maxWidth="lg"
      onClose={() => showCreate(false)}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Tạo/sửa vật tư</DialogTitle>
      <DialogContent>
        {isEditPopup ? (
          <EditDraftSupply id={4} showCreate={showCreate} />
        ) : (
          <CreateDraftSupply record={{ billId }} showCreate={showCreate} />
        )}
      </DialogContent>
    </Dialog>
  );
}
