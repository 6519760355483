import React, {FC, useState} from 'react';
import {
    AutocompleteInput,
    DateField,
    DateInput,
    Filter, ReferenceInput,
    SelectInput,
    TextInput,
    useInput,
    useTranslate
} from 'react-admin';
import * as _ from "lodash";
import {DepartmentAlias, MissingDeviceStatus, to_chuc_thuc_hien} from "./Types";
import {TextField} from "@material-ui/core";
import moment from "moment";
import {allHeThong, don_vi_sua_chua, don_vi_tao, to_may} from "../maintenance/Types";

const SupplyStatusInput: FC<any> = (props) => {
    const translate = useTranslate();
    return (
      <SelectInput
        {...props}
        choices={_.keys(MissingDeviceStatus).map((key) => ({
            id: (MissingDeviceStatus as any)[key],
            name: translate(
              `resources.missingdevices.status.${(MissingDeviceStatus as any)[key]}`
            ),
        }))}
      />
    );
};

const ToMayInput: FC<any> = (props) => {
    const translate = useTranslate();
    return (
        <SelectInput
            {...props}
            choices={_.keys(MissingDeviceStatus).map((key) => ({
                id: (MissingDeviceStatus as any)[key],
                name: translate(
                    `resources.missingdevices.status.${(MissingDeviceStatus as any)[key]}`
                ),
            }))}
        />
    );
};

const DateCustomInput: FC<any> = (props) => {
    const { input } = useInput(props);
    const [value, setValue] = useState<any>("");

    return (
        <div> ngày tháng
        <input value={value} onChange={(e)=>setValue(e.target.value)}/>
        </div>
    );
};

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
      <SupplyStatusInput variant="outlined" alwaysOn source="status"/>
      <TextInput
        variant="outlined"
        alwaysOn
        source="khiem_khuyet_thiet_bi"
        label="Quản lý khiếm khuyết thiết bị"
      />
      <SelectInput
          source="to_may"
          resource="missingdevices"
          choices={to_may}
          label="Tổ máy"
          alwaysOn
          variant="outlined"
      />
      <SelectInput
          source="he_thong"
          resource="missingdevices"
          label="Hệ thống"
          alwaysOn
          choices={allHeThong}
          variant="outlined"
      />
      <SelectInput
          source="don_vi_tao"
          resource="missingdevices"
          label="Đơn vị tạo"
          alwaysOn
          choices={don_vi_tao}
          variant="outlined"
      />
      <SelectInput
          source="to_thuc_hien"
          resource="missingdevices"
          label="Tổ chức thực hiện"
          alwaysOn
          choices={to_chuc_thuc_hien}
          variant="outlined"
      />
      <ReferenceInput
          alwaysOn
          label="Đơn vị thực hiện"
          source="departmentId"
          reference="departments"
          resource="misssingdevices"
          variant="outlined"
          filterToQuery={(searchText: any) => {
              return _.isEmpty(_.trim(searchText))
                  ? null
                  : {name: searchText};
          }}>
          <AutocompleteInput
              optionText="name"
              fullWidth
              variant="outlined"
          />
      </ReferenceInput>
      <DateInput source="createdAt||$gte" label="Từ ngày" alwaysOn variant="outlined"/>
      <DateInput source="createdAt||$lte" label="Tới ngày" alwaysOn variant="outlined"/>
  </Filter>
);

export default ListFilter;
