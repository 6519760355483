import { Box, Card, CardContent } from "@material-ui/core";
import React, { FC } from "react";
import {
  Create,
  NumberInput,
  FormWithRedirect,
  TextInput,
  Toolbar,
  SaveButton,
  Button,
  Edit,
} from "react-admin";
import CloseIcon from "@material-ui/icons/Close";

const Form: FC<any> = (props) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box
                display={{ md: "block", lg: "flex" }}
                style={{ alignItems: "center", width: "100%" }}
              >
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="name" resource="supplies" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="code" resource="supplies" fullWidth />
                </Box>

                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="materialCode"
                    resource="supplies"
                    fullWidth
                  />
                </Box>

                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="system" resource="supplies" fullWidth />
                </Box>
              </Box>
              <Box
                display={{ md: "block", lg: "flex" }}
                style={{ alignItems: "center", width: "100%" }}
              >
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="info" resource="supplies" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="madein" resource="supplies" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <NumberInput source="count" resource="supplies" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="unit" resource="supplies" fullWidth />
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="bills"
              undoable={false}
            >
              <SaveButton
                saving={formProps.saving}
                handleSubmitWithRedirect={(args: any) => {
                  formProps.handleSubmitWithRedirect(null);
                  props.showCreate(false);
                }}
                // @ts-ignore
                redirect={null}
                resource="bills"
              />
              <Button
                style={{ color: "red" }}
                component="button"
                variant="text"
                label="ra.action.cancel"
                onClick={() => {
                  props.showCreate(false);
                }}
              >
                <CloseIcon style={{ fontSize: "20", color: "red" }} />
              </Button>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};
export const CreateDraftSupply = (props: any) => {
  return (
    <>
      <Create
        {...props}
        title={" "}
        resource="draftsupplies"
        basePath="/draftsupplies"
      >
        <Form {...props} />
      </Create>
    </>
  );
};

export const EditDraftSupply = (props: any) => {
  return (
    <>
      <Edit
        {...props}
        title={" "}
        resource="draftsupplies"
        basePath="/draftsupplies"
      >
        <Form {...props} />
      </Edit>
    </>
  );
};
