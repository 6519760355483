import dayjs from "dayjs";
import React, { FC } from "react";
import Chip from "@material-ui/core/Chip";
import { FieldProps } from "../types";

interface Props extends FieldProps<any> {
  className?: string;
  isChip?: boolean;
  showOnlyDate?: boolean;
  width?: any;
}

const DateTimeField: FC<Props> = ({
  record,
  className,
  source,
  isChip = false,
  showOnlyDate = false,
  width = "80px",
}) => {
  const format = showOnlyDate ? "DD-MM-YYYY" : "HH:mm DD-MM-YYYY";
  return record ? (
    isChip ? (
      <Chip
        className={className}
        label={
          source && record[source] ? dayjs(record[source]).format(format) : ""
        }
      />
    ) : (
      <p style={{ display: "block", minWidth: width }}>
        {source && record[source] ? dayjs(record[source]).format(format) : ""}
      </p>
    )
  ) : null;
};

export default DateTimeField;
