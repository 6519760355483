import {
    AutocompleteInput,
    FormWithRedirect,
    ReferenceInput, required,
    SaveButton,
    SelectInput,
    TextInput,
    Toolbar, useNotify, useRefresh,
    useUpdate
} from "react-admin";
import {Box, Card, CardContent, Typography} from "@material-ui/core";
import React, {useState} from "react";
import * as _ from "lodash";
import {Button as RaButton} from "ra-ui-materialui/lib/button";
import {useHistory} from "react-router";
import {DepartmentAlias, loai_cong_viec, MaintenanceStatus} from "./Types";
import CheckboxInput from "../components/CheckboxInput";
import {to_may, to_may_1, to_may_2, phan_chung} from "./Types";
import {useApiFetch} from "../helpers";

export const Form = (props: any) => {
    const {isEdit, record} = props;
    const renderHeThong = (formProps: any) => {
        const {to_may} = formProps.form.getState().values;
        let result;
        switch (to_may) {
            case "to_may_1":
                result = to_may_1;
                break;
            case "to_may_2":
                result = to_may_2;
                break;
            default:
                result = phan_chung;
        }
        return result;
    }
    const history = useHistory();
    const isDisableEdit = (allowAliases: string[]) => {
        return false;
    }
    const [update] = useUpdate("maintenance", record ? record.id : "");
    const notify = useNotify();
    const refresh = useRefresh();
    const [departmentId, setDepartmentId] = useState<number>(0);
    const {data} = useApiFetch(
        `/api/maintenance/department/${departmentId}/to`,
        [departmentId]
    );

    const handleFinish = (formProps: any) => {
        const {form} = formProps
        update(
            {
                type: "update",
                resource: "maintenance",
                payload: {
                    id: record.id,
                    data: {
                        ...form.getState().values,
                        status: MaintenanceStatus.FINISH
                    }
                },
            },
            {
                onSuccess: () => {
                    notify("Hoàn thành", "info");
                    refresh()
                },
            }
        );
    }
    return (
        <FormWithRedirect
            {...props}
            render={(formProps: any) => {
                const state = formProps.form.getState()
                if (!state.status) {
                    formProps.form.change("status", MaintenanceStatus.OPENING)
                }
                if(!state.modified.departmentId && state.initialValues.departmentId) {
                    setDepartmentId(state.initialValues.departmentId);
                }
                return (
                    <Card>
                        <form>
                            <CardContent>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <SelectInput
                                            source="to_may"
                                            resource="maintenance"
                                            fullWidth
                                            variant={"outlined"}
                                            choices={to_may}
                                            defaultValue="to_may_1"
                                            validate={requiredValidate}
                                            onChange={() => formProps.form.change("he_thong", undefined)}
                                        />
                                    </Box>
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <SelectInput
                                            source="he_thong"
                                            resource="maintenance"
                                            fullWidth
                                            validate={requiredValidate}
                                            choices={renderHeThong(formProps)}
                                            variant={"outlined"}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <ReferenceInput
                                            label="Đơn vị thực hiện"
                                            source="departmentId"
                                            reference="departments"
                                            resource="maintenance"
                                            variant="outlined"
                                            onChange={(id:number)=> {
                                                setDepartmentId(id);
                                            }}
                                            filterToQuery={(searchText: any) => {
                                                return _.isEmpty(_.trim(searchText))
                                                    ? null
                                                    : {name: searchText};
                                            }}>
                                            <AutocompleteInput
                                                optionText="name"
                                                optionValue="id"
                                                fullWidth
                                                variant="outlined"
                                                disabled={isDisableEdit([
                                                    DepartmentAlias.p4,
                                                    DepartmentAlias.px4
                                                ])}
                                            />
                                        </ReferenceInput>
                                    </Box>
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <SelectInput
                                            source="to"
                                            label={"Tổ"}
                                            resource="maintenance"
                                            fullWidth
                                            choices={data ? data : []}
                                            variant={"outlined"}
                                        />
                                    </Box>
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <SelectInput
                                            source="loai_cong_viec"
                                            label={"Loại công việc"}
                                            resource="maintenance"
                                            fullWidth
                                            choices={loai_cong_viec}
                                            variant={"outlined"}
                                            defaultValue={loai_cong_viec[0].id}
                                        />
                                    </Box>
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <TextInput
                                            source="thiet_bi"
                                            label="Thiết bị"
                                            resource="maintenance"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <TextInput
                                            source="ma_kks"
                                            label="Mã KKS"
                                            multiline
                                            rows={3}
                                            resource="maintenance"
                                            fullWidth
                                            variant="outlined"
                                            disabled={isDisableEdit([
                                                DepartmentAlias.px4,
                                                DepartmentAlias.px2,
                                            ])}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <TextInput
                                            source="noi_dung"
                                            label="Nội dung công việc"
                                            multiline
                                            rows={3}
                                            resource="maintenance"
                                            fullWidth
                                            variant="outlined"
                                            disabled={isDisableEdit([
                                                DepartmentAlias.px1,
                                                DepartmentAlias.px3,
                                                DepartmentAlias.px5,
                                            ])}
                                        />
                                    </Box>
                                </Box>
                                <CardContent>
                                    <Typography variant="h6" component="h2" style={{fontWeight: 500}}>
                                        Kế hoạch thực hiện
                                    </Typography>
                                    <Box
                                        display={{md: "block", lg: "flex"}}
                                        style={{alignItems: "center"}}
                                    >
                                        <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                            <CheckboxInput
                                                resource="maintenance"
                                                source="ke_hoach_tuan1"
                                                type="checkbox"
                                                label="Tuần 1"
                                                disabled={
                                                    false
                                                }
                                            />
                                        </Box><Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <CheckboxInput
                                            resource="maintenance"
                                            source="ke_hoach_tuan2"
                                            type="checkbox"
                                            label="Tuần 2"
                                            disabled={
                                                false
                                            }
                                        />
                                    </Box>
                                        <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                            <CheckboxInput
                                                resource="maintenance"
                                                source="ke_hoach_tuan3"
                                                type="checkbox"
                                                label="Tuần 3"
                                                disabled={
                                                    false
                                                }
                                            />
                                        </Box><Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <CheckboxInput
                                            resource="maintenance"
                                            source="ke_hoach_tuan4"
                                            type="checkbox"
                                            label="Tuần 4"
                                            disabled={
                                                false
                                            }
                                        />
                                    </Box>
                                    </Box>
                                </CardContent>
                                <CardContent>
                                    <Typography variant="h6" component="h2" style={{fontWeight: 500}}>
                                        1. Xác nhận thực hiện
                                    </Typography>
                                    <Box
                                        display={{md: "block", lg: "flex"}}
                                        style={{alignItems: "center"}}
                                    >
                                        <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                            <CheckboxInput
                                                resource="maintenance"
                                                source="xac_nhan_tuan1"
                                                type="checkbox"
                                                label="Tuần 1"
                                                disabled={
                                                    false
                                                }
                                            />
                                        </Box><Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <CheckboxInput
                                            resource="maintenance"
                                            source="xac_nhan_tuan2"
                                            type="checkbox"
                                            label="Tuần 2"
                                            disabled={
                                                false
                                            }
                                        />
                                    </Box>
                                        <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                            <CheckboxInput
                                                resource="maintenance"
                                                source="xac_nhan_tuan3"
                                                type="checkbox"
                                                label="Tuần 3"
                                                disabled={
                                                    false
                                                }
                                            />
                                        </Box><Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <CheckboxInput
                                            resource="maintenance"
                                            source="xac_nhan_tuan4"
                                            type="checkbox"
                                            label="Tuần 4"
                                            disabled={
                                                false
                                            }
                                        />
                                    </Box>
                                    </Box>
                                </CardContent>
                                <CardContent>
                                    <Typography variant="h6" component="h2" style={{fontWeight: 500}}>
                                        2. Xác nhận hoàn thành
                                    </Typography>
                                    <Box
                                        display={{md: "block", lg: "flex"}}
                                        style={{alignItems: "center"}}
                                    >
                                        <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                            <CheckboxInput
                                                resource="maintenance"
                                                source="tuan1"
                                                type="checkbox"
                                                label="Tuần 1"
                                                disabled={
                                                    false
                                                }
                                            />
                                        </Box><Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <CheckboxInput
                                            resource="maintenance"
                                            source="tuan2"
                                            type="checkbox"
                                            label="Tuần 2"
                                            disabled={
                                                false
                                            }
                                        />
                                    </Box>
                                        <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                            <CheckboxInput
                                                resource="maintenance"
                                                source="tuan3"
                                                type="checkbox"
                                                label="Tuần 3"
                                                disabled={
                                                    false
                                                }
                                            />
                                        </Box><Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <CheckboxInput
                                            resource="maintenance"
                                            source="tuan4"
                                            type="checkbox"
                                            label="Tuần 4"
                                            disabled={
                                                false
                                            }
                                        />
                                    </Box>
                                    </Box>
                                </CardContent>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <TextInput
                                            source="kq_thuc_hien"
                                            label="Kết quả thực hiện"
                                            multiline
                                            rows={3}
                                            resource="maintenance"
                                            fullWidth
                                            variant="outlined"
                                            disabled={isDisableEdit([
                                                DepartmentAlias.p4,
                                            ])}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <TextInput
                                            source="xac_nhan_dv_van_hanh"
                                            label="Xác nhận đơn vị vận hành"
                                            multiline
                                            rows={3}
                                            resource="maintenance"
                                            fullWidth
                                            variant="outlined"
                                            disabled={isDisableEdit([
                                                DepartmentAlias.p3,
                                            ])}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <TextInput
                                            source="p4_danh_gia"
                                            label="P4 đánh giá"
                                            multiline
                                            rows={3}
                                            resource="maintenance"
                                            fullWidth
                                            variant="outlined"
                                            disabled={isDisableEdit([
                                                DepartmentAlias.px1,
                                                DepartmentAlias.px3,
                                                DepartmentAlias.px5,
                                            ])}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <TextInput
                                            source="ghi_chu"
                                            label="Ghi chú"
                                            multiline
                                            rows={3}
                                            resource="maintenance"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Box>
                                </Box>
                            </CardContent>
                            <Toolbar
                                record={formProps.record}
                                basePath={formProps.basePath}
                                invalid={formProps.invalid}
                                handleSubmit={formProps.handleSubmit}
                                saving={formProps.saving}
                                resource="maintenance"
                                undoable={false}
                            >
                                <div>
                                    <SaveButton
                                        style={{
                                            marginRight: "10px"
                                        }}
                                        saving={formProps.saving}
                                        redirect={isEdit ? "edit" : "list"}
                                        handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                    />
                                    <RaButton
                                        onClick={() => {
                                            history.push(`/maintenance`);
                                        }}
                                        label="Quay lại"
                                        color="primary"
                                        component="button"
                                        variant="contained"
                                        size="medium"
                                    />
                                </div>
                            </Toolbar>
                        </form>
                    </Card>
                )
            }}
        />
    );
};

const requiredValidate = [required()]
