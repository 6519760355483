import { Chip, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import Done from "@material-ui/icons/Done";
import Edit from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as _ from "lodash";
import MaterialTable, { Column, Components, Options } from "material-table";
import { ImportButton } from "ra-import-csv";
import React, { memo } from "react";
import { Button, useNotify, useTranslate } from "react-admin";
import { useField } from "react-final-form";
import { useRouteMatch } from "react-router";
import { tableIcons } from "../bills/TableSupply";
import { MTableToolbar } from "../components/MToolbar";
import { slugify } from "../helpers";

export const BuyingStatusTypeColor: any = {
    chua_thuc_hien: "#d32f2f",
    dang_trien_khai_lcnt: "#81c784",
    da_thuc_hien: "#448aff",
};

export enum BuyingStatusType {
    NOT_BUYING = "Chưa thực hiện",
    ACCEPT = "Đang triển khai LCNT",
    IN_STOCK = "Đã thực hiện",
}

const HEADER_MAPPER: any = {
  stt: "id",
  ma_vat_tu: "materialCode",
  tien_do: "status",
};

const configImportSupply = {
  parseConfig: {
    header: true,
    transformHeader: (h: string) => {
      const slug = slugify(h);
      let header = slug;

      const vals: string[] = Object.keys(HEADER_MAPPER);

      for (let i = 0; i < vals.length; i++) {
        if (slug.includes(vals[i])) {
          header = vals[i];
          break;
        }
      }

      return HEADER_MAPPER[header];
    },
  },
};

export const colSCDX2 = (translate: any) =>
  [
    {
      title: "STT",
      field: "id",
      editable: "never",
      width: 30,
      headerStyle: { textAlign: "center" },
      cellStyle: {
        width: 30,
        minWidth: 30,
        overflowWrap: "break-word",
        textAlign: "center",
      },
    },
    {
      title: translate("resources.supplies.fields.materialCode"),
      field: "materialCode",
      sorting: false,
      width: 100,
      cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
    },
    {
      title: translate("resources.supplies.fields.system"),
      field: "system",
      editable: "never",
      sorting: false,
      width: 50,
      cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
    },
    {
      title: translate("resources.supplies.fields.deviceName"),
      field: "deviceName",
      sorting: false,
      editable: "never",
      width: 50,
      cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
    },

    {
      title: translate("resources.supplies.fields.name"),
      field: "name",
      editable: "never",
      sorting: false,
      width: 150,
      cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
    },
    {
      title: translate("resources.supplies.fields.info"),
      field: "info",
      width: 300,
      sorting: false,
      editable: "never",
      cellStyle: { minWidth: 200, overflowWrap: "break-word" },
    },
    {
      title: translate("resources.supplies.fields.code"),
      field: "code",
      editable: "never",
      sorting: false,
      width: 100,
      cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
    },

    {
      title: translate("resources.supplies.fields.madein"),
      field: "madein",
      cellStyle: { maxWidth: 100, overflowWrap: "break-word" },
      width: 50,
      sorting: false,
      editable: "never",
    },
    {
      title: translate("resources.supplies.fields.unit"),
      field: "unit",
      editable: "never",
      sorting: false,
      width: 10,
      cellStyle: { maxWidth: 60, overflowWrap: "break-word" },
    },

    {
      title: translate("resources.supplies.fields.count"),
      field: "count",
      type: "numeric",
      editable: "never",
      sorting: false,
      width: 10,
      cellStyle: { maxWidth: 50, overflowWrap: "break-word" },
    },
    {
      title: translate("resources.supplies.fields.buyingStatus"),
      field: "status",
      width: 100,
      sorting: false,
      editComponent: (row: any) => {
        return (
          <Autocomplete
            freeSolo
            value={row.value}
            options={_.values(BuyingStatusType).map((status) => status)}
            onInputChange={(e: any, value) => {
              row.onChange(value);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        );
      },
      render: (row: any) => {
        return (
          <Chip
            label={row.status}
            color="primary"
            size="small"
            style={{
              backgroundColor:
                BuyingStatusTypeColor[slugify(row.status) as any],
            }}
          />
        );
      },
    },

    // {
    //   title: translate("resources.supplies.fields.note"),
    //   field: "note",
    //   editable: "never",
    // },
    {
      title: translate("resources.supplies.fields.idkt"),
      field: "ktId",
      width: 150,
      sorting: false,
      cellStyle: { maxWidth: 50, overflowWrap: "break-word" },
    },
    {
      title: translate("resources.supplies.fields.idpx"),
      field: "pxId",
      sorting: false,
      width: 150,
      cellStyle: { maxWidth: 50, overflowWrap: "break-word" },
    },
  ] as Column<any>[];

export const getCols = (translate: any) => {
  let cols = [];

  cols = colSCDX2(translate);

  return cols.map((i: any) => ({
    ...i,
    headerStyle: {
      ...(i.headerStyle || {}),
      padding: "4px",
    },
    cellStyle: {
      ...(i.cellStyle || {}),
      padding: "4px",
      borderWidth: 1,
      borderColor: "black",
      borderStyle: "solid",
    },
  })) as Column<any>[];
};

export const components = (packageId: string | undefined, clickAddItem: any) =>
  ({
    Action: (props: any) => {
      const action =
        typeof props.action === "function"
          ? props.action().tooltip
          : props.action.tooltip;
      switch (action) {
        case "Add":
          if (!packageId)
            return (
              <Button
                component="button"
                variant="text"
                label="Thêm dịch vụ"
                onClick={clickAddItem}
              >
                <AddIcon style={{ fontSize: "20" }} />
              </Button>
            );
          return (
            <>
              <ImportButton
                resource="packagesupplies"
                {...configImportSupply}
                label="Cập nhật tiến độ pl1"
                showOverWrite={true}
                showCreate={false}
                addFields={(rows: any) => {
                    console.log(rows);

                    return rows.map((row: any) => {
                    const status = row.status;
                    const newRow = _.pickBy(
                      { ...row, packageId, status },
                      _.identity
                    );
                    return newRow;
                  });
                }}
              />
              <Button
                component="button"
                variant="text"
                label="Thêm dịch vụ"
                onClick={clickAddItem}
              >
                <AddIcon style={{ fontSize: "20" }} />
              </Button>
            </>
          );
        case "Edit":
          return (
            <Button
              label={`ra.action.edit`}
              onClick={(event: any) =>
                props.action().onClick(event, props.data)
              }
            >
              <Edit />
            </Button>
          );

        case "Save":
          return (
            <Done
              onClick={(event: any) => props.action.onClick(event, props.data)}
            />
          );

        case "Cancel":
          return (
            <Close
              style={{ color: "red" }}
              onClick={(event: any) => props.action.onClick(event, props.data)}
            />
          );

        default:
          console.log(props);
          return <></>;
      }
    },
    Toolbar: MTableToolbar,
  } as Components);

export const options = (isClone: boolean) =>
  ({
    search: false,
    actionsColumnIndex: -1,
    pageSize: 5,
    showSelectAllCheckbox: false,
    showTextRowsSelected: false,
    tableLayout: "auto",
    headerStyle: {
      borderWidth: 1,
      borderColor: "black",
      borderStyle: "solid",
    },
    rowStyle: {
      padding: 0,
      fontSize: "14px",
      paddingBlock: 0,
    },
    exportFileName: "vat_tu",
    actionsCellStyle: {
      padding: "4px",
      borderWidth: 1,
      borderColor: "black",
      borderStyle: "solid",
    },
  } as Options<any>);

const TableSupplyChoosen = memo((props: any) => {
  const { setShowChoosen } = props;
  const inputProps = useField(props.source);
  const match = useRouteMatch<{ id: string }>();
  const packageId = match.params.id;

  const translate = useTranslate();
  const notify = useNotify();

    const filterOutData = _.reject(
        inputProps.input.value,
        (val) => val.isActive === false || val.checked === false
    );
  return (
    <MaterialTable
      icons={tableIcons}
      title={"Phụ lục 1"}
      options={options(true)}
      columns={getCols(translate) as any}
      data={filterOutData}
      components={components(packageId, () => {
        setShowChoosen(() => true);
      })}
      localization={{
        pagination: { labelRowsSelect: "bản ghi" },
        body: {
          emptyDataSourceMessage: "Không có bản ghi nào",
          editRow: { deleteText: "Xóa bản ghi?" },
        },
        header: { actions: "Chỉnh sửa" },
      }}
      editable={{
        onRowAdd: () => new Promise((resolve) => {}),
        onRowUpdate: (newData: any, oldData: any) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataUpdate = [...inputProps.input.value];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              inputProps.input.onChange([...dataUpdate]);
              resolve(null);
            }, 0);
          }),
      }}
    />
  );
});

export default TableSupplyChoosen;
