import React, { FC } from "react";
import { Filter, TextInput } from "react-admin";

const ListFilter: FC<any> = (props) => (
  <fieldset className="filter-fieldset">
    <legend>Tìm kiếm</legend>
    <Filter {...props}>
      <TextInput source="materialCode" alwaysOn variant="outlined" />
      <TextInput variant="outlined" source="name" alwaysOn />
      <TextInput source="code" alwaysOn variant="outlined" />
      <TextInput source="info" alwaysOn variant="outlined" />
    </Filter>
  </fieldset>
);

export default ListFilter;
