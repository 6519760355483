import React, { FC } from "react";
import * as _ from "lodash";
import { Chip } from "@material-ui/core";
import { FieldProps } from "../types";

interface Props extends FieldProps<any> {
  className?: string;
  size?: "small" | "medium";
}

const ChipField: FC<Props> = ({
  record,
  className,
  source,
  size = "small",
}) => {
  return record && source && _.get(record, source, undefined) ? (
    <Chip className={className} label={source && record[source]} size={size} />
  ) : null;
};

export default ChipField;
