export enum MaintenanceStatus {
  OPENING = "opening",
  FINISH = "finish",
}

export enum DepartmentAlias {
  px1 = "px1",
  px2 = "px2",
  px3 = "px3",
  px4 = "px4",
  px5 = "px5",
  p3 = "p3",
  p2 = "p2",
  p4 = "p4"
}

export const to_may = [
  {id: "to_may_1", name: "Tổ máy 1"},
  {id: "to_may_2", name: "Tổ máy 2"},
  {id: "phan_chung", name: "Phần Chung"}
]

export const to_may_object: any = {
  to_may_1: to_may[0].name,
  to_may_2: to_may[1].name,
  phan_chung: to_may[2].name
}
export const to_may_1 = [
  {id: "lo_hoi", name: "Lò hơi"},
  {id: "turbine", name: "Turbine"},
  {id: "thai_xi", name: "Thải xỉ"},
  {id: "FGD", name: "FGD"},
  {id: "ESP", name: "ESP"},
  {id: "CEMS", name: "CEMS"},
]

export const to_may_1_object: any = {
  lo_hoi: to_may_1[0].name,
  turbine: to_may_1[1].name,
  thai_xi: to_may_1[2].name,
  FGD: to_may_1[3].name,
  ESP: to_may_1[4].name,
  CEMS: to_may_1[5].name,
}


export const to_may_2 = [
  {id: "lo_hoi", name: "Lò hơi"},
  {id: "turbine", name: "Turbine"},
  {id: "thai_xi", name: "Thải xỉ"},
  {id: "FGD", name: "FGD"},
  {id: "ESP", name: "ESP"},
  {id: "CEMS", name: "CEMS"},
]

export const to_may_2_object: any = {
  lo_hoi: to_may_2[0].name,
  turbine: to_may_2[1].name,
  thai_xi: to_may_2[2].name,
  FGD: to_may_2[3].name,
  ESP: to_may_2[4].name,
  CEMS: to_may_2[5].name,
}

export const phan_chung = [
  {id: "nhien_lieu", name: "Nhiên liệu"},
  {id: "xu_ly_nuoc", name: "Xử lý nước"},
  {id: "thai_xi", name: "Thải xỉ"},
  {id: "FGD", name: "FGD"},
  {id: "he_thong_dien", name: "Hệ thống điện"},
  {id: "at_mt", name: "An toàn & môi trường"},
  {id: "pccc", name: "Hệ thống PCCC"},
  {id: "ttas", name: "Hệ thống TT&AS"},
  {id: "he_thong_dieu_khien", name: "Hệ thống điều khiển"},
]

export const phan_chung_object: any = {
  nhien_lieu: phan_chung[0].name,
  xu_ly_nuoc: phan_chung[1].name,
  thai_xi: phan_chung[2].name,
  FGD: phan_chung[3].name,
  he_thong_dien: phan_chung[4].name,
  at_mt: phan_chung[5].name,
  pccc: phan_chung[6].name,
  ttas: phan_chung[7].name,
  he_thong_dieu_khien: phan_chung[8].name,
}

export const allHeThong = [
  {id: "lo_hoi", name: "Lò hơi"},
  {id: "turbine", name: "Turbine"},
  {id: "ESP", name: "ESP"},
  {id: "CEMS", name: "CEMS"},
  {id: "nhien_lieu", name: "Nhiên liệu"},
  {id: "xu_ly_nuoc", name: "Xử lý nước"},
  {id: "thai_xi", name: "Thải xỉ"},
  {id: "FGD", name: "FGD"},
  {id: "he_thong_dien", name: "Hệ thống điện"},
  {id: "at_mt", name: "An toàn & môi trường"},
  {id: "pccc", name: "Hệ thống PCCC"},
  {id: "ttas", name: "Hệ thống TT&AS"},
  {id: "he_thong_dieu_khien", name: "Hệ thống điều khiển"},
]

export const don_vi_tao = [
  {id: "px1", name: "PX1"},
  {id: "px3", name: "PX3"},
  {id: "px5", name: "PX5"},
]

export const don_vi_sua_chua = [
  {id: "px2", name: "PX2"},
  {id: "px4", name: "PX4"},
]

export const loai_cong_viec = [
  {id: "bdtx", name: "Bảo dưỡng thường xuyên"},
  {id: "cvps", name: "Công việc phát sinh"},
]

export const allHeThongObject = {...to_may_1_object, ...to_may_2_object, ...phan_chung_object}


export const toObject = [
  {id: "dien", name: "Điên"},
  {id: "co", name: "Cơ"},
  {id: "ci", name: "CI"},
  {id: "nhat_thu", name: "Nhất thứ"},
  {id: "nhi_thu", name: "Nhị Thứ"},
  {id: "thong_tin", name: "Thông tin"},
  {id: "phan_mem", name: "Phầm mềm"},
]
