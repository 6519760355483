import React, { FC, useState } from "react";
import { Datagrid, List, TextField, useTranslate } from "react-admin";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";
import { useCanAccess } from "../helpers";
import TextFieldMore from "../components/TextFieldMore";
import Pagination from "../components/Pagination";

const StudentList: FC<any> = (props) => {
  const translate = useTranslate();
  const canAccessEdit = useCanAccess({ action: "update", resource: "supply" });
  const canAccessDelete = useCanAccess({
    action: "delete",
    resource: "supply",
  });
  return (
    <List
      {...props}
      id="supplies"
      title={translate("resources.supplies.page.list")}
      bulkActionButtons={canAccessDelete}
      actions={<ListActions />}
      filters={<ListFilter />}
      sort={{ field: "id", order: "ASC" }}
      perPage={25}
      pagination={<Pagination />}
    >
      <Datagrid rowClick={canAccessEdit ? "edit" : undefined}>
        <TextField source="name" />
        <TextField source="materialCode" />
        <TextField source="system" />
        <TextField source="deviceName" />
        <TextField source="code" />

        <TextFieldMore source="info" length={100} />
        <TextField source="madein" />
        <TextField source="count" />
        <TextField source="unit" />
        <TextField source="note" />
      </Datagrid>
    </List>
  );
};

export default StudentList;
