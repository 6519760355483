import keyBy from "lodash/keyBy";
import keys from "lodash/keys";
import mapValues from "lodash/mapValues";
import omit from "lodash/omit";
import * as reactAdmin from "react-admin";
import crudProvider from "ra-nest-crud";
import { fetchUtils } from "react-admin";

export const postApi = (path: string, body: any = {}) => {
  const token = localStorage.getItem("user_token");

  return fetch(process.env.REACT_APP_API_BASE_URL + path, {
    body: JSON.stringify(body),
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then((record) => record.json());
};
export const httpClient = async (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: "application/json",
    });
  }
  const token = localStorage.getItem("user_token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export const customHttpClient = (path: string, options: any = {}) => {
  const url = process.env.REACT_APP_API_BASE_URL + path;
  return httpClient(url, options);
};
const dataProvider = crudProvider(
  process.env.REACT_APP_API_BASE_URL + "/crud",
  httpClient
);

const customProvider = async (
  action: string,
  resource: string,
  params: any
) => {
  if (action === reactAdmin.UPDATE || action === reactAdmin.CREATE) {
    if (
      ![
        "statusbills",
        "eworktickets",
        "cmdworktickets",
        "hworktickets",
        "bills",
      ].includes(resource)
    ) {
      // fallback to the default implementation
      return defaultDataProvider(action, resource, params);
    }
    // Freshly dropped pictures are File objects and must be converted to base64 strings
    let newParams = await handleImageData(params);

    if (action === reactAdmin.CREATE) {
      newParams = await handleCreateStatusBills(newParams);
    }

    return defaultDataProvider(action, resource, newParams);
  }

  if(resource === 'incurred'){
    return defaultDataProvider(action, 'maintenance', params);
  }

  return defaultDataProvider(action, resource, params);
};

export enum BillStatusType {
  APPROVED_BY_STAFF = "APPROVED_BY_STAFF",
  APPROVED_BY_LEADER_STAFF = "APPROVED_BY_LEADER_STAFF",
  APPROVED_BY_KT = "APPROVED_BY_KT",
  APPROVED_BY_LEADER_KT = "APPROVED_BY_LEADER_KT",
  APPROVED_BY_DIRECTOR = "APPROVED_BY_DIRECTOR",
  BUY_SUCCESS = "BUY_SUCCESS",
}

export const BillStatusTypeKey = Object.keys(BillStatusType);

const handleCreateStatusBills = async (params: any) => {
  const isApprovalAction = !!params.data.approvalDepartmentId;
  const currentStatusIndex = BillStatusTypeKey.findIndex(
    (val) => val === params.data.status
  );

  if (currentStatusIndex > -1) {
    return {
      ...params,
      data: {
        ...params.data,
        ...{
          status:
            BillStatusTypeKey[
              isApprovalAction ? currentStatusIndex + 1 : currentStatusIndex - 1
            ],
        },
      },
    };
  }
  return params;
};

const handleSingleImage = async (params: any) => {
  const isNewPicture = typeof params.data.imageUrl === "object";
  if (isNewPicture) {
    const imageUrl = await convertFileToBase64(params.data.imageUrl);
    return {
      ...params,
      data: {
        ...params.data,
        ...{ imageUrl },
      },
    };
  }
  return params;
};

const handleMultiFiles = async (params: any) => {
  const files = await Promise.all(
    (params.data.files || []).map(async (meta: any) => {
      if (!!meta.rawFile) {
        const base64 = await convertFileToBase64(meta);
        return { data: base64, name: meta.rawFile.name };
      }
      return meta;
    })
  );

  return {
    ...params,
    data: {
      ...params.data,
      ...{ files },
    },
  };
};

const handleSingleAttachment = async (params: any) => {
  const isNewPicture =
    typeof params.data.attachment === "object" && params.data.attachment.src;
  if (isNewPicture) {
    const fileUrl = await convertFileToBase64(params.data.attachment);
    return {
      ...params,
      data: {
        ...params.data,
        ...{
          attachment: JSON.stringify({
            filename: params.data.attachment.rawFile.path,
            base64: fileUrl,
          }),
        },
      },
    };
  }
  return params;
};

const handleMultipleImage = async (params: any, field = "images") => {
  const images = await Promise.all(
    (params.data[field] || []).map(async (meta: any) => {
      if (!!meta.rawFile) {
        const base64 = await convertFileToBase64(meta);
        return { data: base64, name: meta.rawFile.name };
      }
      return meta;
    })
  );

  return {
    ...params,
    data: {
      ...params.data,
      ...{ [field]: images },
    },
  };
};

const handleMultipleVocabImage = async (params: any) => {
  const vocabMetas = await Promise.all(
    params.data.vocabMetas.map(async (vocab: any) => {
      const isNewPicture = !!vocab.thumb.rawFile;
      if (isNewPicture) {
        const base64 = await convertFileToBase64(vocab.thumb);
        return { ...vocab, thumb: base64 };
      }
      return vocab;
    })
  );

  return {
    ...params,
    data: {
      ...params.data,
      ...{ vocabMetas },
    },
  };
};

const handleImageData = async (params: any) => {
  let _params = params;

  if (_params.data && _params.data.hasOwnProperty("attachment")) {
    _params = await handleSingleAttachment(params);
  }

  if (_params.data && _params.data.hasOwnProperty("files")) {
    _params = await handleMultiFiles(params);
  }

  if (_params.data && _params.data.hasOwnProperty("imageUrl")) {
    _params = await handleSingleImage(params);
  }

  if (_params.data && _params.data.hasOwnProperty("images")) {
    _params = await handleMultipleImage(_params, "images");
  }
  if (_params.data && _params.data.hasOwnProperty("image2s")) {
    _params = await handleMultipleImage(_params, "image2s");
  }

  if (_params.data && _params.data.hasOwnProperty("vocabMetas")) {
    _params = await handleMultipleVocabImage(_params);
  }

  return _params;
};

const defaultDataProvider = (action: string, resource: string, params: any) => {
  if (params.filter) {
    const keyMatchFks = keys(params.filter).filter((key) => key.includes("Id"));

    const keyMatchNestedFks = keys(params.filter).filter(
      (key) => params.filter[key] && params.filter[key].hasOwnProperty("id")
    );

    const objectMatchNested = keyMatchNestedFks.map((key) => ({
      key: `${key}.id||$eq`,
      value: params.filter[key].id,
    }));

    const objectMatch = keyMatchFks.map((key) => ({
      key: `${key}||$eq`,
      value: params.filter[key],
    }));

    const _params = {
      ...params,
      filter: {
        ...omit(params.filter, [...keyMatchFks, ...keyMatchNestedFks]),
        ...mapValues(keyBy(objectMatch, "key"), "value"),
        ...mapValues(keyBy(objectMatchNested, "key"), "value"),
      },
    };

    return dataProvider(action, resource, _params);
  } else {
    return dataProvider(action, resource, params);
  }
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
export const convertFileToBase64 = (file: any): any =>
  new Promise((resolve, reject) => {
    if (!file) {
      resolve("");
    }
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });
export default customProvider;
