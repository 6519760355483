import ReviewIcon from "@material-ui/icons/CategoryOutlined";
import List from "./List";
import Create from "./Create";
import Edit from "./Edit";
export default {
  icon: ReviewIcon,
  list: List,
  create: Create,
  edit: Edit,
  name: "maintenance",
};
