import React, { FC, memo, useEffect, useState } from "react";
import * as _ from "lodash";
import { setSidebarVisibility, useGetList } from "react-admin";
import { Card, CircularProgress } from "@material-ui/core";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import CheckIcon from "@material-ui/icons/Check";
import { useDispatch } from "react-redux";

export const Item: FC<any> = memo((props) => {
  const { record, checked } = props;
  const history = useHistory();
  const classChecked = checked ? "list_item checked" : "list_item";
  return (
    <div className={classChecked}>
      <div className="checker">{checked ? <CheckIcon /> : ""}</div>
      <div className="label">
        <span
          onClick={() => {
            history.push(`/bills/${record.id}`);
          }}
        >
          {record.billNumber}
        </span>
      </div>
    </div>
  );
});

export const ListingInEdit = () => {
  const match = useRouteMatch<any>();
  const id = match.params.id;

  const [choosenId, setChoosenId] = useState(id);

  useEffect(() => {
    setChoosenId(id);
  }, [id]);
  const { data, loading, ids } = useGetList(
    "bills",
    { page: 1, perPage: 30 },
    { field: "createdAt", order: "DESC" },
    {}
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [setSidebarVisibility]);

  if (loading) return <CircularProgress />;
  return (
    <Card>
      <div className="bill_label">Danh sách phiếu</div>
      <div className="bill_list">
        {_.map(ids, (id) => (
          <Item record={data![id]} checked={choosenId == id} />
        ))}
      </div>
    </Card>
  );
};
