import { CSSProperties } from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import React, { FC, useCallback, useEffect, useState } from "react";
import { BillType } from "../bills/Create";
import { postApi } from "../dataProvider";
import { BillChart } from "./BillChart";
import { BillList } from "./BillList";
import { SupplyList } from "./SupplyList";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em" },
  billList: { marginTop: "12px" },
};

const Dashboard: FC<any> = () => {
  const [suCo, setSuCo] = useState<any>({});
  const [dotXuat, setDotXuat] = useState<any>({});
  const [tieuHao, setTieuHao] = useState<any>({});
  const fetchCounts = useCallback(async (type, setter) => {
    const record = await postApi("/api/bills/statistic", {
      start: "string",
      end: "string",
      billType: type,
    });
    setter(record.data);
  }, []);
  useEffect(() => {
    fetchCounts(BillType.SUA_CHUA_SU_CO, setSuCo);
    fetchCounts(BillType.SUA_CHUA_DOT_XUAT, setDotXuat);
    fetchCounts(BillType.VAT_TU_TIEU_HAO, setTieuHao);
  }, [fetchCounts]);
  const dIdSuCo = _.get(suCo, "data", []);
  const dIdDotXuat = _.get(dotXuat, "data", []);
  const dIdTieuHao = _.get(tieuHao, "data", []);
  const colors = ["red", "orange", "yellow", "green", "blue", "purple", "pink"];
  const dIdColors = _([...dIdDotXuat, ...dIdSuCo, ...dIdTieuHao])
    .map("dId")
    .union()
    .map((dId, index) => ({
      dId,
      color: colors[index % colors.length],
    }))
    .value();

  const colorMapping = _.groupBy(dIdColors, "dId");
  return (
    <div className="wrapper">
      <div style={styles.flexColumn as CSSProperties}>
        <fieldset>
          <legend className="dashboard_legend">
            Tình hình thực hiện phiếu đề xuất
          </legend>
          <div style={styles.flex}>
            <BillChart
              results={suCo}
              title="Đề xuất sự cố"
              colorMapping={colorMapping}
              type="bar"
            />
            <BillChart
              results={dotXuat}
              title="Đề xuất đột xuất"
              colorMapping={colorMapping}
              type="line"
            />
            <BillChart
              results={tieuHao}
              title="Đề xuất vât tư"
              colorMapping={colorMapping}
              type="line"
            />
          </div>
        </fieldset>
      </div>
      <div style={styles.billList as CSSProperties}>
        <BillList />
      </div>

      <div style={styles.billList as CSSProperties}>
        <SupplyList />
      </div>
    </div>
  );
};

export default Dashboard;
