import DateTimeField from "../components/DateTimeField";
import ListActions from "./ListActions";
import ListFilter from "./ListFilters";
import React, { FC } from "react";
import {
  Datagrid,
  List,
  ReferenceField,
  TextField,
  useTranslate,
} from "react-admin";
import ChipField from "../components/ChipField";

const StudentList: FC<any> = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate("resources.departments.page.list")}
      actions={<ListActions />}
      filters={<ListFilter />}
      sort={{ field: "id", order: "ASC" }}
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField
          source="parentId"
          reference="departments"
          resource="departments"
          label={translate("resources.departments.fields.parentId")}
        >
          <ChipField source="name" size="medium" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default StudentList;
