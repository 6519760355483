import ReviewIcon from "@material-ui/icons/PostAddOutlined";
import React from "react";
import "./component.scss";

export default {
  icon: ReviewIcon,
  list: React.lazy(() => import("./List")),
  create: React.lazy(() => import("./Create")),
  edit: React.lazy(() => import("./Edit")),
  name: "eworktickets",
};
