import { useCallback, useEffect, useReducer } from "react";
import { has } from "lodash";
import { customHttpClient } from "../dataProvider";

interface StateT<T> {
  loading: boolean;
  error: Error | null;
  data: T | null;
}

interface Action {
  type: string;
  payload?: any;
}

const initState = (_default: any): StateT<any> => ({
  loading: false,
  error: null,
  data: _default,
});

const reducer = (state: StateT<any>, action: Action) => {
  switch (action.type) {
    case "startFetch":
      return { ...state, loading: true };
    case "fetchSuccess":
      return { ...state, loading: false, data: action.payload };
    case "fetchError":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const useMutate = (
  promise: any,
  when: any[] = [],
  options: {
    valueDefault?: any;
    inputIsSnake?: boolean;
    callback?: (err: any, data: any) => void;
  } = {}
): [StateT<any>, (formData: any) => void] => {
  const {
    valueDefault = [],
    inputIsSnake = false,
    callback = (err = null, data: any = null) => {},
  } = options;

  const [{ data, error, loading }, dispatch] = useReducer(
    reducer,
    initState(valueDefault)
  );

  let mount = true;
  useEffect(() => {
    mount = true;
    return () => {
      mount = false;
    };
  }, []);

  const mutate = useCallback(
    (formData, inlineCallBack = () => {}) => {
      const _form = formData;
      const fetchData = async () => {
        dispatch({ type: "startFetch" });

        try {
          const response = await promise(_form);

          const { data: _data } = response.json;

          if (has(_data, "data")) {
            if (!mount) {
              return;
            }
            const tranform = _data.data;
            dispatch({
              type: "fetchSuccess",
              payload: tranform,
            });
            callback(null, tranform);
            inlineCallBack(null, tranform);
            return;
          }
          const tranform = _data;

          if (!mount) {
            return;
          }
          callback(null, tranform);
          inlineCallBack(null, tranform);
          dispatch({ type: "fetchSuccess", payload: tranform });
        } catch (err) {
          if (!mount) {
            return;
          }

          dispatch({ type: "fetchError", payload: err });
          callback(err, null);
          inlineCallBack(err, null);
        }
      };
      fetchData();
    },
    [...when, callback]
  );

  return [{ error, data, loading }, mutate];
};

export const useMutateAPI = (
  resource: string,
  when: any[] = [],
  callback = (err: Error, data: any) => {}
) => {
  return useMutate(
    (formData: any) => {
      return customHttpClient(`/api/${resource}`, {
        method: "POST",
        body: JSON.stringify(formData),
      });
    },
    when,
    { valueDefault: null, callback }
  );
};

export const useMutateCRUD = (
  resource: string,
  when: any[] = [],
  callback = (err: Error, data: any) => {}
) => {
  return useMutate(
    (formData: any) => {
      return customHttpClient(`/crud/${resource}`, {
        method: "POST",
        body: JSON.stringify(formData),
      });
    },
    when,
    { valueDefault: null, callback }
  );
};

export const useMutateUpdateCRUD = (
  resource: string,
  when: any[] = [],
  callback = (err: Error, data: any) => {}
) => {
  return useMutate(
    (formData: any) => {
      return customHttpClient(`/crud/${resource}/${formData.id}`, {
        method: "PATCH",
        body: JSON.stringify(formData),
      });
    },
    when,
    { valueDefault: null, callback }
  );
};
export const useMutateDeleteCRUD = (
  resource: string,
  when: any[] = [],
  callback = (err: Error, data: any) => {}
) => {
  return useMutate(
    (formData: any) => {
      return customHttpClient(`/crud/${resource}/${formData.id}`, {
        method: "DELETE",
        body: JSON.stringify(formData),
      });
    },
    when,
    { valueDefault: null, callback }
  );
};

export default useMutate;
