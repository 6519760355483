import ListActions from "./ListActions";
import ListFilter from "./ListFilters";
import React, { FC } from "react";
import {
  Datagrid,
  List,
  TextField,
  useTranslate,
  ReferenceField,
} from "react-admin";
import ChipField from "../components/ChipField";
import { isAdmin, useMe } from "../helpers";

const StudentList: FC<any> = (props) => {
  const translate = useTranslate();
  const { loading, me } = useMe();
  return (
    <List
      {...props}
      title={translate("resources.users.page.list")}
      actions={isAdmin(me) ? <ListActions /> : false}
      filters={<ListFilter />}
      sort={{ field: "id", order: "ASC" }}
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <TextField source="username" />
        <TextField source="fullname" />
        <ReferenceField
          source="positionId"
          reference="positions"
          label={translate("resources.positions.fields.name")}
        >
          <ChipField source="name" size="medium" />
        </ReferenceField>

        <ReferenceField
          source="positionId"
          reference="positions"
          label={translate("resources.departments.fields.name")}
        >
          <ReferenceField source="departmentId" reference="departments">
            <ChipField source="name" size="medium" />
          </ReferenceField>
        </ReferenceField>
        <TextField source="email" />

        <TextField source="telephone" />
      </Datagrid>
    </List>
  );
};

export default StudentList;
