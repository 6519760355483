import React, { FC } from 'react';
import SexInput from '../components/SexInput';
import { Filter, TextInput } from 'react-admin';

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <TextInput source="id" />
    <TextInput source="fullname" />
    <TextInput source="dob" type="date" />
    <TextInput source="email" />
    <SexInput source="sex" />
    <TextInput source="province" />
    <TextInput source="address" />
  </Filter>
);

export default ListFilter;
