import React, { FC} from 'react';
import {AutocompleteInput, DateInput, Filter, ReferenceInput, SelectInput, TextInput, useTranslate} from 'react-admin';
import * as _ from "lodash";
import {allHeThong, allHeThongObject, loai_cong_viec, MaintenanceStatus, to_may, toObject} from "./Types";

const ListFilter: FC<any> = (props) => {
  return (
  <Filter {...props}>
      <TextInput
        variant="outlined"
        alwaysOn
        source="ma_kks"
        label="Mã KKS"
      />
      <DateInput source="createdAt||$gte" variant="outlined" label="Từ ngày" alwaysOn  />
      <DateInput source="createdAt||$lte" variant="outlined" label="Tới ngày" alwaysOn />
      <ReferenceInput
        label="Đơn vị thực hiện"
        source="departmentId"
        reference="departments"
        resource="maintenance"
        variant="outlined"
        alwaysOn
        filterToQuery={(searchText: any) => {
            return _.isEmpty(_.trim(searchText))
                ? null
                : {name: searchText};
        }}>
        <AutocompleteInput
            optionText="name"
            optionValue="id"
            fullWidth
            variant="outlined"
        />
      </ReferenceInput>
      <SelectInput
          source="to"
          label={"Tổ"}
          resource="maintenance"
          fullWidth
          alwaysOn
          choices={toObject}
          variant={"outlined"}
      />
      <SelectInput
          source="he_thong"
          label={"Hệ thống"}
          resource="maintenance"
          fullWidth
          alwaysOn
          choices={allHeThong}
          variant={"outlined"}
      />
      <SelectInput
          source="to_may"
          label={"Tổ máy"}
          resource="maintenance"
          fullWidth
          alwaysOn
          choices={to_may}
          variant={"outlined"}
      />
  </Filter>
)};

export default ListFilter;
