import React from "react";
import { Route } from "react-router-dom";
import { Dashboard } from "./dashboard";
const Configuration = React.lazy(() => import("./configuration/Configuration"));
const BillApproval = React.lazy(() => import("./bills/Approval"));
const GrantPermission = React.lazy(() => import("./permissions/grant"));
const HistoryBill = React.lazy(() => import("./bills/HistoryBill"));
const BillClone = React.lazy(() => import("./bills/SimpleCreate"));
const SearchSupplies = React.lazy(() => import("./supplies/SearchSupplies"));
const WorkticketDashboard = React.lazy(() => import("./worktickets/Dashboard"));

export default [
  <Route exact path="/configuration" render={() => <Configuration />} />,
  <Route
    exact
    path="/bills/:id/approval"
    render={(props) => <BillApproval {...props} />}
  />,
  <Route
    exact
    path="/permissions/:userId"
    render={(props) => <GrantPermission {...props} />}
  />,
  <Route
    exact
    path="/billsearchs"
    render={(props) => <SearchSupplies {...props} />}
  />,

  <Route
    exact
    path="/bills/:id/history"
    render={(props) => <HistoryBill {...props} />}
  />,

  <Route
    exact
    path="/bills/create-by-group"
    render={(props) => (
      <BillClone {...props} isGroup basePath="/bills" resource="bills" />
    )}
  />,
  <Route exact path="/bills-board" render={(props) => <Dashboard />} />,
  <Route
    exact
    path="/worktickets-board"
    render={(props) => <WorkticketDashboard />}
  />,
];
