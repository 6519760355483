import { AuthProvider } from "ra-core";

const callApiCheckMe = async (token: string) => {
  return fetch(process.env.REACT_APP_API_BASE_URL + "/api/me", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then(async (res) => {
    if (res.ok) {
      return res.json();
    }
    localStorage.removeItem("user_token");
    localStorage.removeItem("permissions");
    throw new Error("Đăng nhập lại");
  });
};

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const data = await fetch(
      process.env.REACT_APP_API_BASE_URL + "/api/login",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
        }),
      }
    ).then((res) => res.json());
    if (data.data && data.data.token) {
      localStorage.setItem("user_token", data.data.token);
      return Promise.resolve();
    }
    return Promise.reject();
  },
  logout: () => {
    localStorage.removeItem("user_token");
    localStorage.removeItem("permissions");
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: async () => {
    const token = localStorage.getItem("user_token");
    const permissions = localStorage.getItem("permissions");
    if (!token) Promise.reject();
    try {
      if(!permissions){
        const data = await callApiCheckMe(token!);
        localStorage.setItem("permissions", JSON.stringify(data.data));
        return Promise.resolve(data);
      }
    } catch (error) {
      return Promise.reject();
    }
  },
  getPermissions: async () => {
    const token = localStorage.getItem("user_token");
    if (!token) return Promise.reject();
    const pers = localStorage.getItem("permissions");
    if (!pers) {
      try {
        const data = await callApiCheckMe(token!);
        localStorage.setItem("permissions", JSON.stringify(data.data));
        return Promise.resolve(data.data);
      } catch (error) {
        return Promise.reject();
      }
    }
    return Promise.resolve(JSON.parse(pers));
  },
};

export default authProvider;
