import * as _ from "lodash";
import { Button, CircularProgress, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import {
  useMutation,
  useNotify,
  useQuery,
  useRedirect,
  useTranslate,
} from "react-admin";

export default function PopupChangePass(props: any) {
  const { showChangePass, setShowChangePass, userId } = props;
  const [password, setPassword] = useState("");

  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const [changePassMutation] = useMutation(
    {
      type: "update",
      resource: "users",
      payload: {
        id: userId,
        data: { password },
      },
    },
    {
      onSuccess: () => {
        setShowChangePass(false);
        setPassword("");
        notify("Đổi mật khẩu thành công", "info");
      },
      onFailure: (error: any) => {
        setShowChangePass(false);
        setPassword("");
        notify(`Đổi mật khẩu thất bại ${error.message}`, "warning");
      },
    }
  );

  return (
    <Dialog
      open={showChangePass}
      maxWidth="lg"
      onClose={() => setShowChangePass(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Đổi mật khẩu</DialogTitle>
      <DialogContent>
        <DialogContentText>Bạn có chắc chắn đổi mật khẩu?</DialogContentText>

        <TextField
          value={password}
          label={"Mật khẩu"}
          variant="standard"
          onChange={(e) => {
            if (e.target.value) {
              setPassword(e.target.value);
            }
          }}
          error={!password}
          helperText={!password ? "Không được để trống" : ""}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowChangePass(false)} color="primary">
          Hủy bỏ
        </Button>
        <Button
          onClick={() => {
            if (!password) {
              return;
            }
            changePassMutation();
          }}
          color="primary"
        >
          Lưu
        </Button>
      </DialogActions>
    </Dialog>
  );
}
