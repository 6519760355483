import { CircularProgress } from "@material-ui/core";
import { PlusOneOutlined } from "@material-ui/icons";
import _ from "lodash";
import React, { FC, useCallback, useState } from "react";
import "./upload.css";

interface IProps {
  onChange?: any;
  value?: string;
  disable?: boolean;
}

const UploadPDF: FC<IProps> = (props) => {
  const { disable } = props;
  const [loading, setLoading] = useState<boolean>();
  const [imageUrl, setImageUrl] = useState(props.value);
  const uploadButton = (
    <div className="block-upload-file">
      {loading ? <CircularProgress /> : <PlusOneOutlined />}
      <div style={{ marginTop: 4 }}>Tải tệp lên</div>
    </div>
  );
  const hostname = `${
    process.env.REACT_APP_API_BASE_URL || "http://localhost:4004"
  }`;
  const uploadImage = useCallback((file) => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("file", file);

    const requestOptions = {
      method: "POST",
      body: formdata,
    };

    fetch(`${hostname}/api/upload/file`, requestOptions)
      .then((response) => {
        setLoading(false);
        return response.json();
      })
      .then((success) => {
        const image = _.get(success, "data.url");
        setImageUrl(image);
        props.onChange(image);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  if (disable) {
    if (imageUrl) {
      return (
        <label className="block-upload-file">
          <a href={`${hostname}${imageUrl}`} target="_blank">
            Tải đính kèm
          </a>
        </label>
      );
    } else {
      return <></>;
    }
  }

  return (
    <label className="block-upload-file">
      <input
        hidden
        onChange={(e) => {
          if (e.target.files) {
            uploadImage(e.target.files[0]);
          }
        }}
        type="file"
        accept="application/pdf"
      />
      {imageUrl ? (
        <span className="block-upload-file-preview">Tải tệp lên</span>
      ) : (
        uploadButton
      )}
    </label>
  );
};

export default UploadPDF;
