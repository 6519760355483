import { Box, Card, CardContent, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import {
  Create,
  DateInput,
  FormWithRedirect,
  PasswordInput,
  required,
  TextInput,
  Toolbar,
  SelectInput,
  ReferenceInput,
  SaveButton,
  Button,
  DeleteButton,
  BooleanInput,
} from "react-admin";
import DoneIcon from "@material-ui/icons/Done";
import { isAdmin, useMe } from "../helpers";
import ListActions from "./ListActions";
import PopupChangePass from "./PopupChangePass";

const MajorCreate = (props: any) => {
  const { loading, me } = useMe();
  return (
    <Create
      {...props}
      undoable={false}
      actions={isAdmin(me) ? <ListActions /> : false}
    >
      <Form />
    </Create>
  );
};

export const Form = (props: any) => {
  const { isEdit } = props;
  const { loading, me } = useMe();

  const [showChangePass, setShowChangePass] = useState(false);

  if (loading) return <CircularProgress />;
  const meIsAdmin = isAdmin(me);
  const canAccessDelete = meIsAdmin;
  const isMe = me.id === props.record.id;

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <>
          <PopupChangePass
            showChangePass={showChangePass}
            setShowChangePass={setShowChangePass}
            userId={props.record.id}
          />
          <Card>
            <form>
              <CardContent>
                <Box
                  display={{ md: "block", lg: "flex" }}
                  style={{ alignItems: "center" }}
                >
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      source="email"
                      resource="users"
                      fullWidth
                      validate={requiredValidate}
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      source="username"
                      resource="users"
                      fullWidth
                      validate={requiredValidate}
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    {!isEdit && (
                      <PasswordInput
                        source="password"
                        validate={requiredValidate}
                        resource="users"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  display={{ md: "block", lg: "flex" }}
                  style={{ alignItems: "center" }}
                >
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      source="emailGroup"
                      resource="users"
                      fullWidth
                      variant="outlined"
                    />
                  </Box>

                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      source="signAlias"
                      resource="users"
                      fullWidth
                      variant="outlined"
                      label="Alias"
                      translate="no"
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <BooleanInput
                      source="isContractor"
                      resource="users"
                      fullWidth
                      label="Là nhà thầu"
                      translate="no"
                    />
                  </Box>
                </Box>
                <Box
                  display={{ md: "block", lg: "flex" }}
                  style={{ alignItems: "center" }}
                >
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      source="telephone"
                      resource="users"
                      fullWidth
                      variant="outlined"
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      source="address"
                      resource="users"
                      fullWidth
                      variant="outlined"
                    />
                  </Box>

                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <DateInput
                      source="dob"
                      type="date"
                      resource="users"
                      fullWidth
                      variant="outlined"
                    />
                  </Box>
                </Box>
                <Box
                  display={{ md: "block", lg: "flex" }}
                  style={{ alignItems: "center" }}
                >
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <ReferenceInput
                      source="positionId"
                      reference="positions"
                      resource="users"
                      perPage={10000}
                    >
                      <SelectInput
                        optionText={(record: any) =>
                          `${record.name} - ${record.department.name}`
                        }
                        fullWidth
                        variant="outlined"
                      />
                    </ReferenceInput>Đổi mật khẩu

                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      source="scope"
                      resource="users"
                      fullWidth
                      variant="outlined"
                      validate={requiredValidate}
                    />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      source="fullname"
                      resource="users"
                      fullWidth
                      variant="outlined"
                      validate={requiredValidate}
                    />
                  </Box>
                </Box>
              </CardContent>
              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                resource="users"
                undoable={false}
              >
                <div>
                  <SaveButton
                    saving={formProps.saving}
                    redirect={isEdit ? "edit" : "list"}
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect
                    }
                  />

                  {(isMe || meIsAdmin) && (
                    <>
                      &nbsp;
                      <Button
                        color="primary"
                        component="button"
                        variant="contained"
                        size="medium"
                        label="Đổi mật khẩu"
                        onClick={() => {
                          setShowChangePass(true);
                        }}
                      >
                        <DoneIcon style={{ fontSize: "20" }} />
                      </Button>
                    </>
                  )}
                </div>
                {canAccessDelete && <DeleteButton />}
              </Toolbar>
            </form>
          </Card>
        </>
      )}
    />
  );
};

const requiredValidate = [required()];

export default MajorCreate;
