import React from "react";
import {
  Create,
} from "react-admin";
import ListActions from "./ListActions";
import {Form} from "./Form";

const MajorCreate = (props: any) => {
  return (
      <Create {...props} undoable={false} actions={<ListActions />}>
        <Form />
      </Create>
  );
};

export default MajorCreate;
