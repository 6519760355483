import React, { FC } from "react";
import { FieldProps } from "../types";
import { useTranslate, SelectInput } from "react-admin";

interface Props extends FieldProps<any> {
  className?: string;
}

const SupplyStatusInput: FC<Props> = (props) => {
  const translate = useTranslate();
  return (
    <SelectInput
      {...props}
      choices={[
        {
          id: 1,
          name: translate("resources.supplies.fields.inStock"),
        },
        {
          id: 2,
          name: translate("resources.supplies.fields.outOfStock"),
        },
      ]}
    />
  );
};

export default SupplyStatusInput;
