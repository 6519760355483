import * as _ from "lodash";
import { Button, CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { FC } from "react";
import { useGetOne } from "react-admin";
import { BillGroupType } from "./Edit";
import { Link } from "react-router-dom";
import { BuyingStatusType } from "../packages/TableSupplyChoosen";

const getBillPx = (data: any) => {
  const current = _.get(data, "currentBill", undefined);
  if (!current) return undefined;

  if (current.groupType === BillGroupType.DEFAULT) return current;
  if (current.groupType === BillGroupType.CHILD) return current;
  if (current.groupType === BillGroupType.PARENT) {
    return _.get(data, "prevBill", undefined);
  }
  return undefined;
};

const getBillKt = (data: any) => {
  const current = _.get(data, "currentBill", undefined);
  if (!current) return undefined;

  if (current.groupType === BillGroupType.DEFAULT_KT) return current;
  if (current.groupType === BillGroupType.CHILD) return undefined;
  if (current.groupType === BillGroupType.PARENT) {
    return current;
  }
  return undefined;
};

const Content: FC<any> = (props) => {
  const { id, type } = props;
  const { data, error, loading } = useGetOne(`supplyrelativebills/${type}`, id);
  if (loading) {
    return <CircularProgress />;
  }

  if (!_.isEmpty(error)) {
    return <div>Có lỗi hệ thống {JSON.stringify(error)}</div>;
  }

  const { packageSupply }: any = data || {};

  const pdxPx = getBillPx(data);
  const pdxKt = getBillKt(data);

  return (
    <>
      <div className="relative-item">
        <div className="item-label">PĐX phân xưởng</div>
        <div className="item-value">
          {pdxPx ? (
            <Link to={`/bills/${pdxPx.id}`} target="_blank">
              {pdxPx.billNumber}
            </Link>
          ) : (
            "Không có thông tin"
          )}
        </div>
      </div>

      <div className="relative-item">
        <div className="item-label">PĐX kỹ thuật</div>
        <div className="item-value">
          {pdxKt ? (
            <Link to={`/bills/${pdxKt.id}`} target="_blank">
              {pdxKt.billNumber}
            </Link>
          ) : (
            "Không có thông tin"
          )}
        </div>
      </div>

      <div className="relative-item">
        <div className="item-label">Gói thầu</div>
        <div className="item-value">
          {!_.isEmpty(packageSupply) ? (
            <Link to={`/packages/${packageSupply.package.id}`} target="_blank">
              {packageSupply.package.packageName}
            </Link>
          ) : (
            "Không có thông tin"
          )}
        </div>
      </div>
      <div className="relative-item">
        <div className="item-label">Tiến độ</div>
        <div className="item-value">
          {!_.isEmpty(packageSupply)
            ? (BuyingStatusType as any)[packageSupply.status] ||
              packageSupply.status
            : "Không có thông tin"}
        </div>
      </div>
    </>
  );
};
export default function DialogSupplyMoreDetail(props: any) {
  const { showMore = false, setShowMore, id, type } = props;

  return (
    <Dialog
      id="dialog-supply-more"
      open={showMore}
      maxWidth="lg"
      onClose={() => setShowMore(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Thông tin phiếu đề xuất liên quan
      </DialogTitle>
      <DialogContent>{showMore && <Content id={id} type={type} />}</DialogContent>
      <DialogActions>
        <Button onClick={() => setShowMore(false)} color="primary">
          Hủy bỏ
        </Button>
      </DialogActions>
    </Dialog>
  );
}
