import * as _ from "lodash";
import { customHttpClient } from "../dataProvider";

export interface IOption {
  page?: string;
  top?: string;
  left?: string;
  width?: string;
  height?: string;
}

export const createFormDataSign = (
  user: any,
  file: File,
  options: IOption = {}
) => {
  const defautOptions: IOption = {
    page: "4",
    top: "300",
    left: "0",
    width: "100",
    height: "100",
  };
  const option = _.assign(defautOptions, options);
  let formData = new FormData();
  formData.append("file", file);
  formData.append("page", option.page!);
  formData.append("top", option.top!);
  formData.append("left", option.left!);
  formData.append("width", option.width!);
  formData.append("height", option.height!);
  formData.append("alias", _.get(user, "signAlias", ""));
  return formData;
};

export const createFormDataSignWithAlias = (
    alias: string = "",
    file: File,
    options: IOption = {}
) => {
  const defautOptions: IOption = {
    page: "4",
    top: "300",
    left: "0",
    width: "100",
    height: "100",
  };
  const option = _.assign(defautOptions, options);
  let formData = new FormData();
  formData.append("file", file);
  formData.append("page", option.page!);
  formData.append("top", option.top!);
  formData.append("left", option.left!);
  formData.append("width", option.width!);
  formData.append("height", option.height!);
  formData.append("alias", alias);
  return formData;
};

export const uploadTicketServer = (
  model: string,
  ticketId: string,
  blob: Blob,
  type?: string
) => {
  const filename = `${model}_${ticketId}`;
  const file = new File([blob], filename);
  let formData = new FormData();
  formData.append("file", file);
  formData.append("model", model);
  type && formData.append("type", type);
  return customHttpClient("/api/upload/ticket", {
    header: { "Content-Type": "multipart/form-data" },
    body: formData,
    method: "post",
  });
};

export const uploadETicketServer = (
  filename: string,
  blob: Blob,
  type?: string
) => {
  const file = new File([blob], filename);
  let formData = new FormData();
  formData.append("file", file);
  formData.append("model", "eworkticket");
  type && formData.append("type", type);
  return customHttpClient("/api/upload/ticket", {
    header: { "Content-Type": "multipart/form-data" },
    body: formData,
    method: "post",
  });
};

export const uploadCMDTicketServer = (
  filename: string,
  blob: Blob,
  type?: string
) => {
  const model = "cmdworkticket";
  const file = new File([blob], filename);
  let formData = new FormData();
  formData.append("file", file);
  formData.append("model", model);
  type && formData.append("type", type);
  return customHttpClient("/api/upload/ticket", {
    header: { "Content-Type": "multipart/form-data" },
    body: formData,
    method: "post",
  });
};

export const uploadHTicketServer = (
  filename: string,
  blob: Blob,
  type?: string
) => {
  const file = new File([blob], filename);
  let formData = new FormData();
  formData.append("file", file);
  formData.append("model", "hworkticket");
  type && formData.append("type", type);
  return customHttpClient("/api/upload/ticket", {
    header: { "Content-Type": "multipart/form-data" },
    body: formData,
    method: "post",
  });
};

export const generateTicketFinal = (ticketId: number, model: string) => {
  return customHttpClient("/api/generate/ticket", {
    header: { "Content-Type": "multipart/form-data" },
    body: JSON.stringify({ ticketId, model }),
    method: "post",
  });
};

export const generateETicketFinal = (ticketId: number, model: string) => {
  return customHttpClient("/api/generate/eticket", {
    header: { "Content-Type": "multipart/form-data" },
    body: JSON.stringify({ ticketId, model }),
    method: "post",
  });
};

export const generateHTicketFinal = (ticketId: number, model: string) => {
  return customHttpClient("/api/generate/hticket", {
    header: { "Content-Type": "multipart/form-data" },
    body: JSON.stringify({ ticketId, model }),
    method: "post",
  });
};

export const downloadFromUrl = (url: string) => {
  return fetch(url).then((res) => res.blob());
};
