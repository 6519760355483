import isEmpty from "lodash/isEmpty";
import trim from "lodash/trim";
import ListActions from "./ListActions";
import React from "react";
import { Box, Card, CardContent } from "@material-ui/core";
import {
  AutocompleteInput,
  Create,
  FormWithRedirect,
  ReferenceInput,
  required,
  TextInput,
  Toolbar,
  BooleanInput,
  SaveButton,
} from "react-admin";

const MajorCreate = (props: any) => {
  return (
    <Create {...props} undoable={false} actions={<ListActions />}>
      <Form />
    </Create>
  );
};

export const Form = (props: any) => {
  const { isEdit } = props;
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box
                display={{ md: "block", lg: "flex" }}
                style={{ alignItems: "center" }}>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <ReferenceInput
                    source="departmentId"
                    reference="departments"
                    resource="positions"
                    filterToQuery={(searchText: any) => {
                      return isEmpty(trim(searchText))
                        ? null
                        : { name: searchText };
                    }}>
                    <AutocompleteInput
                      optionText="name"
                      fullWidth
                      validate={requiredValidate}
                    />
                  </ReferenceInput>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="name"
                    fullWidth
                    validate={requiredValidate}
                    resource="positions"
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <BooleanInput source="isLeader" resource="positions" />
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="positions"
              undoable={false}>
              <SaveButton
                saving={formProps.saving}
                redirect={isEdit ? "edit" : "list"}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

const requiredValidate = [required()];

export default MajorCreate;
