import {
    AutocompleteInput,
    BooleanField, DateTimeInput,
    FormWithRedirect,
    ReferenceInput, required,
    SaveButton,
    SelectInput,
    TextInput,
    Toolbar, useNotify, useRefresh,
    useTranslate, useUpdate
} from "react-admin";
import {Box, Button, Card, CardContent, Chip, Input, TextField} from "@material-ui/core";
import React, {useState} from "react";
import CheckboxInput from "../components/CheckboxInput";
import * as _ from "lodash";
import DoneIcon from "@material-ui/icons/Done";
import {Button as RaButton} from "ra-ui-materialui/lib/button";
import {useHistory} from "react-router";
import {DepartmentAlias, MissingDeviceStatus, to_chuc_thuc_hien} from "./Types";
import {useMe} from "../helpers";
import {to_may_1, to_may_2, phan_chung, to_may, allHeThongObject} from "./Types";

export const Form = (props: any) => {
    const {isEdit, record} = props;
    const {me, loading} = useMe();
    const [hideDate, setHideDate] = useState(true);
    const renderHeThong = (formProps: any) => {
        const userDepartmentAlias = _.get(me,'position.department.alias');
        const {to_may} = formProps.form.getState().values;
        let result;
        switch (to_may) {
            case "to_may_1":
                result = to_may_1;
                break;
            case "to_may_2":
                result = to_may_2;
                break;
            default:
                result = phan_chung;
        }
        return result.map(i => {
            return {
                id: i.id,
                name: `${userDepartmentAlias} - ${i.name}`
            }
        });
    }
    const history = useHistory();
    const isDisableEdit = (allowAliases: string[]) => {
        return false
        if (!loading) {
            const userDepartmentAlias = me.position.department.alias;
            return !allowAliases.includes(userDepartmentAlias.toLowerCase());
        } else {
            return true;
        }
    }
    const [update] = useUpdate("missingdevices", record ? record.id : "");
    const notify = useNotify();
    const refresh = useRefresh();

    const handleFinish = (formProps: any) => {
        const {form} = formProps
        update(
            {
                type: "update",
                resource: "missingdevices",
                payload: {
                    id: record.id,
                    data: {
                        ...form.getState().values,
                        status: MissingDeviceStatus.FINISH
                    }
                },
            },
            {
                onSuccess: () => {
                    notify("Hoàn thành", "info");
                    refresh()
                },
            }
        );
    }
    return (
        <FormWithRedirect
            {...props}
            render={(formProps: any) => {
                if (!formProps.form.getState().values['status']) {
                    formProps.form.change("status", MissingDeviceStatus.OPENING)
                } else {
                    setHideDate(formProps.form.getState().values['uu_tien_hoan_thanh'])
                }
                return (
                    <Card>
                        <form>
                            <CardContent>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <SelectInput
                                            source="to_may"
                                            resource="missingdevices"
                                            fullWidth
                                            choices={to_may}
                                            defaultValue="to_may_1"
                                            variant="outlined"
                                            validate={requiredValidate}
                                            onChange={() => formProps.form.change("he_thong", undefined)}
                                        />
                                    </Box>
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <SelectInput
                                            source="he_thong"
                                            resource="missingdevices"
                                            fullWidth
                                            variant="outlined"
                                            validate={requiredValidate}
                                            choices={renderHeThong(formProps)}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <CheckboxInput
                                            resource="misssingdevices"
                                            source="uu_tien_hoan_thanh"
                                            type="checkbox"
                                            label="Ưu tiên hoàn thành sớm"
                                            disabled={
                                                false
                                            }
                                            onChange={(e: any)=>{
                                                setHideDate(e.target.checked)
                                            }}
                                        />
                                    </Box>
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <CheckboxInput
                                            resource="misssingdevices"
                                            source="gap"
                                            type="checkbox"
                                            label="Gấp"
                                            disabled={
                                                false
                                            }
                                            onChange={(e: any)=>{
                                                setHideDate(e.target.checked)
                                            }}
                                            color="orange"
                                        />
                                    </Box>
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <CheckboxInput
                                            resource="misssingdevices"
                                            source="rat_gap"
                                            type="checkbox"
                                            label="Rất gấp"
                                            disabled={
                                                false
                                            }
                                            onChange={(e: any)=>{
                                                setHideDate(e.target.checked)
                                            }}
                                            color="red"
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <DateTimeInput
                                            source="ngay_phat_hien"
                                            fullWidth
                                            resource="misssingdevices"
                                            variant="outlined"
                                            disabled={isDisableEdit([
                                                DepartmentAlias.px1,
                                                DepartmentAlias.px3,
                                                DepartmentAlias.px5,
                                            ])}
                                            label="Ngày phát hiện"
                                        />
                                    </Box>
                                    {hideDate && (
                                        <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                            <DateTimeInput
                                                source="ngay_ket_thuc"
                                                resource="misssingdevices"
                                                fullWidth
                                                variant="outlined"
                                                disabled={isDisableEdit([
                                                    DepartmentAlias.px1,
                                                    DepartmentAlias.px3,
                                                    DepartmentAlias.px5,
                                                ])}
                                                label="Ngày ưu tiên hoàn thành sớm"
                                            />
                                        </Box>
                                    )}
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <ReferenceInput
                                            label="Tiến độ vật tư"
                                            source="billId"
                                            reference="bills"
                                            resource="misssingdevices"
                                            variant="outlined"
                                            filterToQuery={(searchText: any) => {
                                                return _.isEmpty(_.trim(searchText))
                                                    ? null
                                                    : {billNumber: searchText};
                                            }}>
                                            <AutocompleteInput
                                                optionText="billNumber"
                                                fullWidth
                                                variant="outlined"
                                                disabled={isDisableEdit([
                                                    DepartmentAlias.p4,
                                                    DepartmentAlias.px2,
                                                    DepartmentAlias.px4,
                                                ])}
                                                resettable
                                            />
                                        </ReferenceInput>
                                    </Box>
                                </Box>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={5} ml={{xs: 0, sm: "0.5em"}}>
                                        <TextInput
                                            source="khiem_khuyet_thiet_bi"
                                            resource="misssingdevices"
                                            label="Quản lý khiếm khuyết thiết bị"
                                            fullWidth
                                            variant="outlined"
                                            disabled={isDisableEdit([
                                                DepartmentAlias.px1,
                                                DepartmentAlias.px3,
                                                DepartmentAlias.px5,
                                            ])}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <ReferenceInput
                                            label="Đơn vị thực hiện"
                                            source="departmentId"
                                            reference="departments"
                                            resource="misssingdevices"
                                            variant="outlined"
                                            filterToQuery={(searchText: any) => {
                                                return _.isEmpty(_.trim(searchText))
                                                    ? null
                                                    : {name: searchText};
                                            }}>
                                            <AutocompleteInput
                                                optionText="name"
                                                fullWidth
                                                variant="outlined"
                                                disabled={isDisableEdit([
                                                    DepartmentAlias.p4,
                                                ])}
                                            />
                                        </ReferenceInput>
                                    </Box>
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <SelectInput
                                            source="to_thuc_hien"
                                            label={"Tổ chức thực hiện"}
                                            resource="missingdevices"
                                            fullWidth
                                            choices={to_chuc_thuc_hien}
                                            variant="outlined"
                                            disabled={isDisableEdit([
                                                DepartmentAlias.px4,
                                                DepartmentAlias.px2,
                                            ])}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <TextInput
                                            source="y_kien_don_vi_sua_chua"
                                            label="Ý kiến đơn vị sửa chữa"
                                            multiline
                                            rows={3}
                                            resource="missingdevices"
                                            fullWidth
                                            variant="outlined"
                                            disabled={isDisableEdit([
                                                DepartmentAlias.px4,
                                                DepartmentAlias.px2,
                                            ])}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <TextInput
                                            source="y_kien_don_vi_van_hanh"
                                            label="Ý kiến đơn vị vận hành"
                                            multiline
                                            rows={3}
                                            resource="missingdevices"
                                            fullWidth
                                            variant="outlined"
                                            disabled={isDisableEdit([
                                                DepartmentAlias.px1,
                                                DepartmentAlias.px3,
                                                DepartmentAlias.px5,
                                            ])}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <TextInput
                                            source="y_kien_don_vi_ky_thuat"
                                            label="Ý kiến phòng kỹ thuật"
                                            multiline
                                            rows={3}
                                            resource="missingdevices"
                                            fullWidth
                                            variant="outlined"
                                            disabled={isDisableEdit([
                                                DepartmentAlias.p4,
                                            ])}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <TextInput
                                            source="y_kien_don_vi_ATMT"
                                            label="Ý kiến phòng AT&MT"
                                            multiline
                                            rows={3}
                                            resource="missingdevices"
                                            fullWidth
                                            variant="outlined"
                                            disabled={isDisableEdit([
                                                DepartmentAlias.p3,
                                            ])}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <TextInput
                                            source="ket_luan"
                                            label="Kết luận"
                                            multiline
                                            rows={3}
                                            resource="missingdevices"
                                            fullWidth
                                            variant="outlined"
                                            disabled={isDisableEdit([
                                                DepartmentAlias.px1,
                                                DepartmentAlias.px3,
                                                DepartmentAlias.px5,
                                            ])}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    display={{md: "block", lg: "flex"}}
                                    style={{alignItems: "center"}}
                                >
                                    <Box flex={1} ml={{xs: 0, sm: "0.5em"}}>
                                        <TextInput
                                            source="ghi_chu"
                                            label="Ghi chú"
                                            multiline
                                            rows={3}
                                            resource="missingdevices"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Box>
                                </Box>
                            </CardContent>
                            <Toolbar
                                record={formProps.record}
                                basePath={formProps.basePath}
                                invalid={formProps.invalid}
                                handleSubmit={formProps.handleSubmit}
                                saving={formProps.saving}
                                resource="misssingdevices"
                                undoable={false}
                            >
                                <div>
                                    <SaveButton
                                        style={{
                                            marginRight: "10px"
                                        }}
                                        saving={formProps.saving}
                                        redirect={isEdit ? "edit" : "list"}
                                        handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                    />
                                    {isEdit && !isDisableEdit([
                                        DepartmentAlias.px1,
                                        DepartmentAlias.px3,
                                        DepartmentAlias.px5,
                                    ]) && <RaButton
                                        style={{
                                            marginRight: "10px"
                                        }}
                                        color="primary"
                                        component="button"
                                        variant="contained"
                                        size="medium"
                                        label="Hoàn thành"
                                        onClick={() => {
                                            handleFinish(formProps);
                                        }}
                                    >
                                        <DoneIcon style={{fontSize: "20"}}/>
                                    </RaButton>}
                                    <RaButton
                                        onClick={() => {
                                            history.push(`/missingdevices`);
                                        }}
                                        label="Quay lại"
                                        color="primary"
                                        component="button"
                                        variant="contained"
                                        size="medium"
                                    />
                                </div>
                            </Toolbar>
                        </form>
                    </Card>
                )
            }}
        />
    );
};

const requiredValidate = [required()]
